import { FC, useRef } from 'react';
import './style.scss';
import { Button } from '../../form/Button';
import { ReactComponent as Telegram } from 'assets/img/socials/Telegram.svg';
import { ReactComponent as Github } from 'assets/img/socials/Github.svg';
import { ReactComponent as Medium } from 'assets/img/socials/Medium.svg';
import { ReactComponent as Reddit } from 'assets/img/socials/Reddit.svg';
import { ReactComponent as Twitter } from 'assets/img/socials/Twitter.svg';
import { ReactComponent as Youtube } from 'assets/img/socials/Youtube.svg';

import { openNewTab } from 'utils';
import {
    LINK_GITHUB,
    LINK_IME_FAQ,
    LINK_MEDIUM,
    LINK_REDDIT,
    LINK_TELEGRAM,
    LINK_TWITTER,
    LINK_YOUTUBE,
    PROJECT_SHORT_NAME,
} from 'config';
import { useCanvas } from 'hooks';

const Socials: FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    useCanvas(
        'ducks',
        'EC22FFBBC68A4D43914DC975C46E05C9',
        canvasRef,
        animationRef,
        overlayRef,
        true,
        true
    );

    return (
        <div className={'socials'}>
            <div className={'block'}>
                <div className={'title'}>Have any questions?</div>
                <div className={'text'}>
                    {`Find your answers in ${PROJECT_SHORT_NAME} FAQ \nor contact us via social networks`}
                </div>
                <div className={'icons'}>
                    <Medium
                        onClick={() => {
                            openNewTab(LINK_MEDIUM);
                        }}
                    />
                    <Telegram
                        onClick={() => {
                            openNewTab(LINK_TELEGRAM);
                        }}
                    />
                    <Twitter
                        onClick={() => {
                            openNewTab(LINK_TWITTER);
                        }}
                    />
                    <Youtube
                        onClick={() => {
                            openNewTab(LINK_YOUTUBE);
                        }}
                    />
                    <Github
                        onClick={() => {
                            openNewTab(LINK_GITHUB);
                        }}
                    />
                    <Reddit
                        onClick={() => {
                            openNewTab(LINK_REDDIT);
                        }}
                    />
                </div>
                <div className={'buttons'}>
                    <Button
                        isBlueFill
                        isFullSize
                        onClick={() => {
                            openNewTab(LINK_IME_FAQ);
                        }}
                    >
                        Read FAQ
                    </Button>
                </div>
            </div>
            <div className={'block ducks'}>
                <div className={'animation-wrapper'} ref={animationRef}>
                    <canvas ref={canvasRef} />
                    <div ref={overlayRef}></div>
                </div>
            </div>
        </div>
    );
};

export default Socials;

import { FC } from 'react'
import { ReactComponent as LimeBsc } from 'assets/img/lime-bsc.svg';
import { ReactComponent as LimePolygon } from 'assets/img/lime-polygon.svg';
import { ReactComponent as Lime } from 'assets/img/lime-icon.svg';

import { ChainNames } from 'config';

import { ChainNamesType } from '../types'

export const LimeIcon: FC<{ chain: ChainNamesType | string }> = ({ chain }) => {
    if (chain === ChainNames.Bsc) {
        return <LimeBsc />
    }

    if (chain === ChainNames.Polygon) {
        return <LimePolygon />
    }

    return <Lime />
}
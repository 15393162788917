import { PLATFORMS_ENUM, PlatformLinks } from 'types';
import {
    LINK_DESKTOP_APP_WINDOWS_64,
    LINK_DESKTOP_APP_WINDOWS_64_PORTABLE,
    LINK_DESKTOP_APP_WINDOWS_32,
    LINK_DESKTOP_APP_WINDOWS_32_PORTABLE,
    LINK_DESKTOP_APP_MAC,
    // LINK_DESKTOP_APP_MAC_APP_STORE,
    LINK_DESKTOP_APP_LINUX_64,
 } from 'config/links'

export const DEVICES_LINKS: Record<Exclude<PLATFORMS_ENUM, PLATFORMS_ENUM.UNSET>, PlatformLinks> = {
    [PLATFORMS_ENUM.WINDOWS_64]: {
        label: 'Windows',
        arch: 'x64',
        link: LINK_DESKTOP_APP_WINDOWS_64,
        additionalLinks: [
            {
                label: 'Portable Version',
                link: LINK_DESKTOP_APP_WINDOWS_64_PORTABLE,
            }
        ]
    },
    [PLATFORMS_ENUM.MAC]: {
        label: 'macOS',
        link: LINK_DESKTOP_APP_MAC,
        additionalLinks: [
            // {
            //     label: 'Mac App Store',
            //     link: LINK_DESKTOP_APP_MAC_APP_STORE,
            // }
        ]
    },
    [PLATFORMS_ENUM.LINUX_64]: {
        label: 'Linux',
        arch: 'x64',
        link: LINK_DESKTOP_APP_LINUX_64,
    },
    [PLATFORMS_ENUM.WINDOWS_32]: {
        label: 'Windows',
        arch: 'x32',
        link: LINK_DESKTOP_APP_WINDOWS_32,
        additionalLinks: [
            {
                label: 'Portable Version',
                link: LINK_DESKTOP_APP_WINDOWS_32_PORTABLE,
            }
        ]
    }
}

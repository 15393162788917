import { FC } from 'react'

export const Tabs: FC<any> = ({
    disabled,
    setManual,
    manual,
}) => {
    return (
        <div
            className={`airdrop-tabs ${
                disabled ? 'airdrop-disabled' : ''
            }`}
        >
            <button
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled) {
                        setManual(true);
                    }
                }}
                className={
                    manual ? 'airdrop-tabs-active' : ''
                }
            >
                Rows
            </button>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled) {
                        setManual(false);
                    }
                }}
                className={
                    !manual ? 'airdrop-tabs-active' : ''
                }
            >
                List (CSV file)
            </button>
        </div>
    )
}
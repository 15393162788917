export const bridgeConfig = {
    currentNetwork: 'TESTNET',
    TESTNET: {
        providerE: {
            name: 'Ropsten Testnet',
            chainId: 3,
            chainIdStr: '0x3',
            url: 'https://rpc.ankr.com/eth_ropsten/rpc',
            blockexplorer: 'https://ropsten.etherscan.io/',
        },
        providerB: {
            name: 'BSC Testnet',
            chainId: 97,
            chainIdStr: '0x61',
            url: 'https://testnet.bsc.imem.app/gateway/',
            blockexplorer: 'https://testnet.bscscan.com/',
        },
        address_BAB: '0x54b69e497437cD6f8Fb35AE5Fb8699BAEE23b06F',
        address_BAE: '0x4F1281fEf2Bbd433D8E12Fa6cfc9cF4BACb351cb',
        address_TKNB: '0xbfAaF004504bcc139B354cf8F12f5b830CdD0DE6',
        address_TKNE: '0x98B3C321F619d8D87c54a46b7ce9495E43d6F7Ef',
    },
    MAINNET: {
        providerE: {
            name: 'Ethereum Mainnet',
            chainId: 1,
            chainIdStr: '0x1',
            url: 'https://82.202.165.230.sslip.io/rpc',
            blockexplorer: 'https://etherscan.io/',
        },
        providerB: {
            name: 'BNB Chain',
            chainId: 56,
            chainIdStr: '0x38',
            url: 'https://bsc-mainnet.core.chainstack.com/a5a47b68c4174a4680e3627dc84a14d0',
            blockexplorer: 'https://bscscan.com/',
        },
        address_BAB: '0xECab05bb8215023796BDA1a33514C44a6b80777d',
        address_BAE: '0xECab05bb8215023796BDA1a33514C44a6b80777d',
        address_TKNB: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
        address_TKNE: '0x9d0b65a76274645b29e4cc41b8f23081fa09f4a3',
    },
};

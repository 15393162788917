import { DEEPLINK_TELEGRAM_CHANNEL, LINK_TELEGRAM_CHANNEL } from 'config';
import { NetworkPurpose } from 'types';

export * from './bignumber';
export * from './devices';
export * from './screen';
export * from './api';

export const pad = (n: number) => {
    return n < 10 ? '0' + n : n;
};

export const openNewTab = (link: string | undefined) => {
    if (link) return window.open(link, '_blank');
};

export const sliceAddress = (address: string) =>
    `${address.slice(0, 4)}...${address.slice(-4)}`;

export const getTelegramChannelLink = (name: string) => {
    return `${LINK_TELEGRAM_CHANNEL}${name}`;
};

export const getTelegramChannelDeepLink = (name: string) => {
    return `${DEEPLINK_TELEGRAM_CHANNEL}${name}`;
};
/**
 * # Evaluate NetworkPurpose from a string
 */
function asNetworkPurpose(candidate?: string): NetworkPurpose {
  const possibleValues = Object.values(NetworkPurpose);

  if (!possibleValues.includes(candidate as NetworkPurpose)) {
    throw new Error(
      `Invalid network purpose value: ${candidate}. Choose from ${possibleValues}`,
    );
  }

  return candidate as NetworkPurpose;
}

export const currentNetwork = asNetworkPurpose(process.env.REACT_APP_NET);

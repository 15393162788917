import { RefObject, useEffect, useState } from 'react';

export const useOnScreen = (
    ref: RefObject<HTMLDivElement>,
    threshold: number = 0.8
) => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {
                root: null,
                threshold: threshold,
            }
        );
        observer.observe(ref.current!);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
};

export const CARD_STATUS = {
    PUBLISHED: 'published',
    NOT_PAID: 'not paid',
    UNDER_REVIEW: 'under review',
    NOT_PUBLISHED: 'NEW',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    REJECTED: 'REJECTED',
}

export const CARD_COLORS = {
    [CARD_STATUS.PUBLISHED]: '#00BA77',
    [CARD_STATUS.NOT_PAID]: '#F5841F',
    [CARD_STATUS.UNDER_REVIEW]: '#3A4F68',
    [CARD_STATUS.NOT_PUBLISHED]: '#93AAC8',
    [CARD_STATUS.CANCELED]: '#FD6868',
    [CARD_STATUS.REJECTED]: '#FD6868',
}

import axios from 'axios';

import { STORAGE_TOKENS, STORAGE_USER } from 'config';
import { TelegramUser } from 'types';
import { isTokenExpired } from 'utils';

import { userAxios } from './instance';

userAxios.interceptors.response.use(undefined, function (err) {
    return new Promise(function (resolve, reject) {
        console.log(err.response);
        if (
            err.response.status === 401 &&
            err.response.config &&
            !err.response.config.__isRetryRequest
        ) {
            refreshToken()
                .then((success) => {
                    err.response.config.__isRetryRequest = true;
                    err.response.config.headers.Authorization = `Bearer ${success.data.payload.access}`;
                    axios(err.response.config).then(resolve, reject);
                })
                .catch((error) => {
                    console.log('Refresh login error: ', error.response);
                    reject(error.response);
                    localStorage.removeItem(STORAGE_USER);
                    localStorage.removeItem(STORAGE_TOKENS);
                    window.location.reload();
                });
        }
        throw err;
    });
});

export const getAuthToken = (user: TelegramUser) => {
    if (localStorage.getItem(STORAGE_TOKENS)) {
        const isExp = isTokenExpired(
            JSON.parse(localStorage.getItem(STORAGE_TOKENS) || '').access
        );
        if (isExp) {
            refreshToken();
        } else {
            userAxios.defaults.headers.common['Authorization'] = `Bearer ${
                JSON.parse(localStorage.getItem(STORAGE_TOKENS) || '').access
            }`;
        }
    } else {
        return userAxios
            .post('/auth-token/login', user)
            .then((res) => {
                userAxios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${res.data.payload.access}`;
                localStorage.setItem(
                    STORAGE_TOKENS,
                    JSON.stringify(res.data.payload)
                );
                return res;
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    }
};
export const refreshToken = () => {
    return userAxios
        .post(`/auth-token/refreshToken`, {
            refresher: JSON.parse(localStorage.getItem(STORAGE_TOKENS) || '')!
                .refresh,
        })
        .then((res) => {
            userAxios.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${res.data.payload.access}`;
            localStorage.setItem(
                STORAGE_TOKENS,
                JSON.stringify(res.data.payload)
            );
            return res;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
};

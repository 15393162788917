import { ReactNode, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { BigNumber, Contract, providers, utils } from 'ethers';
import { BigNumber as BigNumberJs } from 'bignumber.js'

import { Chain, CHAINS_BY_ID } from 'config';
import { AirdropFormEntry } from './types';
import Token from 'abis/Token.json';
import AirdropContract from 'abis/Airdrop.json';

import { CHAINS_ABI_BY_ID } from 'components/Service/Swap/constants'
import { WalletState } from './components/WalletState'
import { AirdropForm } from './components/AirdropForm'
import { AirdropTitle } from './components/AirdropTitle'

import { AIRDROP_CHAINS_BY_ID } from './constants'

import { useWeb3Wallet } from 'modules/web3/provider'

import './style.scss';

const Airdrop = () => {
    const { wallet, shortWallet, connector, currentChainId, connection } = useWeb3Wallet()

    const airdropContract = useRef<Contract | undefined>()
    const [maxRecipients, setMaxRecipients] = useState(3)
    const currentChain = useMemo(() => currentChainId ? CHAINS_BY_ID[currentChainId] : undefined, [currentChainId])
    const isValidChain = useMemo(() => Boolean(currentChainId && AIRDROP_CHAINS_BY_ID[currentChainId]), [currentChainId])
    const [balance, setBalance] = useState('');
    const [balanceLoading, setBalanceLoading] = useState(false);

    const fetchBalance = useCallback(async (currentWallet: string, { address, url, chainId }: Chain) => {
        setBalance('')
        setBalanceLoading(true)

        try {
            const provider = new providers.JsonRpcProvider(url);
            const contract = new Contract(address.lime, CHAINS_ABI_BY_ID[chainId], provider);

            const balance = await contract.balanceOf(currentWallet)

            setBalance(new BigNumberJs(utils.formatEther(balance.toString())).toFixed(8));
        } catch (e) {
            console.log('balance loading err: ', e)
            connection.disconnect()
        }

        setBalanceLoading(false)
    }, [setBalance, connection])

    const saveAirdropContract = useCallback(async (currentWallet: string, { airdrop }: Chain) => {
        if (currentWallet && airdrop && connector?.provider) {
            const provider = connector.provider && new providers.Web3Provider(connector.provider);
            const signer = provider?.getSigner();

            const contract = new Contract(
                airdrop as string,
                AirdropContract.abi,
                signer
            );

            const maxValue = await contract.maxRecipients()

            airdropContract.current = contract
            setMaxRecipients(maxValue)
        } else {
            setMaxRecipients(0)
        }
    }, [setMaxRecipients, connector])

    useEffect(() => {
        if (wallet && isValidChain && currentChain) {
            fetchBalance(wallet, currentChain)
            saveAirdropContract(wallet, currentChain)
        } else {
            setBalance('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet, currentChain, isValidChain])

    const limeContract = useMemo(() => {
        if (wallet && currentChain && connector?.provider) {
            const provider = new providers.Web3Provider(connector.provider);
            const signer = provider.getSigner();

            return new Contract(
                currentChain.address.lime as string,
                Token.abi,
                signer
            )
        }

        return null
    }, [wallet, connector, currentChain])

    const [allowance, setAllowance] = useState<BigNumber>();
    const [airdropTxHash, setAirdropTxHash] = useState<string>('');
    const [approvePending, setApprovePending] = useState<boolean>(false);
    const [airdropPending, setAirdropPending] = useState<boolean>(false);

    const [errors, setErrors] = useState<ReactNode[]>([]);

    const resetAirdrop = useCallback(() => {
        setErrors([])
        setAirdropTxHash('')
        setAirdropPending(false)
        setApprovePending(false)
    }, [])

    const fetchAllowance = useCallback(async (chain: Chain) => {
        const result: BigNumber = await (limeContract as Contract).allowance(
            wallet,
            chain.airdrop
        );
        setAllowance(result);
    }, [setAllowance, wallet, limeContract]);


    const approveTokens = useCallback(async (chain: Chain) => {
        try {
            const balanceBigNum = utils.parseEther(balance)

            setApprovePending(true);
            const tx = await (limeContract as Contract).approve(
                chain.airdrop,
                balanceBigNum
            );
            await tx.wait();
            await fetchAllowance(chain);
        } finally {
            setApprovePending(false);
        }
    }, [fetchAllowance, setApprovePending, limeContract, balance]);

    const airdropTokens = useCallback(async (chain: Chain, entities: AirdropFormEntry[]) => {
        if (!connector?.provider || !airdropContract.current) {
            return
        }

        try {
            setAirdropPending(true);

            const addresses = []
            const amounts = []

            for (const entry of entities) {
                if (entry.isAddressValid === true && entry.isAmountValid === true) {
                    addresses.push(entry.address)
                    amounts.push(entry.amount)
                }
            }

            const bigNumberAmounts = amounts.map(amount => utils.parseEther(amount))

            const tx: providers.TransactionResponse = await airdropContract.current
                .airdrop(
                    addresses,
                    bigNumberAmounts,
                    chain.address.lime as string,
                )
            setAirdropTxHash(tx.hash);
        } catch (err) {
            if (err.code === -32000) {
                throw err
            } else {
                console.log('err', err)
            }
        } finally {
            setAirdropPending(false);
        }
    }, [setAirdropPending, setAirdropTxHash, connector, wallet]);

    useEffect(() => {
        if (limeContract && currentChain) {
            fetchAllowance(currentChain);
        }
    }, [limeContract, currentChain, fetchAllowance]);

    return (
        <div className={`service-wrapper`}>
            <div className="airdrop">
                <div className={`airdrop-main`}>
                    <AirdropTitle wallet={wallet} currentChain={currentChain} airdropCompleted={!!airdropTxHash} />
                    <WalletState wallet={wallet} balance={balance} balanceLoading={balanceLoading} />
                    <AirdropForm
                        wallet={wallet}
                        shortWallet={shortWallet}
                        balance={balance}
                        errors={errors}
                        allowance={allowance}
                        scanAirdropTxLink={currentChain && airdropTxHash && `${currentChain.explorer}${airdropTxHash}`}
                        airdropPending={airdropPending}
                        approvePending={approvePending}
                        balanceLoading={balanceLoading}
                        setAirdropPending={setAirdropPending}
                        setApprovePending={setApprovePending}
                        currentChain={currentChain}
                        approveTokens={approveTokens}
                        airdropTokens={airdropTokens}
                        openWalletModal={connection.openModal}
                        resetAirdrop={resetAirdrop}
                        isValidChain={isValidChain}
                        maxRecipients={maxRecipients}
                    />
                </div>
            </div>
        </div>
    );
};

export default Airdrop;

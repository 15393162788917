import { FC } from 'react';
import './style.scss';
import classNames from 'classnames';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = ({
    onClick,
    children,
    isBlueBorder,
    isBlue,
    isBlueFill,
    isLightBlueFill,
    isBlack,
    isBlackFill,
    isBlackBorder,
    isGreenFill,
    isRedBorder,
    isFullSize,
    isFullWidth,
    isDisabled,
    isRedFill,
    className,
    value,
}) => {
    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            value={value}
            className={classNames(className, {
                button: true,
                'button--blue-border': isBlueBorder,
                'button--blue': isBlue,
                'button--blue-fill': isBlueFill,
                'button--light-blue-fill': isLightBlueFill,
                'button--black': isBlack,
                'button--black-border': isBlackBorder,
                'button--black-fill': isBlackFill,
                'button--green-fill': isGreenFill,
                'button--red-border': isRedBorder,
                'button--full-size': isFullSize,
                'button--full-width': isFullWidth,
                'button--red-fill': isRedFill,
            })}
        >
            {children}
        </button>
    );
};

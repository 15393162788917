import { FC } from 'react'
import { COIN_LIME, Chain } from 'config';
import { AIRDROP_CHAINS } from '../constants'
import { SelectNetwork } from './SelectNetwork'

type AirdropTitleProps = {
    currentChain?: Chain,
    airdropCompleted?: boolean,
    wallet?: string,
}

export const AirdropTitle: FC<AirdropTitleProps> = ({
    currentChain,
    airdropCompleted,
    wallet,
}) => {
    return (
        <header>
            <h2>{COIN_LIME} Multi-transfer</h2>
            {wallet && <SelectNetwork chains={AIRDROP_CHAINS} currentChain={currentChain} />}
        </header>
    )
}
import { FC, ReactNode, useCallback, useState, useMemo } from 'react'
import { COIN_LIME, Chain } from 'config'
import { BigNumber, utils } from 'ethers'
import { isEqual } from 'lodash'
import classNames from 'classnames'

import { AirdropFormEntry } from '../../types'
import { SubmitButton } from './SubmitButton'
import { Footer } from './Footer'
import { CSVForm } from './CSVForm'
import { ManualForm } from './ManualForm'
import { Tabs } from './Tabs'
import { InProgressDialog } from './InProgressDialog'

import { INITIAL_ENTRY, useAirdrop, useCSVForm } from './hooks'

type AirdropFormProps = {
    wallet?: string
    shortWallet?: string
    balance: string
    approvePending: boolean
    airdropPending: boolean
    balanceLoading: boolean
    isValidChain?: boolean
    errors: ReactNode[]
    currentChain?: Chain
    approveTokens: (chain: Chain) => void
    airdropTokens: (chain: Chain, entities: AirdropFormEntry[]) => void
    openWalletModal: () => void
    setAirdropPending: any
    setApprovePending: any
    resetAirdrop: () => void
    allowance?: BigNumber
    scanAirdropTxLink?: string
    maxRecipients: number
}

export const AirdropForm: FC<AirdropFormProps> = ({
    wallet,
    shortWallet,
    balance,
    errors,
    airdropPending,
    approvePending,
    isValidChain,
    balanceLoading,
    currentChain,
    setAirdropPending,
    setApprovePending,
    approveTokens,
    airdropTokens,
    openWalletModal,
    resetAirdrop,
    allowance,
    scanAirdropTxLink,
    maxRecipients,
}) => {
    const {
        entries,
        commonAmount,
        totalAddresses,
        totalTokenAmount,
        validateAndUpdateEntries,
        setCommonAmountHandle,
        updateEntry,
        removeEntry,
        addEntry,
        reset,
    } = useAirdrop()

    const {
        csvText,
        csvError,
        csvFileName,
        csvRecipientsError,
        processCsvFile,
        resetFile,
        updateEntries,
    } = useCSVForm(validateAndUpdateEntries, maxRecipients)

    const [errCode, setErrCode] = useState('')

    const [showInprogressDialog, toggleInprogressDialog] = useState<boolean>(false);

    const [manual, setManual] = useState<boolean>(true);
    const switchTab = useCallback((flag) => {
        reset()
        setManual(flag)
    }, [])

    const resetAll = useCallback(() => {
        reset()
        resetFile()
        resetAirdrop()
    }, [])

    const closeDialog = useCallback(() => {
        toggleInprogressDialog(false)
        setAirdropPending(false)

        if (scanAirdropTxLink) {
            resetAll()
        } else if (approvePending) {
            setApprovePending(false)
        }
    }, [scanAirdropTxLink, resetAll, toggleInprogressDialog, approvePending, setApprovePending, setAirdropPending])

    const [entriesErrors, entriesErrorsIdx] = useMemo(() => {
        const errorsTexts: string[] = []
        const errorsIds = new Set()

        if (isEqual(entries, { '0': INITIAL_ENTRY })) {
            return [errorsTexts, errorsIds]
        }

        for (const entityId in entries) {
            const entity = entries[entityId]

            if (!entity.isAddressValid || !entity.isAmountValid) {
                errorsIds.add(+entityId)

                if (!entity.isAddressValid) {
                    errorsTexts.push(entity.address)
                } else {
                    errorsTexts.push(`${entity.address};${entity.amount}`)
                }
            }
        }

        return [errorsTexts, errorsIds]
    }, [entries])

    const balanceBigNum = utils.parseEther(balance || '0')
    const lowBalance = balance !== '0' && balanceBigNum.lt(totalTokenAmount);
    const submitDisabled = entriesErrors.length || errors.length || totalTokenAmount.eq(0) || lowBalance
    const needToApproveTokens = !allowance || allowance.eq(0) || allowance.lt(totalTokenAmount);

    const [showApprove, toggleShowApprove] = useState<boolean>(false);
    const onSubmit = useCallback(async (e: any) => {
        e.preventDefault();
        setErrCode('');
        if (currentChain && !submitDisabled) {
            if (needToApproveTokens) {
                toggleInprogressDialog(true)
                toggleShowApprove(true)
                try {
                    await approveTokens(currentChain);
                } finally {
                    toggleInprogressDialog(false)
                    toggleShowApprove(false)
                }
            } else {
                toggleInprogressDialog(true)
            }
        }
    }, [needToApproveTokens, approveTokens, currentChain, submitDisabled, toggleShowApprove])

    const airdrop = useCallback(async () => {
        if (currentChain && !submitDisabled) {
            try {
                await airdropTokens(currentChain, Object.values(entries));
            } catch (err) {
                if (err.code === 'ACTION_REJECTED' || err.message === 'User rejected the transaction') {
                    setErrCode('ACTION_REJECTED')
                }
            }
        }
    }, [airdropTokens, entries, currentChain, submitDisabled])

    const airdropDisabled = !wallet || approvePending || balanceLoading || !isValidChain

    return (
        <div>
            <InProgressDialog
                open={showInprogressDialog}
                closeDialog={closeDialog}
                wallet={shortWallet}
                balance={balance}
                chainName={currentChain?.label}
                totalTokenAmount={totalTokenAmount}
                airdrop={airdrop}
                showApprove={showApprove}
                loading={airdropPending}
                txLink={scanAirdropTxLink}
                errCode={errCode}
            />
            <form className="airdrop-form">
                <h3>Addresses to send {COIN_LIME}</h3>
                <Tabs
                    disabled={airdropDisabled}
                    setManual={switchTab}
                    manual={manual}
                />
                <div
                    className={classNames(
                        airdropDisabled && 'airdrop-disabled',
                        manual ? 'airdrop-rows' : 'airdrop-csv',
                    )}
                >
                    {manual
                        ? <ManualForm
                            wallet={wallet}
                            entries={entries}
                            addEntry={addEntry}
                            totalTokenAmount={totalTokenAmount}
                            validateAndUpdateEntries={validateAndUpdateEntries}
                            removeEntry={removeEntry}
                            commonAmount={commonAmount}
                            updateEntry={updateEntry}
                            totalAddresses={totalAddresses}
                            setCommonAmountHandle={setCommonAmountHandle}
                            disabled={airdropDisabled}
                            maxRecipients={maxRecipients}
                        />
                        : <CSVForm
                            errors={entriesErrors}
                            totalTokenAmount={totalTokenAmount}
                            totalAddresses={totalAddresses}
                            reset={reset}
                            disabled={airdropDisabled}
                            csvText={csvText}
                            csvError={csvError}
                            csvFileName={csvFileName}
                            processCsvFile={processCsvFile}
                            resetFile={resetFile}
                            updateEntries={updateEntries}
                            entriesErrorsIdx={entriesErrorsIdx}
                            csvRecipientsError={csvRecipientsError}
                        />
                    }
                </div>
            </form>
            <div className="airdrop-errors">
                {balance === '0' && <div>There is no LIME on your wallet balance</div>}
                {balance !== '' && lowBalance && <div>Not enough LIME on your balance</div>}
            </div>
            <SubmitButton
                disabled={submitDisabled || !isValidChain || csvRecipientsError}
                needToApproveTokens={isValidChain && needToApproveTokens}
                onSubmit={onSubmit}
                loading={approvePending}
                balanceLoading={balanceLoading}
                openWalletModal={openWalletModal}
                wallet={wallet}
                isValidChain={isValidChain}
            />
            <Footer
                needToApproveTokens={!submitDisabled && isValidChain && needToApproveTokens}
                error={errors?.[0]}
            />
        </div>
    )
}
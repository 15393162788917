import { FC } from 'react'
import { Checkbox } from 'components/Checkbox'
import { utils } from 'ethers'
import { ReactComponent as RemoveIcon } from 'assets/img/close.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import { AirdropFormEntry } from '../../types'

export const ManualForm: FC<any> = ({
    wallet,
    entries,
    totalTokenAmount,
    removeEntry,
    updateEntry,
    commonAmount,
    addEntry,
    totalAddresses,
    setCommonAmountHandle,
    disabled,
    maxRecipients,
}) => {
    const data = Object.values<AirdropFormEntry>(entries)

    return (
        <>
            <header className='airdrop-form-header'>
                {totalAddresses
                    ? (
                        <div className="airdrop-total-addresses">
                            {totalAddresses} addresses,{' '}
                            {utils.formatEther(totalTokenAmount)} tokens
                        </div>
                    )
                    : (
                        <div>
                            Add each address and amount
                            separately
                        </div>
                    )
                }
                <div className="airdrop-apply-all">
                    <Checkbox
                        id="airdrop-apply-all"
                        checked={commonAmount}
                        onCheckedChange={setCommonAmountHandle}
                        disabled={disabled}
                    />
                    <label htmlFor="airdrop-apply-all">
                        Apply amount to all addresses
                    </label>
                </div>
            </header>
            <section>
                {data.map((entry, i) => {
                    const { address, amount, isAddressValid, isAmountValid } = entry;
                    let amountClass = ''

                    if (isAddressValid === undefined && isAmountValid === undefined) {
                        amountClass = ''
                    } else if (isAddressValid === 'empty') {
                        if (isAmountValid === false) {
                            amountClass = 'airdrop-row-invalid'
                        }
                    } else {
                        if (isAddressValid === false) {
                            if (isAmountValid !== 'empty' && isAmountValid !== true) {
                                amountClass = 'airdrop-row-invalid'
                            }
                        } else {
                            if (isAmountValid !== true) {
                                amountClass = 'airdrop-row-invalid'
                            }
                        }
                    }

                    return (
                        <div key={i}>
                            <input
                                placeholder="Address"
                                value={address}
                                onChange={(e) => {
                                    updateEntry({
                                        ...entry,
                                        address: e.target.value
                                    }, i)
                                }}
                                disabled={disabled}
                                className={isAddressValid !== false ? 'airdrop-address-input' : 'airdrop-address-input airdrop-row-invalid'}
                            />
                            <div>
                                <input
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        updateEntry({
                                            ...entry,
                                            amount: e.target.value
                                        }, i)
                                    }}
                                    disabled={disabled}
                                    className={amountClass}
                                />
                                <button
                                    className="airdrop-row-remove"
                                    disabled={disabled || data.length <= 1}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        removeEntry(i)
                                    }}
                                >
                                    <RemoveIcon />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </section>
            <footer>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        addEntry();
                    }}
                    disabled={disabled || data.length >= maxRecipients}
                >
                    <PlusIcon />&nbsp;Add Row
                </button>
            </footer>
        </>
    )
}
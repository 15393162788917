import { FC, Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NotFound from '../components/NotFound';

const NotFoundPage: FC = () => {
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <NotFound />
                <div className={'footer-wrapper'}>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
};

export default NotFoundPage;

import { Contract, providers } from 'ethers';
import ClaimAbi from 'abis/Claim.json';
import { claimConfig } from 'claimConfig';
import { Dispatch } from 'react';
import { AppStateType } from 'store';
import { currentNetwork } from 'utils';

const address_Claim = claimConfig[currentNetwork].address_Claim;
const _provider = new providers.JsonRpcProvider(
    claimConfig[currentNetwork].provider.url
);

const claim = new Contract(address_Claim, ClaimAbi.abi, _provider);

const RESET_DATA = 'CLAIM/RESET_DATA';
const LOAD_CONTRACT_INFO_START = 'CLAIM/LOAD_CONTRACT_INFO_START';
const LOAD_CONTRACT_INFO_FINISH = 'CLAIM/LOAD_CONTRACT_INFO_FINISH';
const SET_ERROR = 'CLAIM/SET_ERROR';
const SET_TRANSACTION_URL = 'CLAIM/SET_TRANSACTION_URL';
const SET_TRANSACTION_SUCCESS = 'CLAIM/SET_TRANSACTION_SUCCESS';
const CLAIM_START = 'CLAIM/CLAIM_START';
const CLAIM_FINISH = 'CLAIM/CLAIM_FINISH';

interface IClaimState {
    isLoading: boolean;
    limeAvailable: string;
    limeClaimed: string;
    error: string;
    transactionUrl: string;
    isSuccess: boolean;
    isClaiming: boolean;
}

interface ISystemAction {
    type: string;
    payload: any;
}

const initialState: IClaimState = {
    isLoading: false,
    limeAvailable: '',
    limeClaimed: '',
    error: '',
    transactionUrl: '',
    isSuccess: false,
    isClaiming: false,
};

export const getClaim = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: CLAIM_START,
        });
        try {
            const w = getState().services.wallet;
            console.log(w, claim);

            const c = new Contract(
                address_Claim,
                ClaimAbi.abi,
                getState().services.signer!
            );
            const tx: providers.TransactionResponse = await c.claim();

            const snackbar_approve_tx_url = `${claimConfig[currentNetwork].provider.blockexplorer}${tx.hash}`;
            dispatch({
                type: SET_TRANSACTION_URL,
                payload: snackbar_approve_tx_url,
            });
            await tx.wait(4);
            dispatch({
                type: SET_TRANSACTION_SUCCESS,
            });
        } catch (error) {
            console.error(error);

            dispatch({
                type: SET_ERROR,
                payload: error.data?.message,
            });
        }

        dispatch(loadContractInfo());
        dispatch({
            type: CLAIM_FINISH,
        });
    };
};

export const loadContractInfo = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: LOAD_CONTRACT_INFO_START,
        });
        let ans;
        try {
            const w = getState().services.wallet;
            console.log(w, claim);
            ans = await claim.infoBundle(w);

            console.log(ans);
        } catch (error) {
            console.error(error);

            dispatch({
                type: SET_ERROR,
                payload: error.data?.message,
            });
        }

        dispatch({
            type: LOAD_CONTRACT_INFO_FINISH,
            payload: {
                available: ans.uClaimable,
                claimed: ans.uInfo.claimed,
            },
        });
    };
};

const ClaimReducer = (
    state = initialState,
    action: ISystemAction
): IClaimState => {
    switch (action.type) {
        case RESET_DATA:
            return {
                ...initialState,
            };

        case LOAD_CONTRACT_INFO_START:
            return {
                ...state,
                isLoading: true,
            };

        case LOAD_CONTRACT_INFO_FINISH:
            return {
                ...state,
                isLoading: false,
                limeAvailable: action.payload.available,
                limeClaimed: action.payload.claimed,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case SET_TRANSACTION_URL:
            return {
                ...state,
                transactionUrl: action.payload,
            };

        case SET_TRANSACTION_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };

        case CLAIM_START:
            return {
                ...state,
                isSuccess: false,
                transactionUrl: '',
                isClaiming: true,
            };

        case CLAIM_FINISH:
            return {
                ...state,
                isClaiming: false,
            };

        default:
            return state;
    }
};

export default ClaimReducer;

import { FC } from 'react'
import './style.scss'
import '../../style.scss'

interface IChannelStateScreen {
    title: string
    text: string
    buttons: JSX.Element
}

const ChannelStateScreen: FC<IChannelStateScreen> = ({
    title,
    text,
    buttons,
}) => {
    return (
        <div className={'channel-state-screen flex-full-page'}>
            <div className={'title'}>{title}</div>
            <div className={'text'}>{text}</div>
            <div className={'buttons'}>{buttons}</div>
        </div>
    )
}

export default ChannelStateScreen

import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType } from 'store';
import CreateChannel from '../../components/Service/Catalog/CreateChannel';

const CreateChannelPage: FC = () => {
    const channel = useSelector(
        (state: AppStateType) => state.catalog.checkedChannel
    );

    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <CreateChannel channel={channel} />
            </div>
        </Fragment>
    );
};

export default CreateChannelPage;

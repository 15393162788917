import { Fragment, useCallback } from 'react'

import { Button } from 'components/form/Button';
import Modal from 'components/Modal'
import WalletConnectModal from 'components/Modal/WalletConnectNew'
import tick from 'assets/img/tick-white.svg';

import { WALLET_TYPE } from 'modules/web3'
import { useWeb3Wallet } from 'modules/web3/provider'

export const HeaderWallet = () => {
    const {
        wallet,
        shortWallet,
        walletType,
        connection,
    } = useWeb3Wallet()

    const connectMetamask = useCallback(() => connection.connect(WALLET_TYPE.METAMASK), [connection])
    const connectWalletConnect = useCallback(() => connection.connect(WALLET_TYPE.WALLET_CONNECT), [connection])
    const disconnectAndHide = useCallback(() => {
        connection.disconnect()
        connection.closeModal()
    }, [connection])

    return (
        <>
            <Button isBlueFill onClick={connection.openModal}>
                {wallet.length
                    ? <Fragment>
                        {shortWallet}
                        <img src={tick} alt={'wallet'} />
                    </Fragment>
                    : 'Connect Wallet'
                }
            </Button>
            <Modal isShown={connection.isShown} hide={connection.closeModal}>
                <WalletConnectModal
                    hide={connection.closeModal}
                    wallet={wallet}
                    walletType={walletType}
                    connectMetamask={connectMetamask}
                    connectWalletConnect={connectWalletConnect}
                    disconnectAndHide={disconnectAndHide}
                />
            </Modal>
        </>
    )
}
import kadena from 'assets/img/landing/collab/kadena.svg';
import playcent from 'assets/img/landing/collab/playcent.svg';
import parsiq from 'assets/img/landing/collab/parsiq.svg';
import crc from 'assets/img/landing/collab/crc.svg';
import a2dao from 'assets/img/landing/collab/a2dao.svg';
import cuties from 'assets/img/landing/collab/cuties.svg';
import criterion from 'assets/img/landing/collab/criterion.svg';
import simplex from 'assets/img/landing/collab/simplex.svg';
import dePay from 'assets/img/landing/collab/dePay.svg';
import taraxa from 'assets/img/landing/collab/taraxa.svg';
import litentry from 'assets/img/landing/collab/litentry.svg';
import clock7 from 'assets/img/landing/collab/7Clock.svg';
import gains from 'assets/img/landing/collab/gains.svg';
import uniland from 'assets/img/landing/collab/uniland.svg';
import lanceria from 'assets/img/landing/collab/lanceria.svg';
import hapi from 'assets/img/landing/collab/hapi.svg';
import market from 'assets/img/landing/collab/market.svg';

import oke from 'assets/img/landing/collab/oke.svg';
import treeM from 'assets/img/landing/collab/treeM.svg';
import lvt from 'assets/img/landing/collab/lvt.svg';
import bch from 'assets/img/landing/collab/bloackchainhouse.svg';
import deltaHub from 'assets/img/landing/collab/deltaHub.svg';
import titans from 'assets/img/landing/collab/titans.svg';
import newtribe from 'assets/img/landing/collab/newtribe.svg';
import cryptoAvengers from 'assets/img/landing/collab/cryptoAvengers.svg';
import phoenix from 'assets/img/landing/collab/phoenix.svg';
import ybb from 'assets/img/landing/collab/ybb.svg';
import trust from 'assets/img/landing/collab/trust.svg';
import deHive from 'assets/img/landing/collab/deHive.svg';
import purefi from 'assets/img/landing/collab/purefi.svg';
import gate from 'assets/img/landing/collab/gate.svg';
import dego from 'assets/img/landing/collab/dego.svg';
import xsigma from 'assets/img/landing/collab/xsigma.svg';
import bondly from 'assets/img/landing/collab/bondly.svg';

import apeSwap from 'assets/img/landing/collab/apeSwap.svg';
import cartesi from 'assets/img/landing/collab/cartesi.svg';
import ceek from 'assets/img/landing/collab/ceek.svg';
import coverCompared from 'assets/img/landing/collab/coverCompared.svg';
import cryptoBlades from 'assets/img/landing/collab/cryptoBlades.svg';
import daoventure from 'assets/img/landing/collab/daoventures.svg';
import drops from 'assets/img/landing/collab/drops.svg';
import elrond from 'assets/img/landing/collab/elrond.svg';
import eqifi from 'assets/img/landing/collab/eqifi.svg';
import ferrum from 'assets/img/landing/collab/ferrum.svg';
import fuse from 'assets/img/landing/collab/fuse.svg';
import konomi from 'assets/img/landing/collab/konomi.svg';
import lossless from 'assets/img/landing/collab/lossless.svg';
import metis from 'assets/img/landing/collab/metis.svg';
import neo from 'assets/img/landing/collab/neo.svg';
import open from 'assets/img/landing/collab/open.svg';
import shareRing from 'assets/img/landing/collab/shareRing.svg';
import tomo from 'assets/img/landing/collab/tomo.svg';
import union from 'assets/img/landing/collab/union.svg';
import xend from 'assets/img/landing/collab/xend.svg';
import yay from 'assets/img/landing/collab/yay.svg';
import royale from 'assets/img/landing/collab/royale.svg';
import kaby from 'assets/img/landing/collab/kaby.svg';
import saito from 'assets/img/landing/collab/saito.svg';
import dextools from 'assets/img/landing/collab/dextools.svg';
import gamyFi from 'assets/img/landing/collab/gamyFi.svg';
import flurry from 'assets/img/landing/collab/flurry.svg';

import kadenaMonochrome from 'assets/img/landing/collab/monochrome/kadenaMonochrome.svg';
import playcentMonochrome from 'assets/img/landing/collab/monochrome/playcentMonochrome.svg';
import parsiqMonochrome from 'assets/img/landing/collab/monochrome/parsiqMonochrome.svg';
import crcMonochrome from 'assets/img/landing/collab/monochrome/crcMonochrome.svg';
import a2daoMonochrome from 'assets/img/landing/collab/monochrome/a2daoMonochrome.svg';
import cutiesMonochrome from 'assets/img/landing/collab/monochrome/cutiesMonochrome.svg';
import criterionMonochrome from 'assets/img/landing/collab/monochrome/criterionMonochrome.svg';
import simplexMonochrome from 'assets/img/landing/collab/monochrome/simplexMonochrome.svg';
import dePayMonochrome from 'assets/img/landing/collab/monochrome/depayMonochrome.svg';
import taraxaMonochrome from 'assets/img/landing/collab/monochrome/taraxaMonochrome.svg';
import litentryMonochrome from 'assets/img/landing/collab/monochrome/litentryMonochrome.svg';
import clock7Monochrome from 'assets/img/landing/collab/monochrome/7ClockMonochrome.svg';
import gainsMonochrome from 'assets/img/landing/collab/monochrome/gainsMonochrome.svg';
import unilandMonochrome from 'assets/img/landing/collab/monochrome/unilandMonochrome.svg';
import lanceriaMonochrome from 'assets/img/landing/collab/monochrome/lanceriaMonochrome.svg';
import hapiMonochrome from 'assets/img/landing/collab/monochrome/hapiMonochrome.svg';
import marketMonochrome from 'assets/img/landing/collab/monochrome/marketMonochrome.svg';

import okeMonochrome from 'assets/img/landing/collab/monochrome/okeMonochrome.svg';
import treeMMonochrome from 'assets/img/landing/collab/monochrome/treeMMonochrome.svg';
import lvtMonochrome from 'assets/img/landing/collab/monochrome/lvtMonochrome.svg';
import bchMonochrome from 'assets/img/landing/collab/monochrome/blockchainhouseMonochrome.svg';
import deltaHubMonochrome from 'assets/img/landing/collab/monochrome/deltaMonochrome.svg';
import titansMonochrome from 'assets/img/landing/collab/monochrome/titansMonochrome.svg';
import newtribeMonochrome from 'assets/img/landing/collab/monochrome/newTribeMonochrome.svg';
import cryptoAvengersMonochrome from 'assets/img/landing/collab/monochrome/cryptoAvengersMonochrome.svg';
import phoenixMonochrome from 'assets/img/landing/collab/monochrome/phoenixMonochrome.svg';
import ybbMonochrome from 'assets/img/landing/collab/monochrome/ybbMonochrome.svg';
import trustMonochrome from 'assets/img/landing/collab/monochrome/trustMonochrome.svg';
import deHiveMonochrome from 'assets/img/landing/collab/monochrome/deHiveMonochrome.svg';
import purefiMonochrome from 'assets/img/landing/collab/monochrome/purefiMonochrome.svg';
import gateMonochrome from 'assets/img/landing/collab/monochrome/gateMonochrome.svg';
import degoMonochrome from 'assets/img/landing/collab/monochrome/degoMonochrome.svg';
import xsigmaMonochrome from 'assets/img/landing/collab/monochrome/xsigmaMonochrome.svg';
import bondlyMonochrome from 'assets/img/landing/collab/monochrome/bondlyMonochrome.svg';

import apeSwapMonochrome from 'assets/img/landing/collab/monochrome/apeSwapMonochrome.svg';
import cartesiMonochrome from 'assets/img/landing/collab/monochrome/cartesiMonochrome.svg';
import ceekMonochrome from 'assets/img/landing/collab/monochrome/ceekMonochrome.svg';
import coverComparedMonochrome from 'assets/img/landing/collab/monochrome/coverComparedMonochrome.svg';
import cryptoBladesMonochrome from 'assets/img/landing/collab/monochrome/cryptoBladesMonochrome.svg';
import daoventureMonochrome from 'assets/img/landing/collab/monochrome/daoventuresMonochrome.svg';
import dropsMonochrome from 'assets/img/landing/collab/monochrome/dropsMonochrome.svg';
import elrondMonochrome from 'assets/img/landing/collab/monochrome/elrondMonochrome.svg';
import eqifiMonochrome from 'assets/img/landing/collab/monochrome/eqifyMonochrome.svg';
import ferrumMonochrome from 'assets/img/landing/collab/monochrome/ferrumMonochrome.svg';
import fuseMonochrome from 'assets/img/landing/collab/monochrome/fuseMonochrome.svg';
import konomiMonochrome from 'assets/img/landing/collab/monochrome/konomiMonochrome.svg';
import losslessMonochrome from 'assets/img/landing/collab/monochrome/losslessMonochrome.svg';
import metisMonochrome from 'assets/img/landing/collab/monochrome/metisMonochrome.svg';
import neoMonochrome from 'assets/img/landing/collab/monochrome/neoMonochrome.svg';
import openMonochrome from 'assets/img/landing/collab/monochrome/openMonochrome.svg';
import shareRingMonochrome from 'assets/img/landing/collab/monochrome/shareRingMonochrome.svg';
import tomoMonochrome from 'assets/img/landing/collab/monochrome/tomoChainMonochrome.svg';
import unionMonochrome from 'assets/img/landing/collab/monochrome/unionMonochrome.svg';
import xendMonochrome from 'assets/img/landing/collab/monochrome/xendMonochrome.svg';
import yayMonochrome from 'assets/img/landing/collab/monochrome/yayMonochrome.svg';
import royaleMonochrome from 'assets/img/landing/collab/monochrome/royaleMonochrome.svg';
import kabyMonochrome from 'assets/img/landing/collab/monochrome/kabyMonochrome.svg';
import saitoMonochrome from 'assets/img/landing/collab/monochrome/saitoMonochrome.svg';
import dextoolsMonochrome from 'assets/img/landing/collab/monochrome/dextoolsMonochrome.svg';
import gamyFiMonochrome from 'assets/img/landing/collab/monochrome/gamyFiMonochrome.svg';
import flurryMonochrome from 'assets/img/landing/collab/monochrome/flurryMonochrome.svg';

import { LINK_TELEGRAM_CHANNEL } from 'config';
import { CollaborationItem } from './types';

export const collaborations: CollaborationItem[] = [
    {
        link: 'https://www.threem.capital',
        twitter: 'https://twitter.com/threemcapital',
        name: 'Tree M Capital',
        picture: treeM,
        pictureMonochrome: treeMMonochrome,
    },
    {
        link: 'https://titans.ventures',
        twitter: 'https://twitter.com/Titans_ventures',
        telegram: 'https://t.me/Titans_Ventures',
        name: 'titans',
        picture: titans,
        pictureMonochrome: titansMonochrome,
    },
    {
        link: 'http://www.ybb.io',
        twitter: 'https://twitter.com/FoundationYbb',
        medium: 'https://medium.com/ybb-foundation',
        name: 'ybb',
        picture: ybb,
        pictureMonochrome: ybbMonochrome,
    },
    {
        link: 'https://www.deltahub.capital',
        twitter: 'https://twitter.com/deltahubc',
        telegram: 'https://t.me/deltahubc',
        name: 'deltaHub',
        picture: deltaHub,
        pictureMonochrome: deltaHubMonochrome,
    },
    {
        link: 'https://www.litentry.com',
        twitter: 'https://twitter.com/litentry',
        telegram: 'https://t.me/litentry',
        name: 'litentry',
        picture: litentry,
        pictureMonochrome: litentryMonochrome,
    },
    {
        link: 'https://dego.finance/home',
        twitter: 'https://twitter.com/dego_finance',
        telegram: 'https://t.me/dego_finance',
        name: 'dego',
        picture: dego,
        pictureMonochrome: degoMonochrome,
    },
    {
        link: 'https://www.phoenix-vc.com/en',
        twitter: 'https://twitter.com/PhoenixCryptoVC',
        telegram: `${LINK_TELEGRAM_CHANNEL}PhoenixCryptoVC_Announcements`,
        name: 'phoenix',
        picture: phoenix,
        pictureMonochrome: phoenixMonochrome,
    },
    {
        link: 'https://www.gains-associates.com/',
        twitter: 'https://twitter.com/GainsAssociates',
        telegram: 'https://t.me/GainsChat',
        name: 'gains',
        picture: gains,
        pictureMonochrome: gainsMonochrome,
    },
    {
        link: 'https://chainridge.capital/',
        twitter: 'https://twitter.com/chainridgevc?s=21',
        medium: 'https://chainridgecapital.medium.com/',
        name: 'crc',
        picture: crc,
        pictureMonochrome: crcMonochrome,
    },
    {
        link: 'https://a2dao.com/',
        twitter: 'https://twitter.com/a2dao',
        telegram: 'https://t.me/a2ann',
        name: 'A2DAO',
        picture: a2dao,
        pictureMonochrome: a2daoMonochrome,
    },
    {
        link: 'https://www.7oclockcapital.com/',
        twitter: 'https://twitter.com/7oClockCapital',
        telegram: 'https://t.me/sevenOClockCommunity',
        name: '7Clock',
        picture: clock7,
        pictureMonochrome: clock7Monochrome,
    },
    {
        link: 'https://bondly.finance/',
        twitter: 'https://twitter.com/BondlyFinance',
        telegram: 'https://t.me/bondlyfinance',
        name: 'bondly',
        picture: bondly,
        pictureMonochrome: bondlyMonochrome,
    },
    {
        link: 'https://www.okex.com/blockdream-ventures',
        twitter: 'https://twitter.com/OKEx',
        telegram: 'https://t.me/okexofficial_ru',
        name: 'oke',
        picture: oke,
        pictureMonochrome: okeMonochrome,
    },
    {
        link: 'https://www.gate.io',
        twitter: 'https://twitter.com/gate_io',
        telegram: 'https://t.me/gateio',
        name: 'gate',
        picture: gate,
        pictureMonochrome: gateMonochrome,
    },
    {
        link: 'https://www.dextools.io/',
        twitter: 'https://twitter.com/DEXToolsApp',
        telegram: 'https://t.me/DEXToolsNews',
        name: 'dextools',
        picture: dextools,
        pictureMonochrome: dextoolsMonochrome,
    },
    {
        link: 'https://www.simplex.com/',
        twitter: 'https://twitter.com/SimplexCC',
        medium: 'https://simplexcom.medium.com/',
        name: 'simplex',
        picture: simplex,
        pictureMonochrome: simplexMonochrome,
    },
    {
        link: 'https://apeswap.finance/',
        twitter: 'https://twitter.com/ape_swap',
        telegram: 'https://t.me/ape_swap',
        name: 'apeSwap',
        picture: apeSwap,
        pictureMonochrome: apeSwapMonochrome,
    },
    {
        link: 'https://lvtcapital.com',
        name: 'LVT',
        picture: lvt,
        pictureMonochrome: lvtMonochrome,
    },
    {
        link: 'https://www.newtribe.capital',
        twitter: 'https://twitter.com/NewTribeCap',
        telegram: 'https://t.me/NewTribeCapital_VC',
        name: 'newtribe',
        picture: newtribe,
        pictureMonochrome: newtribeMonochrome,
    },
    {
        link: 'https://purefi.io/',
        twitter: 'https://twitter.com/Purefi_Protocol',
        telegram: 'https://t.me/purefiprotocol',
        name: 'purefi',
        picture: purefi,
        pictureMonochrome: purefiMonochrome,
    },
    {
        link: 'https://dehive.finance/',
        twitter: 'https://twitter.com/dehive_finance',
        telegram: 'https://t.me/DeHive_chat',
        name: 'dehive',
        picture: deHive,
        pictureMonochrome: deHiveMonochrome,
    },
    {
        link: 'https://www.taraxa.io/',
        twitter: 'https://twitter.com/taraxa_project',
        telegram: 'https://t.me/taraxa_project',
        name: 'taraxa',
        picture: taraxa,
        pictureMonochrome: taraxaMonochrome,
    },
    {
        link: 'https://xsigma.fi/',
        twitter: 'https://twitter.com/xSigma5',
        telegram: 'https://t.me/xsigma_global',
        name: 'xsigma',
        picture: xsigma,
        pictureMonochrome: xsigmaMonochrome,
    },
    {
        link: 'https://unilend.finance/',
        twitter: 'https://twitter.com/UniLend_Finance',
        telegram: 'https://t.me/UniLendFinance',
        name: 'uniland',
        picture: uniland,
        pictureMonochrome: unilandMonochrome,
    },
    {
        link: 'https://lanceria.io',
        twitter: 'https://twitter.com/lancerialabs',
        telegram: 'https://t.me/lanceria',
        name: 'lanceria',
        picture: lanceria,
        pictureMonochrome: lanceriaMonochrome,
    },
    {
        link: 'https://www.parsiq.net/en/ ',
        twitter: 'https://twitter.com/parsiq_net',
        telegram: 'https://t.me/parsiq_group',
        name: 'parsiq',
        picture: parsiq,
        pictureMonochrome: parsiqMonochrome,
    },
    {
        link: 'https://playcent.com/',
        twitter: 'https://twitter.com/playcentglobal',
        telegram: 'https://t.me/playcentglobal',
        name: 'playcent',
        picture: playcent,
        pictureMonochrome: playcentMonochrome,
    },
    {
        link: 'https://www.hapi.one/ ',
        twitter: 'https://twitter.com/i_am_hapi_one',
        telegram: 'https://t.me/hapiHF',
        name: 'hapi',
        picture: hapi,
        pictureMonochrome: hapiMonochrome,
    },
    {
        link: 'https://blockchaincuties.com/',
        twitter: 'https://twitter.com/BlockchainCutie',
        telegram: 'https://t.me/blockchaincuties_en',
        name: 'cuties',
        picture: cuties,
        pictureMonochrome: cutiesMonochrome,
    },
    {
        link: 'https://www.kadena.io/',
        twitter: 'https://twitter.com/kadena_io',
        telegram: 'https://t.me/kadena_io',
        name: 'kadena',
        picture: kadena,
        pictureMonochrome: kadenaMonochrome,
    },
    {
        link: 'https://depay.fi/',
        twitter: 'https://twitter.com/DePayFi',
        telegram: 'https://t.me/DePayNews',
        name: 'depay',
        picture: dePay,
        pictureMonochrome: dePayMonochrome,
    },
    {
        link: 'http://blockchainhouse.io',
        youtube: 'https://www.youtube.com/channel/UCnWjHPwp5rHaAiKb6VRnRcw',
        name: 'blockchainhouse',
        picture: bch,
        pictureMonochrome: bchMonochrome,
    },
    {
        link: 'https://marketmaking.pro/',
        twitter: 'https://twitter.com/MarketmakingPro',
        telegram: 'https://t.me/market_making_pro_eng',
        name: 'marketMaking',
        picture: market,
        pictureMonochrome: marketMonochrome,
    },
    {
        link: 'https://www.eqifi.com/',
        twitter: 'https://twitter.com/eqifi_finance',
        telegram: 'https://www.t.me/eqifi',
        name: 'eqifi',
        picture: eqifi,
        pictureMonochrome: eqifiMonochrome,
    },
    {
        link: 'https://lossless.cash/',
        twitter: 'https://twitter.com/losslessdefi',
        telegram: 'https://t.me/losslesscash',
        name: 'lossless',
        picture: lossless,
        pictureMonochrome: losslessMonochrome,
    },
    {
        link: 'https://www.unn.finance/',
        twitter: 'https://twitter.com/unnfinance',
        telegram: 'https://t.me/UNNFinance',
        name: 'union',
        picture: union,
        pictureMonochrome: unionMonochrome,
    },
    {
        link: 'https://tomochain.com/',
        twitter: 'https://twitter.com/TomoChainANN',
        telegram: 'https://t.me/TomoChainChannel',
        name: 'tomochain',
        picture: tomo,
        pictureMonochrome: tomoMonochrome,
    },
    {
        link: 'https://www.flurry.finance/ru',
        twitter: 'https://twitter.com/FlurryFi',
        telegram: 'https://t.me/FlurryFinance_Official',
        name: 'flurry',
        picture: flurry,
        pictureMonochrome: flurryMonochrome,
    },
    {
        link: 'https://ferrum.network/',
        twitter: 'https://twitter.com/FerrumNetwork',
        telegram: 'https://t.me/ferrum_network',
        name: 'ferrum',
        picture: ferrum,
        pictureMonochrome: ferrumMonochrome,
    },
    {
        link: 'https://drops.co/',
        twitter: 'https://twitter.com/dropsnft',
        telegram: 'https://t.me/drops_nft',
        name: 'drops',
        picture: drops,
        pictureMonochrome: dropsMonochrome,
    },
    {
        link: 'https://elrond.com/',
        twitter: 'https://twitter.com/elrondnetwork',
        telegram: 'https://t.me/ElrondNetwork',
        name: 'elrond',
        picture: elrond,
        pictureMonochrome: elrondMonochrome,
    },
    {
        link: 'https://www.gamyfi.org/home',
        twitter: 'https://twitter.com/GamyFI_HQ/',
        telegram: 'https://t.me/GamyFi_ANN',
        name: 'gamyfi',
        picture: gamyFi,
        pictureMonochrome: gamyFiMonochrome,
    },
    {
        name: 'criterion',
        picture: criterion,
        pictureMonochrome: criterionMonochrome,
    },
    {
        link: 'https://www.trusttoken.com/',
        twitter: 'https://twitter.com/TrustToken',
        telegram: 'https://t.me/jointruefi',
        name: 'trust',
        picture: trust,
        pictureMonochrome: trustMonochrome,
    },
    {
        link: 'https://cryptoavengers.xyz',
        twitter: 'https://twitter.com/ThorXCrypto',
        telegram: 'https://t.me/HiCryptoAvengers',
        name: 'crypto avengers',
        picture: cryptoAvengers,
        pictureMonochrome: cryptoAvengersMonochrome,
    },
    {
        link: 'https://cartesi.io/en/',
        twitter: 'https://twitter.com/cartesiproject',
        telegram: 'https://t.me/cartesiannouncements',
        name: 'cartesi',
        picture: cartesi,
        pictureMonochrome: cartesiMonochrome,
    },
    {
        link: 'https://www.metis.io/',
        twitter: 'https://twitter.com/MetisDAO',
        telegram: 'https://t.me/MetisDAO',
        name: 'metis',
        picture: metis,
        pictureMonochrome: metisMonochrome,
    },
    {
        link: 'https://royale.finance/',
        twitter: 'https://twitter.com/Royale_Finance',
        telegram: 'https://t.me/Royalefinanceofficial',
        name: 'royale',
        picture: royale,
        pictureMonochrome: royaleMonochrome,
    },
    {
        link: 'https://saito.io/',
        twitter: 'https://twitter.com/SaitoOfficial',
        telegram: 'https://t.me/SaitoIO',
        name: 'saito',
        picture: saito,
        pictureMonochrome: saitoMonochrome,
    },
    {
        link: 'https://www.daoventures.co/',
        twitter: 'https://twitter.com/VenturesDao',
        telegram: 'https://t.me/DAOventures',
        name: 'daoventure',
        picture: daoventure,
        pictureMonochrome: daoventureMonochrome,
    },
    {
        link: 'https://opendao.io/',
        twitter: 'https://twitter.com/opendaoprotocol',
        telegram: 'https://t.me/opendao',
        name: 'opendao',
        picture: open,
        pictureMonochrome: openMonochrome,
    },
    {
        link: 'https://sharering.network/',
        twitter: 'https://twitter.com/ShareRingGlobal?s=20',
        telegram: 'https://t.me/ShareRing',
        name: 'sharering',
        picture: shareRing,
        pictureMonochrome: shareRingMonochrome,
    },
    {
        link: 'https://neo.org/',
        twitter: 'https://twitter.com/neo_blockchain',
        telegram: 'https://t.me/NEO_EN',
        name: 'neo',
        picture: neo,
        pictureMonochrome: neoMonochrome,
    },
    {
        link: 'https://www.yay.games/ ',
        twitter: 'https://twitter.com/yayprotocol',
        name: 'yay',
        picture: yay,
        pictureMonochrome: yayMonochrome,
    },
    {
        link: 'https://fuse.io/',
        twitter: 'https://twitter.com/fuse_network',
        telegram: 'https://t.me/fuseio',
        name: 'fuse',
        picture: fuse,
        pictureMonochrome: fuseMonochrome,
    },
    {
        link: 'https://www.cryptoblades.io/',
        twitter: 'https://twitter.com/BladesCrypto',
        telegram: 'https://t.me/cryptoblades_general',
        name: 'cryptoBlades',
        picture: cryptoBlades,
        pictureMonochrome: cryptoBladesMonochrome,
    },
    {
        link: 'https://www.ceek.io/',
        twitter: 'https://twitter.com/ceek',
        telegram: 'https://t.me/ceekvrtokensale',
        name: 'ceek',
        picture: ceek,
        pictureMonochrome: ceekMonochrome,
    },
    {
        link: 'https://xend.finance/',
        twitter: 'https://twitter.com/xendfinance',
        telegram: 'https://t.me/xendFinance',
        name: 'xend',
        picture: xend,
        pictureMonochrome: xendMonochrome,
    },
    {
        link: 'https://kabyarena.com/',
        twitter: 'https://twitter.com/KabyArena',
        telegram: 'https://t.me/KabyArena',
        name: 'kaby',
        picture: kaby,
        pictureMonochrome: kabyMonochrome,
    },
    {
        link: 'https://www.konomi.network/#/',
        twitter: 'https://twitter.com/KonomiNetwork',
        telegram: 'https://t.me/konominetwork',
        name: 'konomi',
        picture: konomi,
        pictureMonochrome: konomiMonochrome,
    },
    {
        link: 'https://covercompared.com/',
        twitter: 'https://twitter.com/CoverCompared',
        telegram: 'https://t.me/CoverCompared',
        name: 'coverCompared',
        picture: coverCompared,
        pictureMonochrome: coverComparedMonochrome,
    },
];

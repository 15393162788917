import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_MAIN } from 'routes/names';

import logo from 'assets/img/logo-full.svg';
import './style.scss';

import { HeaderWallet } from '../HeaderWallet'

const HeaderService: FC<{}> = ({}) => {
    const history = useHistory();

    return (
        <div className={'header-staking'}>
            <div className={'logo-holder'}>
                <img
                    src={logo}
                    alt={'logo'}
                    className={'logo'}
                    onClick={() => history.push(ROUTE_MAIN)}
                />
                <div className={'name'}>Messenger & Crypto Wallet</div>
            </div>

            <HeaderWallet />
        </div>
    );
};

export default HeaderService;

import { FC } from 'react'

export const SubmitButton: FC<any> = ({
    disabled,
    needToApproveTokens,
    loading,
    openWalletModal,
    wallet,
    balanceLoading,
    onSubmit,
    isValidChain,
}) => {
    if (!wallet) {
        return <button className="airdrop-button" onClick={openWalletModal}>Connect Wallet</button>
    }

    if (!isValidChain) {
        return <button className="airdrop-button" onClick={openWalletModal} disabled>Switch network</button>
    }

    if (balanceLoading) {
        return <button className="airdrop-button airdrop-button-loading"  disabled>Send</button>
    }

    if (loading && needToApproveTokens) {
        return <button className="airdrop-button airdrop-button-loading" disabled>Approving...</button>
    }

    if (loading) {
        return <button className="airdrop-button airdrop-button-loading"  disabled>Loading...</button>
    }

    return (
        <button
            className="airdrop-button"
            onClick={onSubmit}
            disabled={disabled}
        >
            {needToApproveTokens
                ? 'Approve Tokens'
                : 'Send'
            }
        </button>
    )
}
import { Token } from 'symbiosis-js-sdk';

import { ChainNames as GlobalChainNames } from 'config';

export type ChainNamesType = GlobalChainNames.Bsc | GlobalChainNames.Polygon

export interface RouteProps {
    tokens?: Token[];
    token: ChainNamesType;
}

export enum ChainPositionType {
    From,
    To,
}

export type ChainsPositions = Record<ChainPositionType, GlobalChainNames.Bsc | GlobalChainNames.Polygon>

export type SwapAmount = Record<ChainPositionType, string>

export type ChainBalances = Record<ChainNamesType, string>

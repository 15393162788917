import { PLATFORMS_ENUM } from 'types'
import { DEVICES_LINKS, PROJECT_SHORT_NAME } from 'config';
import { DownloadLink } from './DownloadLink'
import { Button } from 'components/form/Button'

type OnePlatformDownloadLinkProps = {
    showAll: () => void,
    devicePlatform: PLATFORMS_ENUM,
}

export const OnePlatformDownloadLink: React.FC<OnePlatformDownloadLinkProps> = ({
    showAll,
    devicePlatform,
}) => {
    if (devicePlatform === PLATFORMS_ENUM.UNSET) {
        showAll()

        return null
    }

    return <div className='onePlatformDownload'>
        <DownloadLink href={DEVICES_LINKS[devicePlatform].link}>
            {PROJECT_SHORT_NAME} for {DEVICES_LINKS[devicePlatform].label} {DEVICES_LINKS[devicePlatform].arch}
        </DownloadLink>
        <Button
            className={`show-all-platforms`}
            onClick={showAll}
        >
            Show all platforms
        </Button>
    </div>
}
import { styled } from '@stitches/react'
import * as SelectPrimitive from '@radix-ui/react-select'

export const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
})

export const StyledIcon = styled(SelectPrimitive.SelectIcon, {})

export const StyledContent = styled(SelectPrimitive.Content, {
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
})

export const StyledViewport = styled(SelectPrimitive.Viewport, {
    padding: 5,
})

export const StyledItem = styled(SelectPrimitive.Item, {
    all: 'unset',
    userSelect: 'none',
    cursor: 'pointer',

    '&[data-disabled]': {
        pointerEvents: 'none',
    },
})

import { Fragment } from 'react'
import { DEVICES_LINKS, PROJECT_SHORT_NAME } from 'config';
import { PlatformLinks, PLATFORMS_ENUM } from 'types';
import { DownloadLink } from './DownloadLink'

const PlatformAdditionalLink: React.FC<{ label: string, link: string }> = ({ label, link }) => {
    return (
        <Fragment key={link} >
            <DownloadLink href={link} additional>{label}</DownloadLink>
            <span>•</span>
        </Fragment>
    )
}

const PlatformLink: React.FC<PlatformLinks> = ({ label, arch, link, additionalLinks }) => {
    return (
        <div className="platform-links" key={label}>
            <DownloadLink href={link}>
                {PROJECT_SHORT_NAME} for {label} {arch}
            </DownloadLink>
            <div className="additional-links">
                {additionalLinks?.map(PlatformAdditionalLink)}
            </div>
        </div>
    )
}

export const AllPlatformsDownloadLink: React.FC<{}> = () => {
    return (
        <div className="all-platforms">
            <PlatformLink {...DEVICES_LINKS[PLATFORMS_ENUM.WINDOWS_64]}/>
            <PlatformLink {...DEVICES_LINKS[PLATFORMS_ENUM.MAC]}/>
            <PlatformLink {...DEVICES_LINKS[PLATFORMS_ENUM.LINUX_64]}/>
            <PlatformLink {...DEVICES_LINKS[PLATFORMS_ENUM.WINDOWS_32]}/>
        </div>
    )
}
import { FC, useState } from 'react';
import './style.scss';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { COIN_LIME } from 'config';
import classNames from 'classnames';
import down from 'assets/img/landing/down.svg';
import { TokenMetrics } from 'types';

interface ILimeMetrics {
    metrics: TokenMetrics;
}

const LimeMetrics: FC<ILimeMetrics> = ({ metrics }) => {
    const data01 = [
        {
            name: 'Hold And Stock',
            value: metrics.distribution.combined.holdAndStock.percent,
        },
        {
            name: 'Circulating Supply',
            value: metrics.distribution.combined.circulatingSupply.percent,
        },
        {
            name: 'DEX Liquidity',
            value: metrics.distribution.combined.DEXLiquidity.percent,
        },
    ];

    const data02 = [
        {
            name: 'Total Supply',
            value: 150,
        },
    ];

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const openInfoToggle = () => setIsOpen(!isOpen);

    return (
        <div className={'lime-metrics'}>
            <div className={'title'}>Lime Metrics</div>

            <div className={'metric-block-external'}>
                <div className={'metric-block'}>
                    <div className={'pie'}>
                        <ResponsiveContainer height="100%" width="100%">
                            <PieChart>
                                <defs>
                                    <linearGradient
                                        id={`myGradient`}
                                        gradientTransform="rotate(90)"
                                    >
                                        <stop
                                            offset="0%"
                                            stopColor={'#3B2184'}
                                        />
                                        <stop
                                            offset="100%"
                                            stopColor={'#AD87FF'}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id={`myGradient2`}
                                        gradientTransform="rotate(90)"
                                    >
                                        <stop
                                            offset="0%"
                                            stopColor={'#FF9227'}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor={'#FFD4A9'}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id={`myGradient3`}
                                        gradientTransform="rotate(90)"
                                    >
                                        <stop
                                            offset="0%"
                                            stopColor={'#C4FA9B'}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor={'#7DDB86'}
                                        />
                                    </linearGradient>
                                </defs>
                                <Pie
                                    stroke="none"
                                    data={data01}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={47}
                                    outerRadius={83}
                                    fill="#82ca9d"
                                >
                                    <Cell fill={'url(#myGradient)'} />
                                    <Cell fill={'url(#myGradient3)'} />
                                    <Cell fill={'url(#myGradient2)'} />
                                </Pie>
                                <Pie
                                    stroke="none"
                                    data={data02}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={92}
                                    outerRadius={100}
                                    fill="#006AED"
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div className={'percents'}>
                        <div className={'data'}>
                            <div className={'title-sector supply'}>
                                <div
                                    className={'block clickable'}
                                    onClick={() => openInfoToggle()}
                                >
                                    <div
                                        className={'bullet'}
                                        style={{ background: '#006AED' }}
                                    />
                                    Total Supply{' '}
                                    <span>
                                        {parseFloat(
                                            metrics.distribution.combined
                                                .totalSupply.value
                                        ).toLocaleString()}{' '}
                                        {COIN_LIME}
                                    </span>
                                    <img
                                        src={down}
                                        alt={'open'}
                                        className={classNames({
                                            down: true,
                                            open: isOpen,
                                        })}
                                    />
                                </div>
                                <div
                                    className={classNames({
                                        block: true,
                                        chains: true,
                                        open: isOpen,
                                    })}
                                >
                                    <div>
                                        <span className={'erc20'}>
                                            {COIN_LIME} ERC20
                                        </span>{' '}
                                        •{' '}
                                        {parseFloat(
                                            metrics.distribution.eth.totalSupply
                                                .value
                                        ) / 1000000}
                                        M
                                    </div>
                                    <div>
                                        <span className={'bep20'}>
                                            {COIN_LIME} BEP20
                                        </span>{' '}
                                        •{' '}
                                        {parseFloat(
                                            metrics.distribution.bsc.totalSupply
                                                .value
                                        ) / 1000000}
                                        M
                                    </div>
                                    <div>
                                        <span className={'polygon'}>
                                            {COIN_LIME} Polygon
                                        </span>{' '}
                                        •{' '}
                                        {parseFloat(
                                            metrics.distribution.polygon
                                                .totalSupply.value
                                        ) / 1000000}
                                        M
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'data'}>
                            <div className={'title-sector'}>
                                <div
                                    className={'bullet'}
                                    style={{ background: '#3B2184' }}
                                />
                                Hold & Lock
                            </div>
                            <div className={'percent'}>
                                {
                                    metrics.distribution.combined.holdAndStock
                                        .percent
                                }{' '}
                                % •{' '}
                                {parseFloat(
                                    metrics.distribution.combined.holdAndStock
                                        .value
                                ).toLocaleString()}{' '}
                                {COIN_LIME}
                            </div>
                        </div>
                        <div className={'data'}>
                            <div className={'title-sector'}>
                                <div
                                    className={'bullet'}
                                    style={{ background: '#FF9F40' }}
                                />
                                DEX Liquidity
                            </div>
                            <div className={'percent'}>
                                {
                                    metrics.distribution.combined.DEXLiquidity
                                        .percent
                                }{' '}
                                % •{' '}
                                {parseFloat(
                                    metrics.distribution.combined.DEXLiquidity
                                        .value
                                ).toLocaleString()}{' '}
                                {COIN_LIME}
                            </div>
                        </div>
                        <div className={'data'}>
                            <div className={'title-sector'}>
                                <div
                                    className={'bullet'}
                                    style={{ background: '#92E48C' }}
                                />
                                Circulating Supply
                            </div>
                            <div className={'percent'}>
                                {
                                    metrics.distribution.combined
                                        .circulatingSupply.percent
                                }{' '}
                                % •{' '}
                                {parseFloat(
                                    metrics.distribution.combined
                                        .circulatingSupply.value
                                ).toLocaleString()}{' '}
                                {COIN_LIME}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'prices'}>
                    <div>
                        Current Price{' '}
                        <span>{metrics.market.price.toLocaleString()} $</span>
                    </div>
                    <div>
                        Market Cap{' '}
                        <span>
                            {metrics.market.capitalization.toLocaleString()} $
                        </span>
                    </div>
                    <div>
                        Fully Diluted Market Cap{' '}
                        <span>
                            {metrics.market.fullyDilutedCapitalization.toLocaleString()}{' '}
                            $
                        </span>
                    </div>
                    <div>
                        Trading Volume 24h{' '}
                        <span>
                            {metrics.market.tradingVolume24h.toLocaleString()} $
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LimeMetrics;

import { FC } from 'react'
import { COIN_LIME, ChainNames } from 'config'
import classNames from 'classnames';
import { ReactComponent as LimeIcon } from 'assets/img/bnbLime.svg';
import { SwapExactIn } from 'symbiosis-js-sdk/dist/crosschain/baseSwapping';

export const SwapMeta: FC<{ fromChain: ChainNames, amount: string, expectedAmount: string, swapMeta?: Awaited<SwapExactIn> }> = ({
    expectedAmount,
    amount,
    swapMeta,
    fromChain,
}) => {
    if (!swapMeta) {
        return (
            <div>
                <div>Slippage Tolerance</div>
                <div>3 %</div>
            </div>
        )
    }

    const fixedPriceImpact = +swapMeta?.priceImpact?.toFixed(3);

    return (
        <>
            <div className="swap-form-meta-price">
                <div>Price</div>
                <div>
                    <strong>
                        {(parseFloat(amount) / parseFloat(expectedAmount)).toFixed(6)}
                    </strong>
                    &nbsp;
                    <LimeIcon className={classNames(
                        "swap-form-meta-icon",
                        fromChain === ChainNames.Bsc ? "swap-form-meta-icon-bnb" : "swap-form-meta-icon-polygon"
                    )} />
                    <strong>{COIN_LIME}</strong>
                    <span className="swap-form-meta-price-prefix">&nbsp;per&nbsp;</span>
                    <LimeIcon className={classNames(
                        "swap-form-meta-icon",
                        fromChain === ChainNames.Bsc ? "swap-form-meta-icon-polygon" : "swap-form-meta-icon-bnb"
                    )} />
                    <strong>{COIN_LIME}</strong>
                </div>
            </div>
            <div>
                <div>Slippage Tolerance</div>
                <div>3 %</div>
            </div>
            <div>
                <div>Amount to be sent</div>
                <div>
                    ${' '}
                    {swapMeta?.amountInUsd?.toFixed(2)}
                </div>
            </div>
            <div>
                <div>Gas Fee</div>
                <div>
                    {swapMeta?.fee?.toFixed(1)}{' '}
                    {swapMeta?.fee?.token.symbol}
                </div>
            </div>
            <div
                className={classNames(
                    fixedPriceImpact && {
                        'swap-price-impact-warning':
                            fixedPriceImpact >= 1,
                        'swap-price-impact-danger':
                            fixedPriceImpact >= 5,
                        'swap-price-impact-ok':
                            fixedPriceImpact < 0,
                    },
                    'swap-price-impact'
                )}
            >
                <div>Price Impact</div>
                <div>{fixedPriceImpact}%</div>
            </div>
        </>
    )
}
import { Dispatch } from 'react';
import {
    Campaign,
    CatalogAllChannelsItem,
    CatalogCampaignCategory,
    CatalogCategory,
    CatalogCountry,
    CatalogLanguage,
    CatalogTag,
    Error,
} from 'types';
import { catalogApi } from 'api';
import { AppStateType } from 'store';
import { CATALOG_PREVIEW_CATEGORY_SIZE, STORAGE_LANGUAGE } from 'config';
import { API_STATUS } from 'api';

const SET_CURRENT_CHANNEL = 'Catalog/SET_CURRENT_CHANNEL';
const SET_CATALOG_TAB = 'Catalog/SET_CATALOG_TAB';
const SET_CURRENT_CATEGORY = 'Catalog/SET_CURRENT_CATEGORY';
const CHECK_CHANNEL_START = 'Catalog/CHECK_CHANNEL_START';
const SET_CHECKED_CHANNEL = 'Catalog/SET_CHECKED_CHANNEL';
const SET_CHECKED_CHANNEL_ERROR = 'Catalog/SET_CHECKED_CHANNEL_ERROR';
const SET_CREATE_STAFF = 'Catalog/SET_CREATE_STAFF';
const SET_CATEGORIES = 'Catalog/SET_CATEGORIES';
const GET_POPULAR_START = 'Catalog/GET_POPULAR_START';
const GET_POPULAR_FINISH = 'Catalog/GET_POPULAR_FINISH';
const GET_CURRENT_CATEGORY_CHANNELS_START =
    'Catalog/GET_CURRENT_CATEGORY_CHANNELS_START';
const GET_CURRENT_CATEGORY_CHANNELS = 'Catalog/GET_CURRENT_CATEGORY_CHANNELS';
const GET_USER_CHANNELS_FINISH = 'Catalog/GET_USER_CHANNELS_FINISH';
const GET_USER_CHANNELS_START = 'Catalog/GET_USER_CHANNELS_START';
const GET_ALL_CHANNELS_FINISH = 'Catalog/GET_ALL_CHANNELS_FINISH';
const GET_ALL_CHANNELS_START = 'Catalog/GET_ALL_CHANNELS_START';
const CANCEL_CAMPAIGN = 'Catalog/CANCEL_CAMPAIGN';
const CREATE_CAMPAIGN = 'Catalog/CREATE_CAMPAIGN';
const CREATE_CAMPAIGN_ERROR = 'Catalog/CREATE_CAMPAIGN_ERROR';
const CREATE_CAMPAIGN_START = 'Catalog/CREATE_CAMPAIGN_START';
const RESTORE_CAMPAIGN = 'Catalog/RESTORE_CAMPAIGN';
const CLEAR = 'Catalog/CLEAR';
const SET_CATALOG_MODE = 'Catalog/SET_CATALOG_MODE';
const SET_SELECTED_TAG_INDEX = 'Catalog/SET_SELECTED_TAG_INDEX';
const SET_IS_CATEGORY = 'Catalog/SET_IS_CATEGORY';
const SET_LANGUAGES = 'Catalog/SET_LANGUAGES';
const SET_CURRENT_LANGUAGE = 'Catalog/SET_CURRENT_LANGUAGE';

interface ICatalogStore<T> {
    channel: T | null;
    group: T | null;
}

export enum CatalogMainTabs {
    channel = 'channel',
    group = 'group',
}

interface ICatalog {
    currentChannel: Campaign | null;
    checkedChannel: Campaign | null;
    isLoading: boolean;
    isLoadingCreate: boolean;
    isLoadingPopular: boolean;
    categories: ICatalogStore<CatalogCategory[]>;
    popularChannels: ICatalogStore<Campaign[]>;
    userChannels: Campaign[] | null;
    currentCategory: ICatalogStore<CatalogCategory>;
    currentCategoryChannels: ICatalogStore<Campaign[]>;
    tags: CatalogTag[];
    languages: CatalogLanguage[];
    countries: CatalogCountry[];
    createCategories: CatalogCampaignCategory[];
    allChannels: ICatalogStore<CatalogAllChannelsItem[]>;
    isLoadingAll: boolean;
    isLoadingUsers: boolean;
    error: ICatalogStore<Error>;
    catalogTabIndex: number;
    isCategory: ICatalogStore<boolean>;
    popularCursor: ICatalogStore<string>;
    previewCursor: ICatalogStore<string>;
    currentMode: CatalogMainTabs;
    selectedTagIndex: ICatalogStore<number>;
    currentLanguageId: number | null;
}

interface ISystemAction {
    type: string;
    payload: any;
    mode: CatalogMainTabs;
}

const initialState: ICatalog = {
    currentChannel: null,
    checkedChannel: null,
    isLoadingCreate: false,
    isLoading: false,
    isLoadingPopular: false,
    categories: { channel: null, group: null },
    popularChannels: { channel: null, group: null },
    currentCategory: { channel: null, group: null },
    currentCategoryChannels: { channel: null, group: null },
    tags: [],
    languages: [],
    countries: [],
    createCategories: [],
    userChannels: null,
    allChannels: { channel: null, group: null },
    isLoadingUsers: false,
    isLoadingAll: false,
    error: { channel: null, group: null },
    catalogTabIndex: 0,
    isCategory: { channel: false, group: false },
    popularCursor: { channel: '', group: '' },
    previewCursor: { channel: '', group: '' },
    currentMode: CatalogMainTabs.channel,
    selectedTagIndex: { channel: -1, group: -1 },
    currentLanguageId: localStorage.getItem(STORAGE_LANGUAGE)
        ? JSON.parse(localStorage.getItem(STORAGE_LANGUAGE)!).id
        : null,
};

export const setCatalogTab = (index: number) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CATALOG_TAB,
            payload: index,
        });
    };
};

export const setIsCategory = (flag: boolean) => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const mode = getState().catalog.currentMode;
        dispatch({
            type: SET_IS_CATEGORY,
            payload: flag,
            mode: mode,
        });
    };
};

export const setCurrentChannel = (channel: Campaign) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CURRENT_CHANNEL,
            payload: channel,
        });
    };
};

export const setCurrentLanguage = (id: number) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CURRENT_LANGUAGE,
            payload: id,
        });
    };
};

export const setSelectedTagIndex = (index: number) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_SELECTED_TAG_INDEX,
            payload: index,
        });
    };
};

export const setCatalogMode = (mode: CatalogMainTabs) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CATALOG_MODE,
            payload: mode,
        });
    };
};

export const getCategories = () => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const mode = getState().catalog.currentMode;
        const lang = getState().catalog.currentLanguageId;
        catalogApi.getCatalogCategories(mode, lang).then((res) => {
            dispatch({
                type: SET_CATEGORIES,
                payload: res,
                mode: mode,
            });
        });
    };
};

export const getLanguages = () => {
    return (dispatch: Dispatch<any>) => {
        catalogApi.getLanguages().then((res) => {
            dispatch({
                type: SET_LANGUAGES,
                payload: res,
            });
        });
    };
};

export const createCampaign = (
    channel: string,
    languageId: number,
    categoryId: number,
    tagIds: number[],
    countryIds: number[]
) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: CREATE_CAMPAIGN_START,
        });
        catalogApi
            .createCampaign(channel, languageId, categoryId, tagIds, countryIds)
            .then((res) => {
                res.status !== 'error'
                    ? dispatch({
                          type: CREATE_CAMPAIGN,
                          payload: res.payload,
                      })
                    : dispatch({
                          type: CREATE_CAMPAIGN_ERROR,
                          payload: res,
                      });
            });
    };
};

export const cancelCampaign = (id: number) => {
    return (dispatch: Dispatch<any>) => {
        catalogApi.cancelCampaign(id).then((res) => {
            dispatch({
                type: CANCEL_CAMPAIGN,
                payload: res,
            });
        });
    };
};

export const restoreCampaign = (id: number) => {
    return (dispatch: Dispatch<any>) => {
        catalogApi.restoreCampaign(id).then((res) => {
            dispatch({
                type: RESTORE_CAMPAIGN,
                payload: res,
            });
        });
    };
};

export const setCurrentCategory = (category: CatalogCategory) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CURRENT_CATEGORY,
            payload: category,
        });
    };
};

export const getAllChannels = () => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const mode = getState().catalog.currentMode;
        const lang = getState().catalog.currentLanguageId;
        dispatch({
            type: GET_ALL_CHANNELS_START,
            mode: mode,
        });
        catalogApi
            .getAllCatalog(
                getState().catalog.previewCursor[mode]!,
                CATALOG_PREVIEW_CATEGORY_SIZE,
                mode,
                lang
            )
            .then((res) => {
                dispatch({
                    type: GET_ALL_CHANNELS_FINISH,
                    payload: res,
                    mode: mode,
                });
            });
    };
};

export const getUserChannels = () => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: GET_USER_CHANNELS_START,
        });
        const mode = getState().catalog.currentMode;
        catalogApi.getUserCampaigns(mode).then((res) => {
            dispatch({
                type: GET_USER_CHANNELS_FINISH,
                payload: res,
                mode: mode,
            });
        });
    };
};

export const setCurrentCategoryChannels = (
    category: CatalogCategory,
    size: number
) => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: GET_CURRENT_CATEGORY_CHANNELS_START,
        });
        dispatch(setCurrentCategory(category));
        const mode = getState().catalog.currentMode;
        const lang = getState().catalog.currentLanguageId;
        catalogApi
            .getCatalogByCategory(category.category.id, size, mode, lang)
            .then((res) => {
                dispatch({
                    type: GET_CURRENT_CATEGORY_CHANNELS,
                    payload: res,
                    mode: mode,
                });
            });
    };
};

export const getPopularChannels = () => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: GET_POPULAR_START,
        });
        const mode = getState().catalog.currentMode;
        const lang = getState().catalog.currentLanguageId;
        catalogApi
            .getPopular(getState().catalog.popularCursor[mode]!, mode, lang)
            .then((res) => {
                dispatch({
                    type: GET_POPULAR_FINISH,
                    payload: res,
                    mode: mode,
                });
            });
    };
};

export const checkChannel = (name: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: CHECK_CHANNEL_START,
        });

        catalogApi.checkChannel(name).then((response) => {
            if (response.status === API_STATUS.OK) {
                const res = response.payload;
                const channel = {
                    channel: {
                        id: res.id,
                        membersCount: res.participants,
                        photo: res.avatarUrl,
                        title: res.title,
                        description: res.description,
                    },
                };
                dispatch({
                    type: SET_CHECKED_CHANNEL,
                    payload: { ...res, ...channel },
                });
            } else {
                dispatch({
                    type: SET_CHECKED_CHANNEL_ERROR,
                    payload: response,
                });
            }
        });
    };
};

export const getCreateStaff = () => {
    return (dispatch: Dispatch<any>) => {
        Promise.all([
            catalogApi.getCategories(),
            catalogApi.getTags(),
            catalogApi.getLanguages(),
            catalogApi.getCountries(),
        ]).then((value) => {
            dispatch({
                type: SET_CREATE_STAFF,
                payload: value,
            });
        });
    };
};

export const createChannelClear = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: CLEAR,
        });
    };
};

const setState = (
    state: ICatalogStore<any> | null,
    payload: any,
    type: CatalogMainTabs
) => {
    if (!state) state = { channel: null, group: null };
    state[type] = payload;
    return state;
};

const CatalogReducer = (
    state = initialState,
    action: ISystemAction
): ICatalog => {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                error: { channel: null, group: null },
                checkedChannel: null,
            };
        case SET_CURRENT_CHANNEL:
            return {
                ...state,
                currentChannel: action.payload,
            };

        case SET_CATALOG_TAB:
            return {
                ...state,
                catalogTabIndex: action.payload,
            };

        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: setState(
                    state.currentCategory,
                    action.payload,
                    state.currentMode
                ),
            };

        case SET_CATEGORIES:
            return {
                ...state,
                categories: setState(
                    state.categories,
                    action.payload,
                    action.mode
                ),
            };

        case GET_CURRENT_CATEGORY_CHANNELS_START: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GET_CURRENT_CATEGORY_CHANNELS:
            return {
                ...state,
                isLoading: false,
                isCategory: setState(state.isCategory, true, action.mode),
                currentCategoryChannels: setState(
                    state.currentCategoryChannels,
                    action.payload,
                    action.mode
                ),
            };

        case CHECK_CHANNEL_START:
            return {
                ...state,
                isLoading: true,
                checkedChannel: null,
                error: { channel: null, group: null },
            };

        case SET_CHECKED_CHANNEL:
            return {
                ...state,
                isLoading: false,
                checkedChannel: action.payload,
            };

        case SET_CHECKED_CHANNEL_ERROR:
            return {
                ...state,
                isLoading: false,
                error: setState(state.error, action.payload, state.currentMode),
            };
        case SET_CREATE_STAFF:
            return {
                ...state,
                createCategories: action.payload[0],
                tags: action.payload[1],
                languages: action.payload[2],
                countries: action.payload[3],
            };

        case GET_POPULAR_START:
            return {
                ...state,
                isLoadingPopular: true,
            };
        case GET_POPULAR_FINISH:
            let popular = state.popularChannels[action.mode];
            if (!popular) popular = [];
            return {
                ...state,
                isLoadingPopular: false,
                popularChannels: setState(
                    state.popularChannels,
                    [...popular, ...action.payload.items],
                    action.mode
                ),
                popularCursor: setState(
                    state.popularCursor,
                    action.payload.meta.nextCursor,
                    action.mode
                ),
            };

        case GET_USER_CHANNELS_START:
            return {
                ...state,
                isLoadingUsers: true,
            };
        case GET_USER_CHANNELS_FINISH:
            return {
                ...state,
                userChannels: action.payload,
                isLoadingUsers: false,
            };

        case GET_ALL_CHANNELS_START:
            return {
                ...state,
                isLoadingAll: true,
            };

        case GET_ALL_CHANNELS_FINISH:
            let all = state.allChannels[action.mode];
            if (!all) all = [];
            return {
                ...state,
                isLoadingAll: false,
                isCategory: setState(state.isCategory, false, action.mode),
                allChannels: setState(
                    state.allChannels,
                    [...all, ...action.payload.items],
                    action.mode
                ),
                previewCursor: setState(
                    state.previewCursor,
                    action.payload.meta.nextCursor,
                    action.mode
                ),
            };

        case SET_IS_CATEGORY:
            return {
                ...state,
                isCategory: setState(
                    state.isCategory,
                    action.payload,
                    action.mode
                ),
            };

        case CREATE_CAMPAIGN:
            let tmp = state.userChannels;
            if (tmp === null) tmp = [];
            return {
                ...state,
                userChannels: [...tmp, action.payload],
                isLoadingCreate: false,
            };

        case CREATE_CAMPAIGN_START:
            return {
                ...state,
                error: { channel: null, group: null },
                isLoadingCreate: true,
            };

        case CREATE_CAMPAIGN_ERROR:
            return {
                ...state,
                isLoadingCreate: false,
                error: setState(state.error, action.payload, state.currentMode),
            };

        case SET_CATALOG_MODE:
            return {
                ...state,
                isLoadingCreate: false,
                currentMode: action.payload,
            };

        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            };

        case SET_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguageId: action.payload,
                popularCursor: { channel: null, group: null },
                popularChannels: { channel: null, group: null },
                categories: { channel: null, group: null },
                previewCursor: { channel: null, group: null },
                allChannels: { channel: null, group: null },
                currentCategoryChannels: { channel: null, group: null },
                currentCategory: { channel: null, group: null },
                selectedTagIndex: { channel: -1, group: -1 },
            };

        case SET_SELECTED_TAG_INDEX:
            return {
                ...state,
                isLoadingCreate: false,
                selectedTagIndex: setState(
                    state.selectedTagIndex,
                    action.payload,
                    state.currentMode
                ),
            };
        default:
            return state;
    }
};

export default CatalogReducer;

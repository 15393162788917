import { RefObject, useEffect, useState } from 'react';

export const useCanvas = (
    animName: string,
    id: string,
    canvas: RefObject<HTMLCanvasElement>,
    anim_container: RefObject<HTMLDivElement>,
    dom_overlay_container: RefObject<HTMLDivElement>,
    isVisible: boolean,
    isInfinity?: boolean
) => {
    const [itWasInit, setItWasInit] = useState<boolean>(false);
    const [itWasPlayed, setItWasPlayed] = useState<boolean>(false);
    const [myStage, setStage] = useState<any>();

    const PHONE_ANIMATION_LENGTH = 75;

    useEffect(() => {
        var stage: any, exportRoot: any, fnStartAnimation: any;

        function init() {
            var comp = window.AdobeAn.getComposition(id);
            var lib = comp.getLibrary();
            handleComplete({}, comp);
        }
        const listener = () => {
            if (
                isInfinity ||
                myStage.children[0].currentFrame < PHONE_ANIMATION_LENGTH
            ) {
                myStage.update();
            } else {
                window.createjs.Ticker.removeEventListener('tick', listener);
            }
        };

        function handleComplete(evt: any, comp: any) {
            //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
            var lib = comp.getLibrary();
            var ss = comp.getSpriteSheet();
            exportRoot = new lib[animName]();
            stage = new lib.Stage(canvas.current);
            setStage(stage);
            window.createjs.Ticker.timingMode =
                window.createjs.Ticker.RAF_SYNCHED;

            fnStartAnimation = function () {
                stage.addChild(exportRoot);
                window.createjs.Ticker.framerate = lib.properties.fps;
                stage.update();
            };
            //Code to support hidpi screens and responsive scaling.
            window.AdobeAn.makeResponsive(
                false,
                'both',
                false,
                1,
                [
                    canvas.current,
                    anim_container.current,
                    dom_overlay_container.current,
                ],
                stage,
                lib.properties
            );
            window.AdobeAn.compositionLoaded(lib.properties.id);
            fnStartAnimation();
        }

        if (
            canvas.current &&
            anim_container.current &&
            dom_overlay_container.current &&
            !itWasInit
        ) {
            init();
            setItWasInit(true);
        }
        if (isVisible && itWasInit && !itWasPlayed) {
            window.createjs.Ticker.addEventListener('tick', listener);
            setItWasPlayed(true);
        }

        return () => {
            if (isInfinity)
                window.createjs.Ticker.removeEventListener('tick', listener);
        };
    }, [canvas, anim_container, dom_overlay_container, isVisible, itWasInit]);
};

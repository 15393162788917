import { useCallback, FC, MouseEvent, useState } from 'react'
import { Chain } from 'config/chains'
import { ReactComponent as DropdownPic } from 'assets/img/up-small.svg';
import { ReactComponent as SelectedPic } from 'assets/img/tick.svg';
import infoPic from 'assets/img/exclamation-circle.svg';
import { Dialog } from 'components/Dialog';
import { ReactComponent as LoaderCircle } from 'assets/img/loader-circle.svg'
import classNames from 'classnames'

import { useWeb3Wallet } from 'modules/web3/provider'

import {
    NetworkList,
    NetworkListItem,
    SelectedNetwork,
    NetworkIcon,
    SelectNetworkContainer,
    NetworkListContainerBody,
    NetworkListContainer,
    NetworkListCaption,
} from './style'
import { WALLET_TYPE } from 'modules/web3'

type SelectNetworkProps = {
    chains: Chain[],
    currentChain?: Chain,
}

export const SelectNetwork: FC<SelectNetworkProps> = ({ chains, currentChain }) => {
    const { switchChain, walletType, currentChainId } = useWeb3Wallet()
    const [dialogIsOpen, toggleDialog] = useState(false)

    const selectAndClose = useCallback(async (e: MouseEvent<HTMLLIElement>) => {
        const chainId = (e.currentTarget as HTMLLIElement).value
        if (currentChainId === chainId) {
            return
        }

        if (walletType === WALLET_TYPE.WALLET_CONNECT) {
            toggleDialog(true)
        }

        try {
            await switchChain(chainId)
        } finally {
            toggleDialog(false)
        }
    }, [switchChain, toggleDialog, walletType, currentChainId])

    return (
        <SelectNetworkContainer>
            <Dialog
                open={dialogIsOpen}
                close={() => toggleDialog(false)}
                className="airdrop-progress-dialog-container airdrop-progress-dialog-container--inprogress"
                content={
                    <div className="airdrop-progress-dialog">
                        <div className="airdrop-progress-dialog-loader">
                            <div className={classNames('loader')}><LoaderCircle /></div>
                        </div>
                        <h2>Change network</h2>
                        <p className="airdrop-progress-dialog-text">
                            Open the connected extension and change the network.
                        </p>
                    </div>
                }
            />
            {currentChain
                ? (
                    <SelectedNetwork style={{ background: currentChain.color }}>
                        <NetworkIcon src={currentChain.icon} />
                        {currentChain.label}
                        <DropdownPic />
                    </SelectedNetwork>
                )
                : (
                    <SelectedNetwork style={{ background: '#93aac8' }}>
                        <NetworkIcon className="empty-network-icon" src={infoPic} />
                        Switch network
                        <DropdownPic />
                    </SelectedNetwork>
                )
            }

            <NetworkListContainer className="networkListContainer">
                <NetworkListContainerBody>
                    <NetworkListCaption>Network for tokens:</NetworkListCaption>
                        <NetworkList>
                            {chains.map((chain: Chain) => (
                                <NetworkListItem
                                    key={chain.chainId}
                                    value={chain.chainId}
                                    onClick={selectAndClose}
                                >
                                    <NetworkIcon src={chain.icon} />
                                    {chain.label}
                                    {chain.chainId === currentChain?.chainId && <SelectedPic />}
                                </NetworkListItem>
                            ))}
                        </NetworkList>
                </NetworkListContainerBody>
            </NetworkListContainer>
        </SelectNetworkContainer>
    )
}
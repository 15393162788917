import React from 'react';

interface LogConsoleProps {
    logs: string[];
}

export const LogConsole: React.FC<LogConsoleProps> = ({ logs }) => {
    return (
        <div>
            {logs.map((log, index) => (
                <p key={index}>{log}</p>
            ))}
        </div>
    );
};
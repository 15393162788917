import { FC } from 'react'
import { COIN_LIME } from 'config';
import { formatBalance } from 'utils/formatBalance'

export const WalletState: FC<{ wallet?: string, balance: string, balanceLoading: boolean }> = ({ wallet, balance, balanceLoading }) => {
    if (!wallet) {
        return null
    }

    return (
        <div className="airdrop-wallet">
            <div>
                Your address: <strong>{wallet}</strong>
            </div>
            <div>
                Your wallet balance:{' '}
                {balanceLoading && <strong>Loading...</strong>}
                {!balanceLoading && (
                    <strong
                        style={{
                            color:
                                balance === '0'
                                    ? '#fd6868'
                                    : 'inherit',
                        }}
                    >
                        {formatBalance(balance)} {COIN_LIME}
                    </strong>
                )}
            </div>
        </div>
    )
}
import { FC } from 'react';
import bg from 'assets/img/landing/head-bg.png';
import { ReactComponent as Telegram } from 'assets/img/socials/Telegram.svg';
import { openNewTab } from 'utils';
import {
    EMAIL_INFO,
    EMAIL_SUPPORT,
    LINK_GITHUB,
    LINK_MEDIUM,
    LINK_REDDIT,
    LINK_TELEGRAM,
    LINK_TWITTER,
    LINK_YOUTUBE,
} from 'config';
import { ReactComponent as Twitter } from 'assets/img/socials/Twitter.svg';
import { ReactComponent as Medium } from 'assets/img/socials/Medium.svg';
import { ReactComponent as Youtube } from 'assets/img/socials/Youtube.svg';
import { ReactComponent as Reddit } from 'assets/img/socials/Reddit.svg';
import { ReactComponent as Github } from 'assets/img/socials/Github.svg';

import * as S from './style';

const Contacts: FC = () => {
    return (
        <S.Wrapper
            className={'contacts page-bg'}
            style={{ backgroundImage: 'url(' + bg + ')' }}
        >
            <div className={'wrapper'}>
                <S.Title>Contacts</S.Title>

                <S.Emails>
                    <div>
                        <div>For support</div>
                        <a href={'mailto:' + EMAIL_SUPPORT} className={'email'}>
                            {EMAIL_SUPPORT}
                        </a>
                    </div>
                    <div>
                        <div>For collaboration</div>
                        <a href={'mailto:' + EMAIL_INFO} className={'email'}>
                            {EMAIL_INFO}
                        </a>
                    </div>
                </S.Emails>

                <S.Social>
                    <Telegram
                        onClick={() => {
                            openNewTab(LINK_TELEGRAM);
                        }}
                    />
                    <Twitter
                        onClick={() => {
                            openNewTab(LINK_TWITTER);
                        }}
                    />
                    <Medium
                        onClick={() => {
                            openNewTab(LINK_MEDIUM);
                        }}
                    />
                    <Youtube
                        onClick={() => {
                            openNewTab(LINK_YOUTUBE);
                        }}
                    />
                    <Reddit
                        onClick={() => {
                            openNewTab(LINK_REDDIT);
                        }}
                    />
                    <Github
                        onClick={() => {
                            openNewTab(LINK_GITHUB);
                        }}
                    />
                </S.Social>
                <S.Title style={{ marginTop: 52 }}>Brand Assets</S.Title>
                <S.Download>
                    Download brand logo and other samples <span>here</span>.
                </S.Download>
            </div>
        </S.Wrapper>
    );
};

export default Contacts;

import { FC, useCallback, useState, useEffect } from 'react'
import { utils } from 'ethers';

import { TransferInput } from './TransferInput'
import { Route } from '../Route';
import { ChainPositionType } from '../types'
import { CHAINS_DATA } from '../constants'
import { InProgressDialog } from './InProgressDialog'
import { SwapMeta } from './SwapMeta'
import { SubmitButton } from './SubmitButton'

import SwapIcon from 'assets/img/swap.svg';

import { useWallet, useSwapForm, useSymbiosisSwap, useSelectItems, SwapStep } from './hooks'

import { useWeb3Wallet } from 'modules/web3/provider'
import { WALLET_TYPE } from 'modules/web3'

export const SwapForm: FC<{}> = () => {
    const { switchChain, wallet, connector, currentChainId, connection, walletType } = useWeb3Wallet()

    const [
        amount,
        setAmount,
        fromChain,
        toChain,
        swapChainPositions,
    ] = useSwapForm()
    const fromChainId = CHAINS_DATA[fromChain].chainId
    const toChainId = CHAINS_DATA[toChain].chainId

    const [
        chainsBalance,
        shouldSwitchNetwork,
        fetchBalances,
        walletLoading,
    ] = useWallet(wallet, currentChainId, fromChainId)

    const [showInprogressDialog, toggleShowInprogressDialog] = useState<boolean>(false);

    const [swapState, swapHandlers, symbiosisLoading] = useSymbiosisSwap(toggleShowInprogressDialog)
    const {
        swapStep,
        swapMeta,
        swapError,
        expectedAmount,
        transactionHash,
    } = swapState
    const { swap, approve, calculate, reset, setSwapStep } = swapHandlers

    const switchNetwork = useCallback(async () => {
        if (walletType === WALLET_TYPE.WALLET_CONNECT) {
            toggleShowInprogressDialog(true)
            setSwapStep(SwapStep.SwitchChain)
        }

        try {
            await switchChain(fromChainId)
        } finally {
            if (walletType === WALLET_TYPE.WALLET_CONNECT) {
                toggleShowInprogressDialog(false)
                setSwapStep(SwapStep.Unset)
            }
        }
    }, [fromChainId, switchChain, setSwapStep, toggleShowInprogressDialog, walletType])

    const submitDecline = useCallback(() => {
        toggleShowInprogressDialog(false)
        setSwapStep(SwapStep.ReadyToSwap)
    }, [])

    useEffect(() => {
        if (!wallet) {
            reset()
            setAmount('')
        }
    }, [wallet, reset, setAmount])

    const approveHandler = useCallback(() => {
        approve(fromChainId, amount, connector)
    }, [approve, fromChainId, amount, connector])
    const swapChainPositionsHandler = useCallback(() => {
        reset()
        setAmount('')
        swapChainPositions()
    }, [reset, setAmount, swapChainPositions])

    const closeInprogressDialog = useCallback(() => {
        reset()
        setAmount('')
        toggleShowInprogressDialog(false)
    }, [])

    const swapHandler = useCallback(async () => {
        const res = await swap(connector)

        if (res === false) {
            if (wallet) {
                const parsedAmount = amount
                    ? utils.formatEther(utils.parseEther(amount))
                    : ''
                calculate(wallet, fromChainId, toChainId, parsedAmount, chainsBalance[fromChain])
            }
        }
    }, [connector, swap, wallet, fromChainId, fromChain, toChainId, amount, chainsBalance, calculate])

    const submitSuccessSwap = useCallback(async () => {
        reset()
        setAmount('')
        toggleShowInprogressDialog(false)
        await fetchBalances(wallet)
    }, [wallet, fetchBalances, reset, setAmount])

    const selectItems = useSelectItems(chainsBalance);

    const confirmSwap = useCallback(async () => {
        setSwapStep(SwapStep.ReadyToSwap)
        toggleShowInprogressDialog(true)
    }, [])

    useEffect(() => {
        if (wallet) {
            const parsedAmount = amount
                ? utils.formatEther(utils.parseEther(amount))
                : ''
            calculate(wallet, fromChainId, toChainId, parsedAmount, chainsBalance[fromChain])
        }
    }, [wallet, fromChainId, fromChain, toChainId, amount, chainsBalance])

    return (
        <>
            <InProgressDialog
                open={showInprogressDialog}
                fromChain={fromChain}
                toChain={toChain}
                amount={amount}
                expectedAmount={expectedAmount}
                swapStep={swapStep}
                swapError={swapError}
                swapMeta={(
                    <div className="swap-form-meta swap-form-meta--dialog">
                        <SwapMeta
                            amount={amount}
                            expectedAmount={expectedAmount}
                            swapMeta={swapMeta}
                            fromChain={fromChain}
                        />
                    </div>
                )}
                swap={swapHandler}
                loading={walletLoading || symbiosisLoading}
                closeInprogressDialog={closeInprogressDialog}
                submitSuccessSwap={submitSuccessSwap}
                submitDecline={submitDecline}
                transactionHash={transactionHash}
                toggleShowInprogressDialog={toggleShowInprogressDialog}
            />
            <form>
                <TransferInput
                    chainPositionType={ChainPositionType.From}
                    blockchain={fromChain}
                    wallet={wallet}
                    amount={amount}
                    onAmountChange={setAmount}
                    onChainChange={swapChainPositionsHandler}
                    chainName={CHAINS_DATA[fromChain].label}
                    chainIcon={CHAINS_DATA[fromChain].icon}
                    chainBalance={chainsBalance[fromChain]}
                    walletLoading={walletLoading}
                    selectItems={selectItems}
                />
                <div className="swap-form-img">
                    <img
                        src={SwapIcon}
                        onClick={swapChainPositionsHandler}
                        alt="swap"
                    />
                </div>
                <TransferInput
                    chainPositionType={ChainPositionType.To}
                    blockchain={toChain}
                    wallet={wallet}
                    amount={expectedAmount}
                    onChainChange={swapChainPositionsHandler}
                    chainName={CHAINS_DATA[toChain].label}
                    chainIcon={CHAINS_DATA[toChain].icon}
                    chainBalance={chainsBalance[toChain]}
                    walletLoading={walletLoading}
                    selectItems={selectItems}
                />
                <hr />
                <div className="swap-form-route">
                    <h3>Route:</h3>
                    <Route token={fromChain} tokens={swapMeta?.route} />
                </div>
                <div className="swap-form-meta">
                    <SwapMeta
                        amount={amount}
                        expectedAmount={expectedAmount}
                        swapMeta={swapMeta}
                        fromChain={fromChain}
                    />
                </div>
            </form>
            <SubmitButton
                swap={confirmSwap}
                error={swapError}
                loading={walletLoading || symbiosisLoading}
                approve={approveHandler}
                wallet={wallet}
                swapStep={swapStep}
                shouldSwitchNetwork={shouldSwitchNetwork}
                switchNetwork={switchNetwork}
                fromChain={fromChain}
                connectWallet={connection.openModal}
            />
        </>
    )
}

import { NetworkPurpose } from "types";

export const stakeConfig = {
    currentNetwork: NetworkPurpose.Testnet,
    [NetworkPurpose.Testnet]: {
        name: 'Rinkeby Testnet',
        provider: {
            chainId: 4,
            url: 'https://rinkeby.infura.io/v3/e8031527a4504619b8581b444ccca4e3',
            blockexplorer: 'https://rinkeby.etherscan.io/tx/',
        },
        address_Staking: '0xF8a23247cCA30e717Ef159D0B8e5dfA76236A05C',
        address_TKN: '0x10ad0a8a482bd9027ed0ce119d8ff6199dd14039',
        address_Airdrop: '',
    },
    [NetworkPurpose.Mainnet]: {
        name: 'Ethereum Mainnet',
        provider: {
            chainId: 1,
            url: 'https://ethereum-mainnet.core.chainstack.com/7e845004a178c4f3041e98439e2b0686',
            blockexplorer: 'https://etherscan.io/tx/',
        },
        address_Staking: '0x2De3B4B4487fcb1C4499640545fF8f2C72888275',
        address_TKN: '0x9D0B65a76274645B29e4cc41B8f23081fA09f4A3',
        address_Airdrop: '0xd508933559d014988ecc3ce8437ed965dd0235a6',
    },
    TESTNET2: {
        name: 'Ropsten Testnet',
        provider: {
            chainId: 3,
            url: 'https://rpc.ankr.com/eth_ropsten/rpc',
            blockexplorer: 'https://ropsten.etherscan.io/tx/',
        },
        address_Staking: '0xe0dec2f9e89126237bcfee0937a9082a133a88a9',
        address_TKN: '0x98b3c321f619d8d87c54a46b7ce9495e43d6f7ef',
        address_Airdrop: '',
    },
};

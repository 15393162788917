import { createStitches } from '@stitches/react';

export const selectStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        padding: '15px',
        color: state.isSelected ? '#006AED' : '#0C2C54',
        backgroundColor: state.isSelected || state.isHov ? '#e0edfd' : 'white',
        '&:hover': {
            backgroundColor: '#e0edfd',
        },
        '&:disabled': {
            opacity: 0.5,
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),

    menuList: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    control: (provided: any, { isDisabled }: any) => ({
        ...provided,
        padding: '10px',
        border: '#93AAC8 solid 1px',
        boxShadow: 'none',
        backgroundColor: 'white',
        opacity: isDisabled ? '0.5' : '1',
    }),
};

export const selectLanguageStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        padding: '12px',
        borderBottom: '1px solid rgba(147, 170, 200, 0.5)',
        color: state.isSelected ? '#006AED' : '#0C2C54',
        backgroundColor: state.isSelected || state.isHov ? '#e0edfd' : 'white',
        '&:hover': {
            backgroundColor: '#e0edfd',
        },
        '&:disabled': {
            opacity: 0.5,
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),

    menuList: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    control: (provided: any, { isDisabled }: any) => ({
        ...provided,
        padding: '10px',
        border: '1px solid rgba(147, 170, 200, 0.5)',
        boxShadow: 'none',
        backgroundColor: 'white',
        opacity: isDisabled ? '0.5' : '1',
        borderRadius: '12px',
    }),
};

export const { styled, css } = createStitches({
    theme: {
        colors: {
            bgAlternative: '#f6fbffff',
            plugGrey: '#e5e5e5ff',
            darkBlue: '#0c2c54ff',
            headTextDark: '#3a4f68',
            basicBlue: '#006aed',
            lightBlue: '#2988ff',
            rockinBlue: '#C9D5E3',
            rockBlue: '#93aac8',
            rockBlueLite: '#c4d2e3',
            error: '#fd6868',
            errorHover: '#e54747',
            success: '#00ba77',
            successHover: '#06a46b',
            hoverPrimary: '#005ac9',
            hoverSecondary: 'rgba(0, 106, 237, 0.1)',
            grey: '#C4C4C4',
            basicGrey: '#9A9A9A',
            basicGrey4: '#676767',
            telegramDarkGrey: '#434F5D',
            gwei: '#FF8102',
        },
    },
});

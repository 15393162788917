import { Dispatch } from 'react';
import { BigNumber, Contract, providers } from 'ethers';
import { StakeType } from 'types';
import { AppStateType } from 'store';
import Token from 'abis/Token.json';
import StakingAbi from 'abis/Staking.json';
import { stakeConfig } from 'stakeConfig';
import { currentNetwork } from 'utils';

const SET_WALLET = 'Staking/SET_WALLET';
const APPROVE_START = 'Staking/APPROVE_START';
const APPROVE_FINISH = 'Staking/APPROVE_FINISH';
const STAKE_START = 'Staking/STAKE_START';
const STAKE_FINISH = 'Staking/STAKE_FINISH';
const UNSTAKE_START = 'Staking/UNSTAKE_START';
const UNSTAKE_FINISH = 'Staking/UNSTAKE_FINISH';
const RETURN_START = 'Staking/RETURN_START';
const RETURN_FINISH = 'Staking/RETURN_FINISH';
const SET_IS_APPROVED = 'Staking/SET_IS_APPROVED';
const CONNECT_METAMASK_START = 'Staking/CONNECT_METAMASK_START';
const CONNECT_METAMASK_FINISH = 'Staking/CONNECT_METAMASK_FINISH';
const LOAD_CONTRACT_INFO_START = 'Staking/LOAD_CONTRACT_INFO_START';
const LOAD_CONTRACT_INFO_FINISH = 'Staking/LOAD_CONTRACT_INFO_FINISH';
const SYNCHRONIZED_TIME = 'Staking/SYNCHRONIZED_TIME';
const CHAIN_ID_UPDATE = 'Staking/CHAIN_ID_UPDATE';
const RESET_DATA = 'Staking/RESET_DATA';
const SET_WALLETCONNECT_QR = 'Staking/SET_WALLETCONNECT_QR';
const SET_TRANSACTION_URL = 'Staking/SET_TRANSACTION_URLS';
const SET_TRANSACTION_CONFIRMED = 'Staking/SET_TRANSACTION_CONFIRMED';
const TIMER_STEP = 'Staking/TIMER_STEP';
const SET_ERROR = 'Staking/SET_ERROR';

const address_TKN = stakeConfig[currentNetwork].address_TKN;
const address_S = stakeConfig[currentNetwork].address_Staking;

const _providerE = new providers.JsonRpcProvider(
    stakeConfig[currentNetwork].provider.url
);
const TKN = new Contract(address_TKN, Token.abi, _providerE);
const S = new Contract(address_S, StakingAbi.abi, _providerE);

interface IStakingState {
    selectedWallet: string | null;
    provider: providers.Web3Provider | any | null;
    signer: providers.JsonRpcSigner | null;
    wallet: '';
    approved: '';
    balance: '';
    loading_approve: boolean;
    loading_stake: boolean;
    loading_unstake: boolean;
    loading_provider: boolean;
    loading_return: boolean;
    loading_contractInfo: boolean;
    isLoading: boolean;
    transactionUrl: string | null;
    connect_dialog: boolean;
    return_dialog: boolean;
    chainId: null | number;
    stakeL: null | StakeType;
    time_now: number;
    rates: null | number[][];
    amounts: number[];
    periods: null | number[];
    walletConnectURI: string | null;
    approvedBN: BigNumber | null;
    isApproved: boolean;
    isTransactionConfirmed: boolean;
    error: string | null;
}

interface ISystemAction {
    type: string;
    payload: any;
}

const initialState: IStakingState = {
    selectedWallet: null,
    provider: null,
    signer: null,
    wallet: '',
    approved: '',
    balance: '',
    loading_approve: false,
    loading_stake: false,
    loading_unstake: false,
    loading_provider: false,
    loading_return: false,
    isLoading: false,
    loading_contractInfo: false,
    transactionUrl: null,
    connect_dialog: false,
    return_dialog: false,
    chainId: null,
    stakeL: null,
    time_now: 0,
    rates: null,
    amounts: [],
    periods: null,
    walletConnectURI: null,
    approvedBN: null,
    isApproved: false,
    isTransactionConfirmed: false,
    error: null,
};

export const initial = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        if (getState().services.wallet) {
            await dispatch(loadContractInfo());
            await dispatch(updateChainId(getState().services.providerE));
        }
    };
};

export const resetWallet = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: RESET_DATA,
        });
    };
};

export const setWallet = (wallet: string | null) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_WALLET,
            payload: wallet,
        });
    };
};

export const timerStep = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: TIMER_STEP,
        });
    };
};

export const synchronizeTimeWithBlock = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const bn = await _providerE.getBlockNumber();
        let time = (await _providerE.getBlock(bn)).timestamp;
        if (time > getState().staking.time_now)
            dispatch({
                type: SYNCHRONIZED_TIME,
                payload: time,
            });
    };
};

export const setError = (error: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_ERROR,
            payload: error,
        });
    };
};

const updateChainId = (provider: any) => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const id = (await provider?.getNetwork())?.chainId || null;
        if (id !== stakeConfig[currentNetwork].provider.chainId)
            dispatch({
                type: SET_ERROR,
                payload: `You selected wrong network for this direction. Select ${stakeConfig[currentNetwork].name} in your provider and refresh the page`,
            });
        dispatch({
            type: CHAIN_ID_UPDATE,
            payload: id,
        });
    };
};

export const loadContractInfo = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        dispatch({
            type: LOAD_CONTRACT_INFO_START,
        });
        let approved, balance, rates, amounts, periods, stakeL;
        try {
            const w = getState().services.wallet;
            const [Tb, Ta, Ss, Info]: [
                BigNumber,
                BigNumber,
                {
                    start: BigNumber;
                    period: BigNumber;
                    LIMEAmount: BigNumber;
                    USDTAmount: BigNumber;
                },
                {
                    _amounts: BigNumber[];
                    _periods: BigNumber[];
                    _rates: BigNumber[][];
                },
                any
            ] = await Promise.all([
                TKN.balanceOf(w),
                TKN.allowance(w, address_S),
                S.stakes(w),
                S.getInfos(),
                dispatch(synchronizeTimeWithBlock()),
            ]);
            approved = Ta.toString();
            if (
                BigNumber.from(approved || 0).gte(
                    '0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
                )
            )
                dispatch({ type: SET_IS_APPROVED });
            balance = Tb.toString();
            rates = Info._rates.map((r) => r.map((x) => x.toNumber() / 10));
            amounts = Info._amounts.map((r) => r.toNumber());
            periods = Info._periods.map((r) => r.toNumber());
            if (!Ss.start.eq(0)) stakeL = Ss;
            else stakeL = null;
        } catch (error) {
            console.error(error);
        }
        dispatch({
            type: LOAD_CONTRACT_INFO_FINISH,
            payload: {
                approved: approved,
                balance: balance,
                rates: rates,
                amounts: amounts,
                periods: periods,
                stakeL: stakeL,
            },
        });
    };
};

export const handleClickApprove = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: APPROVE_START,
        });
        try {
            await dispatch(approve());
        } catch (error) {
            console.error(error);
        }
        dispatch({
            type: APPROVE_FINISH,
        });
    };
};

const approve = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const TKN = new Contract(
            address_TKN,
            Token.abi,
            getState().services.signer!
        );
        const tx = await TKN.approve(
            address_S,
            BigNumber.from(
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            )
        );
        const snackbar_approve_tx_url = `${stakeConfig[currentNetwork].provider.blockexplorer}${tx.hash}`;
        dispatch({
            type: SET_TRANSACTION_URL,
            payload: snackbar_approve_tx_url,
        });
        const receipt = await tx.wait(4);
        dispatch({
            type: SET_TRANSACTION_CONFIRMED,
        });
        await dispatch(loadContractInfo());
    };
};

export const handleClickStake = (daysIndex: number, amountIndex: number) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: STAKE_START,
        });
        try {
            // this.warningMessage = ''
            await dispatch(stake(daysIndex, amountIndex));
            // this.inputAmount = ''
        } catch (error) {
            console.error(error);
        }

        dispatch({
            type: STAKE_FINISH,
        });
    };
};

export const handleClickUnstake = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: UNSTAKE_START,
        });
        try {
            await dispatch(unstake());
        } catch (error) {
            console.error(error);
        }
        dispatch({
            type: UNSTAKE_FINISH,
        });
    };
};

const stake = (daysIndex: number, amountIndex: number) => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        try {
            const S = new Contract(
                address_S,
                StakingAbi.abi,
                getState().services.signer!
            );

            const tx: providers.TransactionResponse = await S.stake(
                daysIndex,
                amountIndex
            );
            const snackbar_approve_tx_url = `${stakeConfig[currentNetwork].provider.blockexplorer}${tx.hash}`;
            dispatch({
                type: SET_TRANSACTION_URL,
                payload: snackbar_approve_tx_url,
            });
            const receipt = await tx.wait(4);
            dispatch({
                type: SET_TRANSACTION_CONFIRMED,
            });
        } catch (error) {
            if (error.message.length > 150) {
                throw new Error(
                    `Error occured. USDT limit is exceeded. Please, choose a shorter period or a smaller amount of LIME.`
                );
            } else throw new Error(`${error.message}`);
        }

        await dispatch(loadContractInfo());
    };
};

const unstake = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const SB = new Contract(
            address_S,
            StakingAbi.abi,
            getState().services.signer!
        );
        const tx = await SB.unstake();
        const snackbar_approve_tx_url = `${stakeConfig[currentNetwork].provider.blockexplorer}${tx.hash}`;
        dispatch({
            type: SET_TRANSACTION_URL,
            payload: snackbar_approve_tx_url,
        });
        const receipt = await tx.wait(4);
        dispatch({
            type: SET_TRANSACTION_CONFIRMED,
        });
        await dispatch(loadContractInfo());
    };
};

export const handleClickReturn = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: RETURN_START,
        });
        try {
            await dispatch(returnLime());
        } catch (error) {
            console.error(error);
        }
        dispatch({
            type: RETURN_FINISH,
        });
    };
};

const returnLime = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const SB = new Contract(
            address_S,
            StakingAbi.abi,
            getState().services.signer!
        );
        let snackbar_approve_tx_url = '';
        const tx = await SB.returnLime();
        snackbar_approve_tx_url = `${stakeConfig[currentNetwork].provider.blockexplorer}${tx.hash}`;
        dispatch({
            type: SET_TRANSACTION_URL,
            payload: snackbar_approve_tx_url,
        });
        const receipt = await tx.wait(4);
        dispatch({
            type: SET_TRANSACTION_CONFIRMED,
        });
        await dispatch(loadContractInfo());
    };
};

const StakingReducer = (
    state = initialState,
    action: ISystemAction
): IStakingState => {
    switch (action.type) {
        case SET_WALLET:
            return {
                ...state,
                selectedWallet: action.payload,
            };

        case CONNECT_METAMASK_START:
            return {
                ...state,
                error: null,
                loading_provider: true,
            };

        case CONNECT_METAMASK_FINISH:
            return {
                ...state,
                loading_provider: false,
                provider: action.payload.provider,
                signer: action.payload.signer,
                wallet: action.payload.wallet,
                connect_dialog: false,
                selectedWallet: action.payload.selectedWallet,
            };

        case LOAD_CONTRACT_INFO_START:
            return {
                ...state,
                isLoading: true,
                loading_contractInfo: true,
            };

        case LOAD_CONTRACT_INFO_FINISH:
            return {
                ...state,
                isLoading: false,
                approved: action.payload.approved,
                balance: action.payload.balance,
                rates: action.payload.rates,
                amounts: action.payload.amounts,
                periods: action.payload.periods,
                stakeL: action.payload.stakeL,
                loading_contractInfo: false,
            };

        case CHAIN_ID_UPDATE:
            return {
                ...state,
                chainId: action.payload,
            };

        case APPROVE_START:
            return {
                ...state,
                isLoading: true,
                transactionUrl: null,
                isTransactionConfirmed: false,
                loading_approve: true,
            };

        case APPROVE_FINISH:
            return {
                ...state,
                isLoading: false,
                loading_approve: false,
            };
        case RETURN_START:
            return {
                ...state,
                isLoading: true,
                transactionUrl: null,
                isTransactionConfirmed: false,
                loading_return: true,
            };

        case RETURN_FINISH:
            return {
                ...state,
                isLoading: false,
                loading_return: false,
            };
        case STAKE_START:
            return {
                ...state,
                isLoading: true,
                transactionUrl: null,
                isTransactionConfirmed: false,
                loading_stake: true,
            };

        case STAKE_FINISH:
            return {
                ...state,
                isLoading: false,
                loading_stake: false,
            };
        case UNSTAKE_START:
            return {
                ...state,
                isLoading: true,
                transactionUrl: null,
                isTransactionConfirmed: false,
                loading_unstake: true,
            };

        case UNSTAKE_FINISH:
            return {
                ...state,
                isLoading: false,
                loading_unstake: false,
            };

        case SET_IS_APPROVED:
            return {
                ...state,
                isApproved: true,
            };

        case SYNCHRONIZED_TIME:
            return {
                ...state,
                time_now: action.payload,
            };

        case SET_WALLETCONNECT_QR:
            return {
                ...state,
                walletConnectURI: action.payload,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case SET_TRANSACTION_URL:
            return {
                ...state,
                transactionUrl: action.payload,
            };
        case SET_TRANSACTION_CONFIRMED:
            return {
                ...state,
                isTransactionConfirmed: true,
            };

        case TIMER_STEP:
            return {
                ...state,
                time_now: state.time_now + 1,
            };

        case RESET_DATA:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default StakingReducer;

import { useCallback, useState } from 'react';

import './style.scss';

import DesktopImage from 'assets/img/desktop.png';
import { getDevicePlatform } from 'utils';
import { PROJECT_SHORT_NAME } from 'config';
import { PLATFORMS_ENUM } from 'types';

import { AllPlatformsDownloadLink } from './components/AllPlatformsDownloadLink'
import { OnePlatformDownloadLink } from './components/OnePlatformDownloadLink'

export const DownloadDesktop = () => {
    const currentDevicePlatform = getDevicePlatform();
    const [allCollapsed, dispatchShowAll] = useState<boolean>(currentDevicePlatform === PLATFORMS_ENUM.UNSET);
    const showAll = useCallback(() => {
        dispatchShowAll(true)
    }, [dispatchShowAll])

    return (
        <div className={`download-wrapper`}>
            <img src={DesktopImage} alt="Desktop" />
            <h3>{PROJECT_SHORT_NAME} Desktop</h3>
            <p>
                You can download {PROJECT_SHORT_NAME} for <span>Desktop</span>{' '}
                here. Desktop version syncs greatly with your mobile phone.
            </p>
            {!allCollapsed && <OnePlatformDownloadLink showAll={showAll} devicePlatform={currentDevicePlatform} />}
            {allCollapsed && <AllPlatformsDownloadLink />}
        </div>
    );
};

import { FC } from 'react'
import HeaderService from 'components/Header/HeaderServiceNew'
import Swap from 'components/Service/Swap'
import { Web3WalletProvider } from 'modules/web3/provider'

const SwapPage: FC = () => {
    return (
        <Web3WalletProvider>
            <div className={'flex-container-wrapper'}>
                <HeaderService />
                <Swap />
                <div className={'swap-page-footer'}>
                    Powered by{' '}
                    <a href="https://symbiosis.finance/" target="_blank" rel="noreferrer">
                        Symbiosis
                    </a>
                </div>
            </div>
        </Web3WalletProvider>
    )
}

export default SwapPage

import { Symbiosis, Token, TokenAmount } from 'symbiosis-js-sdk';
import { utils } from 'ethers';
import { currentNetwork }  from 'utils'
import { NetworkPurpose }  from 'types'

import { chains, SWAP_SLIPPAGE, SWAP_DURATION_TIMEOUT } from 'config'

export const symbiosis = new Symbiosis(currentNetwork === NetworkPurpose.Mainnet ? 'mainnet' : 'testnet', 'awesome-ime-app');

export const limeBsc = new Token({
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
    decimals: 18,
    chainId: chains.BSC.chainId,
    userToken: true,
});

export const limePolygon = new Token({
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0x7f67639Ffc8C93dD558d452b8920b28815638c44',
    decimals: 18,
    chainId: chains.POLYGON.chainId,
    userToken: true,
});

export const limeBscTestnet = new Token({
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0xbfAaF004504bcc139B354cf8F12f5b830CdD0DE6',
    decimals: 18,
    chainId: chains.BSC_TESTNET.chainId,
});

export const limePolygonTestnet = new Token({
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0xf7909CBC21E95000E3d0e14004aADdea2bb1796d',
    decimals: 18,
    chainId: chains.POLYGON_TESTNET.chainId,
});

export const LIME_TOKENS_BY_CHAIN_ID = {
    [chains.BSC.chainId]: limeBsc,
    [chains.BSC_TESTNET.chainId]: limeBscTestnet,
    [chains.POLYGON.chainId]: limePolygon,
    [chains.POLYGON_TESTNET.chainId]: limePolygonTestnet,
}

export const calculateSwap = async (wallet: string, fromChainId: number, toChainId: number, amount: string) => {
    const fromToken = LIME_TOKENS_BY_CHAIN_ID[fromChainId]
    const toToken = LIME_TOKENS_BY_CHAIN_ID[toChainId]

    const swapping = symbiosis.newSwapping();

    const tokenAmountIn = new TokenAmount(
        fromToken,
        utils.parseUnits(amount, fromToken.decimals).toString()
    )

    const swapMeta = await swapping.exactIn(
        tokenAmountIn,
        toToken,
        wallet,
        wallet,
        wallet,
        SWAP_SLIPPAGE,
        Date.now() + SWAP_DURATION_TIMEOUT
    );

    return swapMeta
}

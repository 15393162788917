import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import StakingReducer from '../modules/Staking'
import ServicesReducer from '../modules/Services'
import LandingReducer from '../modules/Landing'
import CatalogReducer from '../modules/Catalog'
import SystemReducer from '../modules/System'
import ClaimReducer from '../modules/Claim'

const rootReducer = combineReducers({
    staking: StakingReducer,
    services: ServicesReducer,
    landing: LandingReducer,
    catalog: CatalogReducer,
    claim: ClaimReducer,
    system: SystemReducer,
})

type CombineReducersType = typeof rootReducer
export type AppStateType = ReturnType<CombineReducersType>

const logger = createLogger({
    predicate: (getState, action) => !action.type.includes('TIMER_STEP'),
})
const store =
    process.env.NODE_ENV === 'production'
        ? createStore(rootReducer, compose(applyMiddleware(ReduxThunk)))
        : createStore(
              rootReducer,
              composeWithDevTools(applyMiddleware(ReduxThunk))
          )

export default store

import { Fragment } from 'react';

import storePic from 'assets/img/buttons/AiStore.svg';
import catalog from 'assets/img/buttons/catalog.svg';
import botPic from 'assets/img/landing/Bot.svg';
import walletPic from 'assets/img/landing/Wallet.svg';
import customPic from 'assets/img/landing/Custom.svg';
import catalogBg from 'assets/img/landing/CatalogBg.svg';
import botPicBg from 'assets/img/landing/NeurobotBg.svg';
import walletPicBg from 'assets/img/landing/WalletBg.svg';
import customPicBg from 'assets/img/landing/CustomToolsBg.svg';
import channelsPic from 'assets/img/landing/Channels.svg';
import { ReactComponent as AppStore } from 'assets/img/buttons/apple-blue.svg';
import { ReactComponent as GooglePlay } from 'assets/img/buttons/google-blue.svg';

import { InfoBlock } from './types';
import { openNewTab } from 'utils';
import { LINK_APP_STORE, LINK_GOOGLE_PLAY, PROJECT_SHORT_NAME } from 'config';
import { Button } from 'components/form/Button';
import { ROUTE_CATALOG } from 'routes/names';

export const infoBlocks: InfoBlock[] = [
    {
        title: (
            <Fragment>
                <div>
                    Crypto Wallet <span className={'hide'}>& DeFi</span>
                </div>
            </Fragment>
        ),
        text: (
            <Fragment>
                <div>
                    Non-custodial wallet and DeFi tools, access to which only
                    the users have.
                </div>
                <div>
                    Storing, buying, swapping and transferring cryptocurrencies
                    is available for Ethereum and Binance Smart Chain accounts.
                </div>
                <div>
                    Make transactions directly in chats, by Telegram ID, public
                    addresses or QR code.
                </div>
                <div>
                    Binance Pay integration with linked Pay ID allows you to
                    request payments and make transfers instantly and without
                    fees.
                </div>

                <div>
                    The developed staking and farming programs allow you not to
                    waste your assets uselessly.
                </div>
            </Fragment>
        ),

        buttons: (history: any) => {
            return (
                <Fragment>
                    <GooglePlay
                        onClick={() => {
                            openNewTab(LINK_GOOGLE_PLAY);
                        }}
                    />
                    <AppStore
                        onClick={() => {
                            openNewTab(LINK_APP_STORE);
                        }}
                    />
                </Fragment>
            );
        },
        picture: walletPic,
        anim_name: 'phone_01',
        anim_id: 'EC22FFBBC68A4D43914DC975C46E05CA',
        bg: walletPicBg,
    },
    {
        title: (
            <Fragment>
                <div>
                    NFT-Neurobots <div className={'soon-tag'}>soon</div>
                </div>
            </Fragment>
        ),
        text: (
            <Fragment>
                <div>
                    A neurobot is a user dataset, trained by an algorithm on a
                    Neural Network with certification, which undeniably and
                    irrefutably belongs to the creator on the ownership rights
                    of the unique NFT token.
                </div>

                <div>
                    Create your smart and useful neurobots for personal
                    collecting and use or sell it on the {PROJECT_SHORT_NAME} AI
                    marketplace to benefit the community.
                </div>
            </Fragment>
        ),
        buttons: (history: any) => {
            return (
                <Fragment>
                    <Button isDisabled isBlueBorder>
                        <img alt="store" src={storePic} />
                        <div style={{ marginLeft: '12px' }}>Neurobot store</div>
                    </Button>
                </Fragment>
            );
        },
        picture: botPic,
        anim_name: 'phone_02',
        anim_id: 'EC22FFBBC68A4D43914DC975C46E05C1',
        bg: botPicBg,
    },
    {
        title: 'Telegram Customization',
        text: (
            <Fragment>
                <div>
                    {PROJECT_SHORT_NAME} implements advanced features that lacks
                    in Telegram. This is the translator in chats, voice-to-text
                    translation, text from photos extraction, cloud albums,
                    topics and advanced folder settings, admin tools, connecting
                    of up to 5 accounts and much more. {PROJECT_SHORT_NAME} and
                    Telegram are interchangeable, the structure of the messenger
                    has the common server part and is fully synchronized. User
                    authorization, chats data transfer and storage occurs
                    through the servers of the official client.
                </div>

                <div>
                    Experience a new level of using the messenger without losing
                    the familiar environment and security of Telegram.
                </div>
            </Fragment>
        ),
        buttons: (history: any) => {
            return (
                <Fragment>
                    <GooglePlay
                        onClick={() => {
                            openNewTab(LINK_GOOGLE_PLAY);
                        }}
                    />
                    <AppStore
                        onClick={() => {
                            openNewTab(LINK_APP_STORE);
                        }}
                    />
                </Fragment>
            );
        },
        picture: customPic,
        anim_name: 'phone_03',
        anim_id: 'EC22FFBBC68A4D43914DC975C46E05C2',
        bg: customPicBg,
    },
    {
        title: (
            <Fragment>
                <div>Telegram Catalog</div>
            </Fragment>
        ),
        text: (
            <Fragment>
                <div>
                    Collections of Telegram channels, groups and chat-bots with
                    a convenient search by categories, countries and languages.
                </div>

                <div>
                    Administrators and owners can place their resources in the
                    Catalog independently, promoting it among the audience which
                    is interested in a convenient search for content by desired
                    parameters.
                </div>
            </Fragment>
        ),
        buttons: (history: any) => {
            return (
                <Fragment>
                    <Button
                        isBlueBorder
                        onClick={() => {
                            history.push(ROUTE_CATALOG);
                        }}
                    >
                        <img alt="catalog" src={catalog} />
                        <div style={{ marginLeft: '12px' }}>Open catalog</div>
                    </Button>
                </Fragment>
            );
        },
        picture: channelsPic,
        anim_name: 'phone_04',
        anim_id: 'EC22FFBBC68A4D43914DC975C46E05C3',
        bg: catalogBg,
    },
];

import { useState, useCallback } from 'react'

export enum LOCAL_STORAGE_ENUM {
    UNSET = 'ls-unset',
    WALLET_TYPE = 'ls-wallet-type',
}

type LocalStorageHook<T> = [T, (value: T) => void]

export const useLocalStorage = <T>(keyName: LOCAL_STORAGE_ENUM, defaultValue: T): LocalStorageHook<T> => {
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        const value = window.localStorage.getItem(keyName);

        if (value) {
          return JSON.parse(value)
        }

        return defaultValue
      } catch (err) {
        console.error(err, 'read from local storage failed')

        return defaultValue;
      }
    });

    const setValue = useCallback((newValue: T) => {
      try {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
        setStoredValue(newValue);
      } catch (err) {
        console.error(err, 'update local storage failed')
      }
    }, [setStoredValue, keyName]);

    return [storedValue, setValue];
};
import { FC, useEffect, useState } from 'react';
import './style.scss';
import { ReactComponent as DropdownPic } from 'assets/img/up-small.svg';
import { ReactComponent as Cancel } from 'assets/img/cancel.svg';
import { useHistory } from 'react-router-dom';
import { SCROLL_TO_CONTACTS, SCROLL_TO_FEATURES, LINK_APP_STORE } from 'config';
import {
    ROUTE_AIRDROP,
    ROUTE_BRIDGE,
    ROUTE_CATALOG,
    ROUTE_EXCHANGE,
    ROUTE_MAIN,
    ROUTE_STAKING,
    ROUTE_DOWNLOAD_ANDROID,
    ROUTE_DOWNLOAD_DESKTOP,
} from 'routes/names';
import { setScrollTo } from 'modules/Landing';
import { useDispatch } from 'react-redux';
import { openNewTab } from 'utils';

interface IHeaderMenu {
    hide: () => any;
}

// Mobile nav
const HeaderMenu: FC<IHeaderMenu> = ({ hide }) => {
    const history = useHistory();

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    const dispatch = useDispatch();
    const [isListOpen, setIsListOpen] = useState<string>('');
    const toggle = (anchor: string) => {
        setIsListOpen((prevState) => {
            if (prevState === anchor) {
                return ''
            }

            return anchor
        })
    };

    return (
        <div className={'header-menu'}>
            <div className={'cancel'}>
                <Cancel onClick={() => hide()} />
            </div>
            <div className={'list'}>
                <div
                    className={'list-item'}
                    onClick={() => {
                        hide();
                        history.push(ROUTE_MAIN);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    Home
                </div>
                <div
                    className={'list-item' + (isListOpen === 'apps' ? ' open' : '')}
                    onClick={() => toggle('apps')}
                >
                    Apps <DropdownPic />
                </div>
                <div className={'sub-list'}>
                    <div
                        className={'sub-list-item'}
                        onClick={() => openNewTab(LINK_APP_STORE)}
                    >
                        iOS
                    </div>
                    <div
                        className={'sub-list-item'}
                        onClick={() => {
                            hide();
                            history.push(ROUTE_DOWNLOAD_ANDROID);
                        }}
                    >
                        Android
                    </div>
                    <div
                        className={'sub-list-item'}
                        onClick={() => {
                            hide();
                            history.push(ROUTE_DOWNLOAD_DESKTOP);
                        }}
                    >
                        Desktop
                    </div>
                </div>
                <div
                    className={'list-item'}
                    onClick={() => {
                        hide();
                        history.push(ROUTE_MAIN);
                        dispatch(setScrollTo(SCROLL_TO_FEATURES));
                    }}
                >
                    Features
                </div>
                <div
                    className={'list-item' + (isListOpen === 'services' ? ' open' : '')}
                    onClick={() => toggle('services')}
                >
                    Services <DropdownPic />
                </div>
                <div className={'sub-list'}>
                    <div
                        className={'sub-list-item'}
                        onClick={() => history.push(ROUTE_EXCHANGE)}
                    >
                        Exchange
                    </div>
                    {/*<div
                        className={'sub-list-item'}
                        onClick={() => history.push(ROUTE_BRIDGE)}
                    >
                        Bridge
                    </div>*/}
                    {/* <div
                        className={'sub-list-item'}
                        onClick={() => history.push(ROUTE_STAKING)}
                    >
                        Staking
                    </div> */}
                    <div
                        className={'sub-list-item'}
                        onClick={() => history.push(ROUTE_AIRDROP)}
                    >
                        Multi-transfer
                    </div>
                    {/*<div className={'sub-list-item'}>
                        Neurobots
                        <div className={'soon-tag'}>soon</div>
                    </div>
                    <div
                        className={'sub-list-item'}
                        onClick={() => {
                            history.push(ROUTE_CATALOG);
                            hide();
                        }}
                    >
                        Catalog
                    </div>*/}
                </div>
                <div
                    className={'list-item'}
                    onClick={() => {
                        hide();
                        history.push(ROUTE_MAIN);
                        dispatch(setScrollTo(SCROLL_TO_CONTACTS));
                    }}
                >
                    Support
                </div>
            </div>
        </div>
    );
};

export default HeaderMenu;

import { FC, Fragment } from 'react';
import Header from '../components/Header';
import { DownloadAndroid } from '../components/Download';

const DownloadAndroidPage: FC = () => {
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <DownloadAndroid />
            </div>
        </Fragment>
    );
};

export default DownloadAndroidPage;

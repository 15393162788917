import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStateType } from 'store';

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const scrollTo = useSelector(
        (state: AppStateType) => state.landing.scrollTo
    );
    const isScrollRestore = useSelector(
        (state: AppStateType) => state.system.isScrollRestore
    );

    useEffect(() => {
        if (!scrollTo && !isScrollRestore) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
}

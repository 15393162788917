export const claimConfig = {
    currentNetwork: 'TESTNET',
    TESTNET: {
        provider: {
            name: 'BSC Testnet',
            chainId: 97,
            chainIdStr: '0x61',
            url: 'https://testnet.bsc.imem.app/gateway/',
            blockexplorer: 'https://testnet.bscscan.com/tx/',
        },
        address_TKN: '0xbfAaF004504bcc139B354cf8F12f5b830CdD0DE6',
        address_Claim: '0xb1d782DA8b2F8175B5E6aDA57eDa1854f6cD4F2f',
    },
    MAINNET: {
        provider: {
            name: 'BNB Chain',
            chainId: 56,
            chainIdStr: '0x38',
            url: 'https://bsc-mainnet.core.chainstack.com/a5a47b68c4174a4680e3627dc84a14d0',
            blockexplorer: 'https://bscscan.com/tx/',
        },

        address_TKN: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
        address_Claim: '0x791f04ffb4bf4a31715dd25a292edc4ac9b58d5a',
    },
}

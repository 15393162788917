import { FC, Fragment, useState } from 'react';
import Landing from 'components/Landing';
import Header from 'components/Header';

const MainPage: FC = () => {
    const [isLogoVisible, setIsLogoVisible] = useState<boolean>(true);

    return (
        <Fragment>
            <Header isLogoVisible={isLogoVisible} />
            <Landing setIsLogoVisible={setIsLogoVisible} />
        </Fragment>
    );
};

export default MainPage;

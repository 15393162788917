import { FC } from 'react'
import { ReactComponent as SuccessCircle } from 'assets/img/success-circle.svg'
import { ReactComponent as DeclinedCircle } from 'assets/img/declined-circle.svg'
import { Loader } from './Loader'

import { SwapStep } from '../hooks'

export const SwapLoader: FC<any> = ({ swapStep }) => {
    if (swapStep === SwapStep.Transaction || swapStep === SwapStep.SwitchChain) {
        return <Loader />
    }

    if (swapStep === SwapStep.SwapSuccess) {
        return <SuccessCircle />
    }

    if (swapStep === SwapStep.Declined) {
        return <DeclinedCircle />
    }

    return null
}
import { styled } from '@stitches/react';

export const NetworkList = styled('ul', {
    listStyle: 'none',
    padding: 0,
    margin: 0,
})

export const NetworkListContainer = styled('div', {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 32,
    zIndex: 1,
    paddingTop: 8,
})

export const NetworkListContainerBody = styled('div', {
    fontSize: 16,
    border: '1px solid rgba(147, 170, 200, 0.2)',
    boxShadow: '12px 12px 70px rgba(17, 51, 102, 0.1)',
    borderRadius: 12,
    overflow: 'hidden',
    minWidth: 220,
    background: '#fff',
})

export const NetworkListCaption = styled('div', {
    fontWeight: 700,
    padding: '17px 16px 20px',
})

export const NetworkListItem = styled('li', {
    padding: '6px 14px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover': {
        background: '#e0edfd',
    },

    '&:not(:first-child):after': {
        content: '',
        position: 'absolute',
        top: -0.5,
        left: 16,
        right: 0,
        display: 'block',
        height: 0.5,
        background: '#e0edfd',
    },

    '&:hover:after': {
        display: 'none',
    },

    svg: {
        marginLeft: 'auto',

        path: {
            stroke: '#006AED',
        }
    }
})

export const SelectedNetwork = styled('div', {
    alignItems: 'center',
    display: 'flex',
    borderRadius: 16,
    fontSize: 16,
    padding: '4px 8px',
    color: '#fff',
    fontWeight: 500,

    svg: {
        path: {
            stroke: 'white',
        }
    },

    img: {
        width: 24,
        height: 24,
        marginRight: 0,
    },

    'img.empty-network-icon': {
        marginRight: 4,
    }
})

export const NetworkIcon = styled('img', {
    width: 29,
    height: 29,
    marginRight: 6,
})

export const SelectNetworkContainer = styled('div', {
    position: 'relative',
    marginLeft: 16,
    marginTop: 3,
    cursor: 'default',

    '&:hover .networkListContainer': {
        display: 'inline-block',
    }
})
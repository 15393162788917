export * from './links';
export * from './coins';
export * from './scroll';
export * from './project';
export * from './chains';
export * from './devices';
export * from './storage';
export * from './screen';
export * from './collaborations';

export const ROADMAP_AUTO_SCROLL_TIME = 3000;
export const ANIMATION_PLANET_ID = '09173952DFEE43BEA3CDEF9BDD5E0A35';
export const CATALOG_PREVIEW_CATEGORY_SIZE = 6;
export const CSV_TOKEN_PREFIX = 'token_';
export const SWAP_SLIPPAGE = 300;
export const SWAP_DURATION_TIMEOUT = 10 * 60;

export const isProd = process.env.NODE_ENV === 'production';

import { Dispatch } from 'react';
import { userApi } from 'api';
import { TelegramButtonState, TelegramUser } from 'types';
import { STORAGE_USER } from 'config';
import { System, SystemAction } from './types';

const SET_ACCOUNT = 'System/SET_ACCOUNT';
const SET_TG_BUTTON = 'System/SET_TG_BUTTON';
const SET_SCROLL_RESTORE = 'System/SET_SCROLL_RESTORE';

const initialState: System = {
    account: null,
    telegramButton: null,
    isScrollRestore: false,
};

export const init = () => {
    return (dispatch: Dispatch<any>) => {
        if (localStorage.getItem(STORAGE_USER)) {
            dispatch({
                type: SET_ACCOUNT,
                payload: JSON.parse(localStorage.getItem(STORAGE_USER) || ''),
            });

            userApi.getAuthToken(
                JSON.parse(localStorage.getItem(STORAGE_USER) || '')
            );
        }
    };
};

export const telegramLogin = (user: TelegramUser) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_ACCOUNT,
            payload: user,
        });

        localStorage.setItem(STORAGE_USER, JSON.stringify(user));
        userApi.getAuthToken(user);
    };
};

export const setTelegramButton = (state: TelegramButtonState) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_TG_BUTTON,
            payload: state,
        });
    };
};

export const setScrollRestore = (flag: boolean) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_SCROLL_RESTORE,
            payload: flag,
        });
    };
};

const SystemReducer = (state = initialState, action: SystemAction): System => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };

        case SET_TG_BUTTON:
            return {
                ...state,
                telegramButton: action.payload,
            };

        case SET_SCROLL_RESTORE:
            return {
                ...state,
                isScrollRestore: action.payload,
            };

        default:
            return state;
    }
};

export default SystemReducer;

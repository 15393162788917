import { styled } from 'styles';

export const Wrapper = styled('div', {
    flex: '1 0 auto',
    padding: '180px 0 0 80px',
    color: '$darkBlue',

    '@media only screen and (max-width: 640px)': {
        padding: '100px 0 0 20px',
    },
});
export const Title = styled('h2', {
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: 60,
});
export const Emails = styled('div', {
    marginTop: 32,
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: 36,

    '& > div:not(:first-child)': {
        marginLeft: 40,
    },
    '& > div > a': {
        color: '$basicBlue',
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 36,
        textDecoration: 'none',
    },

    '@media only screen and (max-width: 640px)': {
        flexDirection: 'column',

        '& > div:not(:first-child)': {
            marginLeft: 0,
            marginTop: 10,
        },
    },
});
export const Social = styled('div', {
    marginTop: 32,

    '& > svg': {
        cursor: 'pointer',

        '&:hover > path': {
            fill: '$hoverPrimary',
        },

        '&:not(:first-child)': {
            marginLeft: 16,
        },
    },
});
export const Download = styled('div', {
    fontSize: 24,
    lineHeight: 36,
    cursor: 'pointer',

    '& > span': {
        color: '$basicBlue',
    },
});

import { FC } from 'react'
import { Close, Portal, Root, Trigger } from '@radix-ui/react-dialog'
import { ReactComponent as Cross2Icon } from 'assets/img/x.svg'

import { DialogProps } from './types'

import { StyledClose, StyledContent, StyledOverlay, StyledTitle } from './style'

export const Dialog: FC<DialogProps> = ({ close, ...props}) => {
    const { open, children, content, title } = props

    return (
        <Root open={open}>
            <Trigger asChild>{children}</Trigger>
            <Portal>
                <StyledOverlay />
                <StyledContent {...props}>
                    {title && <StyledTitle>{title}</StyledTitle>}
                    <Close asChild aria-label="Close" onClick={close}>
                        <StyledClose>
                            <Cross2Icon />
                        </StyledClose>
                    </Close>
                    {content}
                </StyledContent>
            </Portal>
        </Root>
    )
}

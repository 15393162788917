export const ROUTE_MAIN = '/';
export const ROUTE_STAKING = '/staking';
export const ROUTE_CONTACTS = '/contacts';
export const ROUTE_BRIDGE = '/bridge';
export const ROUTE_DESKTOP_ANALYTICS = '/desktop-analytics';
export const ROUTE_AIRDROP = '/bulksend';
export const ROUTE_CATALOG = '/catalog';
export const ROUTE_EXCHANGE = '/exchange';
export const ROUTE_COLLABORATIONS = '/collaborations';
export const ROUTE_DEEP_LINK = '/download';
export const ROUTE_REFERRAL_LINK = '/open-referral';
export const ROUTE_OPEN_APP_LINK = '/open';
export const ROUTE_PREMIUM_DEEP_LINK = '/premium';
export const ROUTE_WALLET_DEEP_LINK = '/wallet';
export const ROUTE_STAKING_DEEP_LINK = '/staking';
export const ROUTE_SWAP_DEEP_LINK = '/swap';
export const ROUTE_CHANNEL = '/channel';
export const ROUTE_CHANNEL_CREATE = '/channel-create';
export const ROUTE_VESTING = '/vesting';
export const ROUTE_DOWNLOAD_GLOBAL = '/download';
export const ROUTE_DOWNLOAD_DESKTOP = '/desktop';
export const ROUTE_DOWNLOAD_ANDROID = '/android';
export const ROUTE_DOWNLOAD_ANDROID_APK = '/download/android/apk';
export const ROUTE_DOWNLOAD_DESKTOP_LINUX = '/download/desktop/linux';
export const ROUTE_DOWNLOAD_DESKTOP_WIN_64_PORTABLE = '/download/desktop/win64_portable';
export const ROUTE_DOWNLOAD_DESKTOP_WIN_PORTABLE = '/download/desktop/win_portable';
export const ROUTE_DOWNLOAD_DESKTOP_WIN_64 = '/download/desktop/win64';
export const ROUTE_DOWNLOAD_DESKTOP_WIN = '/download/desktop/win';
export const ROUTE_DOWNLOAD_DESKTOP_MAC = '/download/desktop/mac';
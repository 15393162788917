import { FC, useState } from 'react';
import './style.scss';
import arrow from 'assets/img/arrow-long.svg';
import { Button } from '../../form/Button';
import { useHistory } from 'react-router-dom';
import { ROUTE_CATALOG } from 'routes/names';
import Modal from '../../Modal';
import close from 'assets/img/cancel.svg';

interface IHeaderWithNavigation {
    backName: string;
    backAction: () => any;
    forwardName?: string;
    forwardAction?: () => any;
    isExit?: boolean;
    isFrowardActive?: boolean;
    isForwardVisible?: boolean;
}

const HeaderWithNavigation: FC<IHeaderWithNavigation> = ({
    backName,
    backAction,
    forwardName,
    forwardAction,
    isExit,
    isFrowardActive,
    isForwardVisible,
}) => {
    const history = useHistory();
    const [modalShown, setModalShown] = useState<boolean>(false);
    return (
        <div className={'header-add-channel'}>
            <Modal isShown={modalShown}>
                <div className={'confirm'}>
                    <img
                        className={'close'}
                        src={close}
                        alt={'close'}
                        onClick={() => setModalShown(false)}
                    />
                    <div className={'title'}>Are you sure?</div>
                    <div className={'text'}>
                        Added information will be deleted. The process of adding
                        a channel will have to start over.
                    </div>
                    <Button
                        isRedFill
                        onClick={() => history.push(ROUTE_CATALOG)}
                    >
                        Exit
                    </Button>
                </div>
            </Modal>

            <div className={'buttons'}>
                <Button isBlue onClick={() => backAction()}>
                    <img src={arrow} alt={'back'} />
                    {backName}
                </Button>
                {isForwardVisible ? (
                    <Button
                        isDisabled={!isFrowardActive}
                        isBlue
                        onClick={() => forwardAction && forwardAction()}
                    >
                        <img
                            className={'forward'}
                            src={arrow}
                            alt={'forward'}
                        />
                        {forwardName}
                    </Button>
                ) : null}
            </div>
            {isExit ? (
                <div className={'exit'}>
                    <Button onClick={() => setModalShown(true)} isRedFill>
                        Exit
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default HeaderWithNavigation;

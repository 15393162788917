import { FC, Fragment } from 'react';
import Header from '../../components/Header';
import Checkout from '../../components/Service/Catalog/Checkout';

const CheckoutPage: FC = () => {
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <Checkout />
            </div>
        </Fragment>
    );
};

export default CheckoutPage;

import React, { FC } from 'react'
import HeaderService from '../components/Header/HeaderServiceNew'
import Footer from 'components/Footer'
import Airdrop from 'components/Service/Airdrop'
import { Web3WalletProvider } from 'modules/web3/provider'

const AirdropPage: FC = () => {
    return (
        <Web3WalletProvider>
            <div className={'flex-container-wrapper'}>
                <HeaderService />
                <Airdrop />
                <div className={'footer-wrapper'}>
                    <Footer />
                </div>
            </div>
        </Web3WalletProvider>
    )
}

export default AirdropPage

import { FC, Fragment, useState } from 'react';
import './style.scss';
import '../../style.scss';
import { Button } from '../../../form/Button';
import ChannelStateScreen from '../ChannelStateScreen';
import { useSelector } from 'react-redux';
import { AppStateType } from 'store';
import ChannelInfo from '../Channel/ChannelInfo';
import lime from 'assets/img/landing/Lime/lime.svg';
import { useHistory } from 'react-router-dom';
import { COIN_LIME, PROJECT_SHORT_NAME } from 'config';
import { ROUTE_CATALOG } from 'routes/names';
import CheckoutButtons from './CheckoutButtons';

const Checkout: FC = () => {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isCheckout, setIsCheckout] = useState<boolean>(false);

    const channel = useSelector(
        (state: AppStateType) => state.catalog.currentChannel
    );
    const history = useHistory();

    const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(0);

    return isCheckout ? (
        <Fragment>
            {isCompleted ? (
                <ChannelStateScreen
                    title={'Payment completed'}
                    text={`Congratulations! Your channel is now published in ${PROJECT_SHORT_NAME} catalog.`}
                    buttons={
                        <Fragment>
                            <Button
                                isBlueFill
                                onClick={() => history.push(ROUTE_CATALOG)}
                            >
                                Back to catalog
                            </Button>{' '}
                        </Fragment>
                    }
                />
            ) : (
                <div className={'channel-checkout'}>
                    <div className={'content'}>
                        <div className={'title'}>Checkout</div>
                        <CheckoutButtons
                            selectedIndex={selectedPeriodIndex}
                            setSelectedIndex={setSelectedPeriodIndex}
                            buttons={[
                                { price: 20, time: '2 month' },
                                { price: 30, time: '4 month' },
                            ]}
                        />
                        <div className={'subtitle'}>Payment method</div>
                        <div className={'lime-block'}>
                            <div>
                                <div className={'lime-account-balance'}>
                                    Lime account balance
                                </div>
                                <div className={'error'}>
                                    Wallet not connected
                                </div>
                            </div>
                            <Button isBlueFill>Connect</Button>
                        </div>

                        <div className={'fee-block'}>
                            <div>Channel publication fee</div>
                            <div className={'fee'}>12.5 {COIN_LIME}</div>
                        </div>

                        <Button
                            isGreenFill
                            isFullWidth
                            onClick={() => setIsCompleted(true)}
                        >
                            Pay 12.5 {COIN_LIME}
                        </Button>
                    </div>
                </div>
            )}
        </Fragment>
    ) : (
        <Fragment>
            <div className={'channel-checkout verified'}>
                <div className={'content'}>
                    <div className={'title'}>Your channel is verified</div>
                    <div className={'subtitle'}>
                        Please, pay a fee to publish the channel to the catalog.
                    </div>
                    <ChannelInfo channel={channel} />

                    <div className={'fee-block'}>
                        <div>
                            Channel publication fee
                            <div className={'fee'}>
                                <img src={lime} alt={'lime'} />
                                12.5 {COIN_LIME}
                            </div>
                        </div>

                        <Button isGreenFill onClick={() => setIsCheckout(true)}>
                            Checkout
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Checkout;

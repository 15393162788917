import { FC, useEffect, useRef, useState } from 'react';
import './style.scss';
import { Button } from '../../form/Button';
import limePic from 'assets/img/landing/LimeFilled.svg';
import downloadPic from 'assets/img/buttons/download.svg';
import green from 'assets/img/landing/Lime/green.svg';
import horse from 'assets/img/landing/Lime/horse.svg';
import M from 'assets/img/landing/Lime/M.svg';
import sq from 'assets/img/landing/Lime/gate.svg';
import brown from 'assets/img/landing/Lime/brown.svg';
import etherscan from 'assets/img/landing/Lime/etherscan.svg';
import bsc from 'assets/img/landing/Lime/bscscan.svg';
import watch from 'assets/img/buttons/watch.svg';
import ascendex from 'assets/img/landing/Lime/ascendex.svg';
import apeSwap from 'assets/img/landing/Lime/apeSwap.svg';
import cancel from 'assets/img/cancel.svg';
import bitcoin from 'assets/img/landing/Lime/bitcoin.svg';
import ethereum from 'assets/img/landing/Lime/ethereum.svg';
import usdt from 'assets/img/landing/Lime/usdt.svg';
import lime from 'assets/img/landing/Lime/lime.svg';
import sushi from 'assets/img/landing/Lime/sushiSwap.svg';
import uniswapV3 from 'assets/img/landing/Lime/uniswapV3.svg';
import tradingView from 'assets/img/landing/Lime/tradingView.svg';
import DEXTools from 'assets/img/landing/Lime/dextools.svg';
import polygonScan from 'assets/img/landing/Lime/polygonScan.svg';
import { ReactComponent as Certic } from 'assets/img/landing/Lime/certik.svg';
import classNames from 'classnames';
import { openNewTab } from 'utils';
import {
    COIN_BTC,
    COIN_ETH,
    COIN_LIME,
    COIN_USDT,
    LINK_DEXTOOLS,
    LINK_LEGAL_OPINION,
    LINK_LIME_APESWAP,
    LINK_LIME_ASCENDEX,
    LINK_LIME_AUDIT,
    LINK_LIME_BSCSCAN,
    LINK_LIME_COINMARKET,
    LINK_LIME_ETHERSCAN,
    LINK_LIME_GATE_BTC,
    LINK_LIME_GATE_ETH,
    LINK_LIME_GATE_USDT,
    LINK_LIME_GECKO,
    LINK_LIME_PANCAKE,
    LINK_LIME_UNISWAP,
    LINK_LIME_YOUTUBE,
    LINK_POLYGONSCAN,
    LINK_SUSHISWAP,
    LINK_TRADING_VIEW,
    LINK_UNISWAPV3,
    PROJECT_SHORT_NAME,
    PROJECT_NAME,
} from 'config';
import { useOnScreen } from 'hooks';

const Lime: FC = () => {
    const [isMenuShown, setIsMenuShown] = useState<boolean>(false);
    const menuToggle = () => setIsMenuShown(!isMenuShown);
    const limeRef = useRef<HTMLDivElement>(null);
    const [animationStarted, setAnimationStarted] = useState<boolean>(false);
    const isVisible = useOnScreen(limeRef, 0.5);

    useEffect(() => {
        if (isVisible) setAnimationStarted(true);
    }, [isVisible]);

    return (
        <div
            ref={limeRef}
            className={classNames({
                lime__container: true,
                'lime-animate': animationStarted,
            })}
        >
            <div
                className="background-div"
                style={{ backgroundImage: 'url(' + limePic + ')' }}
            />

            <div className={'content'}>
                <div className={'lime__title'}>
                    <div>
                        {`Lime Token \n& ${PROJECT_SHORT_NAME} Ecosystem`}
                        <div
                            className={'certik'}
                            onClick={() => openNewTab(LINK_LIME_AUDIT)}
                        >
                            <Certic /> Audited by Certik
                        </div>
                    </div>
                    <div className={'buttons scan'}>
                        <img
                            alt={'market'}
                            src={etherscan}
                            onClick={() => {
                                openNewTab(LINK_LIME_ETHERSCAN);
                            }}
                        />
                        <img
                            alt={'market'}
                            src={bsc}
                            onClick={() => {
                                openNewTab(LINK_LIME_BSCSCAN);
                            }}
                        />
                        <img
                            alt={'market'}
                            src={polygonScan}
                            onClick={() => {
                                openNewTab(LINK_POLYGONSCAN);
                            }}
                        />
                    </div>
                </div>
                <div className={'lime__text'}>
                    {`${COIN_LIME} by ${PROJECT_NAME} is the utility token of the platform, which has smart contracts developed on Ethereum and BNB Chain.\n
                        The native ${COIN_LIME} token serves as the building material of ${PROJECT_SHORT_NAME} platform, on which various services are built using ${COIN_LIME}.\n
                        ${PROJECT_SHORT_NAME} creates the DAO-structured ecosystem with large scale use of ${COIN_LIME} by promoting and popularizing DeFi toolkit.`}
                </div>
                <div>
                    <div className={'lime__buttons lime__buttons--main'}>
                        <Button
                            isBlueBorder
                            onClick={() => {
                                openNewTab(LINK_LIME_YOUTUBE);
                            }}
                        >
                            <img alt="watch" src={watch} />
                            <div style={{ marginLeft: '12px' }}>
                                Watch video
                            </div>
                        </Button>
                        <Button
                            isBlueBorder
                            onClick={() => openNewTab(LINK_LEGAL_OPINION)}
                            className={`lime__buttons--main_legal-opinion`}
                        >
                            Legal Opinion (.pdf)
                            <img
                                alt={'dl'}
                                src={downloadPic}
                                style={{ marginLeft: '10px' }}
                            />
                        </Button>
                    </div>
                    <div
                        className={classNames({
                            lime__buttons: true,
                            crypto: true,
                            inactive: isMenuShown,
                        })}
                    >
                        <div className={'block'}>
                            <div className={'market'}>
                                <img
                                    alt={'coin market'}
                                    src={M}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_COINMARKET);
                                    }}
                                />
                                <span className={'tooltip'}>CoinMarketCap</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'gecko'}
                                    src={green}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_GECKO);
                                    }}
                                />
                                <span className={'tooltip'}>CoinGecko</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'dextools'}
                                    src={DEXTools}
                                    onClick={() => {
                                        openNewTab(LINK_DEXTOOLS);
                                    }}
                                />
                                <span className={'tooltip'}>DEXTools</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'tradingView'}
                                    src={tradingView}
                                    onClick={() => {
                                        openNewTab(LINK_TRADING_VIEW);
                                    }}
                                />
                                <span className={'tooltip'}>TradingView</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'uniswap'}
                                    src={horse}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_UNISWAP);
                                    }}
                                />
                                <span className={'tooltip'}>Uniswap</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'pancake'}
                                    src={brown}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_PANCAKE);
                                    }}
                                />
                                <span className={'tooltip'}>PancakeSwap</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'ApeSwap'}
                                    src={apeSwap}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_APESWAP);
                                    }}
                                />
                                <span className={'tooltip'}>ApeSwap</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'UniswapV3'}
                                    src={uniswapV3}
                                    onClick={() => {
                                        openNewTab(LINK_UNISWAPV3);
                                    }}
                                />
                                <span className={'tooltip'}>UniswapV3</span>
                            </div>
                            <div className={'market'}>
                                <img
                                    alt={'SushiSwap'}
                                    src={sushi}
                                    onClick={() => {
                                        openNewTab(LINK_SUSHISWAP);
                                    }}
                                />
                                <span className={'tooltip'}>SushiSwap</span>
                            </div>
                            <div className={'gate market'}>
                                <img
                                    alt={'gate'}
                                    src={sq}
                                    onClick={() => menuToggle()}
                                />
                                <span className={'tooltip'}>Gate</span>

                                <div
                                    className={classNames({
                                        'menu-external-wrapper': true,
                                        visible: isMenuShown,
                                    })}
                                >
                                    <div
                                        className={'menu-wrapper'}
                                        onClick={() => menuToggle()}
                                    />
                                    <div
                                        className={'trade-list'}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className={'title'}>
                                            {' '}
                                            Select a trading pair:{' '}
                                            <img
                                                alt={'cancel'}
                                                src={cancel}
                                                onClick={() => menuToggle()}
                                            />
                                        </div>
                                        <div
                                            className={'item-wrapper'}
                                            onClick={() => {
                                                openNewTab(LINK_LIME_GATE_ETH);
                                            }}
                                        >
                                            <div className={'item'}>
                                                <img
                                                    alt={COIN_LIME}
                                                    src={lime}
                                                />
                                                {COIN_LIME} –
                                                <img
                                                    alt={COIN_ETH}
                                                    src={ethereum}
                                                />
                                                {COIN_ETH}
                                            </div>
                                        </div>
                                        <div
                                            className={'item-wrapper'}
                                            onClick={() => {
                                                openNewTab(LINK_LIME_GATE_USDT);
                                            }}
                                        >
                                            <div className={'item'}>
                                                <img
                                                    alt={COIN_LIME}
                                                    src={lime}
                                                />
                                                {COIN_LIME} –
                                                <img
                                                    alt={COIN_USDT}
                                                    src={usdt}
                                                />
                                                {COIN_USDT}
                                            </div>
                                        </div>
                                        <div
                                            className={'item-wrapper'}
                                            onClick={() => {
                                                openNewTab(LINK_LIME_GATE_BTC);
                                            }}
                                        >
                                            <div className={'item'}>
                                                <img
                                                    alt={COIN_LIME}
                                                    src={lime}
                                                />
                                                {COIN_LIME} –
                                                <img
                                                    alt={COIN_BTC}
                                                    src={bitcoin}
                                                />
                                                {COIN_BTC}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'market'}>
                                <img
                                    alt={'ascendex'}
                                    src={ascendex}
                                    onClick={() => {
                                        openNewTab(LINK_LIME_ASCENDEX);
                                    }}
                                />
                                <span className={'tooltip'}>AscendEX</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lime;

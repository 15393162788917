import { Dispatch } from 'react';
import axios from 'axios';
import { TokenMetrics } from 'types';

const SET_SCROLL_TO = 'Landing/SET_SCROLL_TO';
const SET_LIME_METRICS = 'Landing/SET_LIME_METRICS';
const SET_LOGIN = 'Landing/SET_LOGIN';

interface ILanding {
    scrollTo: string | null;
    limeMetrics: TokenMetrics | null;
    account: any | null;
}

interface ISystemAction {
    type: string;
    payload: any;
}

const initialState: ILanding = {
    scrollTo: null,
    limeMetrics: null,
    account: null,
};

export const loginSet = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_LOGIN,
        });
    };
};

export const setScrollTo = (target: string | null) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_SCROLL_TO,
            payload: target,
        });
    };
};

export const getLimeMetrics = () => {
    return (dispatch: Dispatch<any>) => {
        axios
            .get(process.env.REACT_APP_LIME_METRICS_URL as string)
            .then((res) => {
                dispatch({
                    type: SET_LIME_METRICS,
                    payload: res.data,
                });
            })
            .catch((e) => console.log(e));
    };
};

const LandingReducer = (
    state = initialState,
    action: ISystemAction
): ILanding => {
    switch (action.type) {
        case SET_LOGIN:
            return {
                ...state,
                account: 'test',
            };

        case SET_SCROLL_TO:
            return {
                ...state,
                scrollTo: action.payload,
            };

        case SET_LIME_METRICS:
            return {
                ...state,
                limeMetrics: action.payload,
            };

        default:
            return state;
    }
};

export default LandingReducer;

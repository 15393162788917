import { Dialog } from 'components/Dialog';
import { BigNumber, utils } from 'ethers'

import { formatBalance } from 'utils/formatBalance'
import { Status } from './Status'
import './style.scss'

type InProgressDialogProps = {
    open: boolean,
    closeDialog: () => void
    wallet?: string
    balance: string
    totalTokenAmount?: BigNumber
    airdrop: () => void
    loading: boolean
    showApprove: boolean
    txLink?: string
    chainName?: string
    error?: boolean
    errCode: string
}

export const InProgressDialog: React.FC<InProgressDialogProps> = ({
    open,
    closeDialog,
    wallet,
    balance,
    totalTokenAmount,
    airdrop,
    loading,
    showApprove,
    chainName,
    txLink,
    errCode,
    error,
}) => {
    if (showApprove) {
        return (
            <Dialog
                open={open}
                close={closeDialog}
                className="airdrop-progress-dialog-container airdrop-progress-dialog-container--inprogress"
                content={
                    <div className="airdrop-progress-dialog">
                        <div className="airdrop-progress-dialog-loader">
                            <Status loading />
                        </div>
                        <h2>Approving Tokens!</h2>
                        <p className="airdrop-progress-dialog-text">
                            Waiting for the transaction confirmation. <br />
                            Confirm transaction in your wallet.
                        </p>
                    </div>
                }
            />
        )
    }

    if (loading) {
        return (
            <Dialog
                open={open}
                close={closeDialog}
                className="airdrop-progress-dialog-container airdrop-progress-dialog-container--inprogress"
                content={
                    <div className="airdrop-progress-dialog">
                        <div className="airdrop-progress-dialog-loader">
                            <Status loading />
                        </div>
                        <h2>Multi-transfer in progress!</h2>
                        <p className="airdrop-progress-dialog-text">
                            Waiting for the transaction confirmation. <br />
                            Confirm transaction in your wallet.
                        </p>
                    </div>
                }
            />
        )
    }

    if (txLink) {
        return (
            <Dialog
                open={open}
                close={closeDialog}
                className="airdrop-progress-dialog-container airdrop-progress-dialog-container--short"
                content={
                    <div className="airdrop-progress-dialog">
                        <div className="airdrop-progress-dialog-loader">
                            <Status success />
                        </div>
                        <h2>Multi-transfer sent</h2>
                        <button onClick={closeDialog}>
                            Close
                        </button>
                        <a href={txLink} target="_blank" rel="noreferrer">View on {chainName}</a>
                    </div>
                }
            />
        )
    }

    if (errCode === 'ACTION_REJECTED') {
        return (
            <Dialog
                open={open}
                close={closeDialog}
                className="airdrop-progress-dialog-container airdrop-progress-dialog-container--short airdrop-progress-dialog-container--declined"
                content={
                    <div className="airdrop-progress-dialog">
                        <div className="airdrop-progress-dialog-loader">
                            <Status error />
                        </div>
                        <h2>Transaction declined</h2>
                        <button onClick={closeDialog}>
                            Close
                        </button>
                    </div>
                }
            />
        )
    }

    return (
        <Dialog
            title="Confirm Multi-transfer"
            open={open}
            close={closeDialog}
            className="airdrop-progress-dialog-container"
            content={
                <div className="airdrop-progress-dialog">
                    <div className="airdrop-form-meta airdrop-form-meta--dialog">
                        <div>
                            <div>Your address:</div>
                            <div>{wallet}</div>
                        </div>
                        <div>
                            <div>Your wallet balance:</div>
                            <div>{formatBalance(balance)}</div>
                        </div>
                        <div>
                            <div>Amount to be sent:</div>
                            <div>{totalTokenAmount && utils.formatEther(totalTokenAmount)}</div>
                        </div>
                    </div>
                    <button onClick={airdrop}>
                        Confirm Multi-transfer
                    </button>
                </div>
            }
        />
    )
}
import { Dialog } from 'components/Dialog';
import { BigNumber } from 'bignumber.js'

import ArrowRightIcon from 'assets/img/arrow-right.svg';

import { ChainNamesType } from '../../types'
import { CHAINS_DATA } from '../../constants'
import { SwapStep } from '../hooks'
import { SwapLoader } from './SwapLoader'
import { LimeIcon } from '../LimeIcon'

type InProgressDialogProps = {
    open: boolean,
    fromChain: ChainNamesType
    toChain: ChainNamesType
    amount: string
    expectedAmount: string
    closeInprogressDialog: () => void
    submitSuccessSwap: () => void
    submitDecline: () => void
    toggleShowInprogressDialog: (open: boolean) => void
    loading: boolean
    swapMeta?: any
    swap?: any
    [k: string]: any
}

export const InProgressDialog: React.FC<InProgressDialogProps> = ({
    open,
    fromChain,
    toChain,
    amount,
    swapMeta,
    expectedAmount,
    closeInprogressDialog,
    toggleShowInprogressDialog,
    submitDecline,
    transactionHash,
    swap,
    submitSuccessSwap,
    swapStep,
}) => {
    const fromAmount = amount
    const toAmount = expectedAmount

    if (swapStep === SwapStep.Transaction) {
        return (
            <Dialog
                open={open}
                close={submitDecline}
                className="swap-progress-dialog-container swap-progress-dialog-container--inprogress"
                content={
                    <div className="swap-progress-dialog">
                        <div className="swap-progress-dialog-loader">
                            <SwapLoader swapStep={swapStep} />
                        </div>
                        <div className="swap-progress-dialog-coins">
                            <div className="swap-progress-dialog-coin">
                                <div className="transfer-input-lime-icons">
                                    <LimeIcon chain={fromChain} />
                                </div>
                                <div>
                                    <h5>{parseFloat(BigNumber(fromAmount).toFixed(4))}</h5>
                                    <p>From&nbsp;{CHAINS_DATA[fromChain].name}</p>
                                </div>
                            </div>
                            <img src={ArrowRightIcon} alt="To" />
                            <div className="swap-progress-dialog-coin">
                                <div className="transfer-input-lime-icons">
                                    <LimeIcon chain={toChain} />
                                </div>
                                <div>
                                    <h5>{parseFloat(BigNumber(toAmount).toFixed(4))}</h5>
                                    <p>To&nbsp;{CHAINS_DATA[toChain].name}</p>
                                </div>
                            </div>
                        </div>
                        <h2>Swap in progress!</h2>
                        <p className="swap-progress-dialog-text">
                            Waiting for the transaction confirmation. <br />
                            Confirm transaction in your wallet.
                        </p>
                    </div>
                }
            />
        )
    }

    if (swapStep === SwapStep.SwitchChain) {
        return (
            <Dialog
                open={open}
                close={closeInprogressDialog}
                className="swap-progress-dialog-container swap-progress-dialog-container--inprogress"
                content={
                    <div className="swap-progress-dialog">
                        <div className="swap-progress-dialog-loader">
                            <SwapLoader swapStep={swapStep} />
                        </div>
                        <h2>Change network</h2>
                        <p className="swap-progress-dialog-text">
                            Open the connected extension and change the network.
                        </p>
                    </div>
                }
            />
        )
    }

    if (swapStep === SwapStep.SwapSuccess) {
        const transactionLink = `${CHAINS_DATA[fromChain].explorer.replace(/\/$/, '')}/${transactionHash}`

        return (
            <Dialog
                open={open}
                close={submitSuccessSwap}
                className="swap-progress-dialog-container swap-progress-dialog-container--short"
                content={
                    <div className="swap-progress-dialog">
                        <div className="swap-progress-dialog-loader">
                            <SwapLoader swapStep={swapStep} />
                        </div>
                        <h2>Transaction sent</h2>
                        <button onClick={submitSuccessSwap}>
                            Close
                        </button>
                        <a href={transactionLink} target="_blank" rel="noreferrer">View on {CHAINS_DATA[fromChain].name}</a>
                    </div>
                }
            />
        )
    }

    if (swapStep === SwapStep.Declined) {
        return (
            <Dialog
                open={open}
                close={submitDecline}
                className="swap-progress-dialog-container swap-progress-dialog-container--short swap-progress-dialog-container--declined"
                content={
                    <div className="swap-progress-dialog">
                        <div className="swap-progress-dialog-loader">
                            <SwapLoader swapStep={swapStep} />
                        </div>
                        <h2>Transaction declined</h2>
                        <button onClick={submitDecline}>
                            Close
                        </button>
                    </div>
                }
            />
        )
    }

    if (swapStep === SwapStep.ReadyToSwap) {
        return (
            <Dialog
                title="Confirm swap"
                open={open}
                close={() => toggleShowInprogressDialog(false)}
                className="swap-progress-dialog-container"
                content={
                    <div className="swap-progress-dialog">
                        <div>
                            <SwapLoader swapStep={swapStep} />
                        </div>
                        <div className="swap-progress-dialog-coins">
                            <div className="swap-progress-dialog-coin">
                                <div className="transfer-input-lime-icons">
                                    <LimeIcon chain={fromChain} />
                                </div>
                                <div>
                                    <h5>{parseFloat(BigNumber(fromAmount).toFixed(4))}</h5>
                                    <p>From&nbsp;{CHAINS_DATA[fromChain].name}</p>
                                </div>
                            </div>
                            <img src={ArrowRightIcon} alt="To" />
                            <div className="swap-progress-dialog-coin">
                                <div className="transfer-input-lime-icons">
                                    <LimeIcon chain={toChain} />
                                </div>
                                <div>
                                    <h5>{parseFloat(BigNumber(toAmount).toFixed(4))}</h5>
                                    <p>To&nbsp;{CHAINS_DATA[toChain].name}</p>
                                </div>
                            </div>
                        </div>
                        {swapMeta}
                        <button onClick={swap}>
                            Confirm swap
                        </button>
                    </div>
                }
            />
        )
    }

    return null
}
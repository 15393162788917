import { FC } from 'react'
import { CheckboxProps } from '@radix-ui/react-checkbox'
import { ReactComponent as CheckIcon } from 'assets/img/check.svg';

import { CheckboxRoot, CheckboxIndicator } from './style'

export const Checkbox: FC<CheckboxProps> = (props) => {
    return (
        <CheckboxRoot {...props}>
            <CheckboxIndicator>
                <CheckIcon />
            </CheckboxIndicator>
        </CheckboxRoot>
    )
}

import { FC, useEffect, useRef, useState } from 'react';
import './style.scss';
import telegram from 'assets/img/landing/about/telegram.svg';
import aws from 'assets/img/landing/about/aws.svg';
import cloud from 'assets/img/landing/about/cloud.svg';
import azure from 'assets/img/landing/about/azure.svg';
import pancake from 'assets/img/landing/about/pancake.svg';
import simplex from 'assets/img/landing/about/simplex.svg';
import binance from 'assets/img/landing/about/binance.svg';
import bnb from 'assets/img/landing/about/bnb.svg';
import uniswap from 'assets/img/landing/about/uniswap.svg';
import ethereum from 'assets/img/landing/about/ethereum.svg';
import translation from 'assets/img/landing/about/translation.svg';
import firebase from 'assets/img/landing/about/firebase.svg';
import { useCanvas, useOnScreen } from 'hooks';
import classNames from 'classnames';
import { ANIMATION_PLANET_ID, PROJECT_SHORT_NAME } from 'config';
import { isMobile } from 'utils';

const About: FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    useCanvas(
        'planet',
        ANIMATION_PLANET_ID,
        canvasRef,
        animationRef,
        overlayRef,
        !isMobile(),
        true
    );

    const aboutRef = useRef<HTMLDivElement>(null);
    const [animationStarted, setAnimationStarted] = useState<boolean>(false);
    const isVisible = useOnScreen(aboutRef, 0.2);

    useEffect(() => {
        if (isVisible) setAnimationStarted(true);
    }, [isVisible]);

    return (
        <div className={'landing-about'} ref={aboutRef}>
            <div className={'animation-wrapper'} ref={animationRef}>
                <canvas ref={canvasRef} />
                <div ref={overlayRef}></div>
            </div>

            <div
                className={classNames({
                    container: true,
                    animated: animationStarted,
                })}
            >
                <div className={'description'}>
                    <div className={'title'}>About the project</div>

                    <div className={'text'}>
                        <div>
                            <div>
                                Multifunctional alternative Telegram client with
                                advanced functionality and DeFi & AI tools.
                            </div>
                        </div>
                        <div>
                            <div>
                                {PROJECT_SHORT_NAME} expands the official client
                                functionality and implements its own
                                technologies that become available to users all
                                over the world.
                            </div>
                        </div>
                        <div>
                            <div>
                                The low entrance threshold into the industry of
                                Cryptocurrencies and Artificial Intelligence
                                through the structure of the Telegram messenger
                                makes {PROJECT_SHORT_NAME} an attractive and
                                convenient platform for both ordinary and
                                professional users.
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'integration'}>
                    <div className={'title'}>Integrations & Technologies</div>

                    <div className={'block'}>
                        <div>
                            <div className={'brand'}>
                                <img src={telegram} alt={'telegram'} />
                                <div>API Telegram</div>
                            </div>
                            <div className={'brand'}>
                                <img src={cloud} alt={'telegram'} />
                                <div>Google Cloud Platform</div>
                            </div>
                            <div className={'brand'}>
                                <img src={aws} alt={'telegram'} />
                                <div>Amazon AWS</div>
                            </div>
                            <div className={'brand'}>
                                <img src={azure} alt={'telegram'} />
                                <div>Microsoft Azure</div>
                            </div>
                            <div className={'brand'}>
                                <img src={firebase} alt={'telegram'} />
                                <div>Firebase</div>
                            </div>
                            <div className={'brand'}>
                                <img src={translation} alt={'telegram'} />
                                <div>Translation Service</div>
                            </div>
                        </div>
                        <div>
                            <div className={'brand'}>
                                <img src={bnb} alt={'telegram'} />
                                <div>BNB Chain</div>
                            </div>
                            <div className={'brand'}>
                                <img src={ethereum} alt={'telegram'} />
                                <div>Ethereum Blockchain</div>
                            </div>
                            <div className={'brand'}>
                                <img src={binance} alt={'telegram'} />
                                <div>Binance Pay</div>
                            </div>
                            <div className={'brand'}>
                                <img src={uniswap} alt={'telegram'} />
                                <div>Uniswap</div>
                            </div>
                            <div className={'brand'}>
                                <img src={pancake} alt={'telegram'} />
                                <div>PancakeSwap</div>
                            </div>
                            <div className={'brand'}>
                                <img src={simplex} alt={'telegram'} />
                                <div>Simplex</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;

import { useEffect, useState, useCallback } from 'react'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect'
import { Web3ReactHooks } from '@web3-react/core'

import { WALLET_TYPE } from 'modules/web3'
import { walletConnect, hooks as walletConnectHooks } from 'modules/web3/walletConnect'
import { metaMask, hooks as metamaskHooks } from 'modules/web3/metamask'

import { useLocalStorage, LOCAL_STORAGE_ENUM } from 'hooks/useLocalStorage'

type ConnectWalletHandlers = {
    connect: (walletType: WALLET_TYPE) => void
    disconnect: () => void
    isShown: boolean
    openModal: () => void
    closeModal: () => void
}

export const connectors: [[MetaMask, Web3ReactHooks], [WalletConnect, Web3ReactHooks]] = [
    [metaMask, metamaskHooks],
    [walletConnect, walletConnectHooks],
]

const useConnectWallet = (setWalletType: (walletType: WALLET_TYPE) => void, setConnectError: (err: string) => void): ConnectWalletHandlers => {
    const [isShown, setIsShown] = useState<boolean>(false)

    const openModal = useCallback(() => {
        setIsShown(true)
    }, [])

    const closeModal = useCallback(() => {
        setIsShown(false)
    }, [])

    const connect = useCallback(async (walletType: WALLET_TYPE) => {
        console.log('connect wallet')
        if (walletType === WALLET_TYPE.WALLET_CONNECT) {
            try {
                await walletConnect.activate()
                setWalletType(WALLET_TYPE.WALLET_CONNECT)
                setConnectError('')
            } catch (err) {
                console.log(err, 'connect error')
                if ((err as Error).message !== 'User closed modal') {
                    setConnectError((err as Error).message)
                }
            }

            return
        }

        if (walletType === WALLET_TYPE.METAMASK) {
            try {
                await metaMask.activate()
                setWalletType(WALLET_TYPE.METAMASK)
                setConnectError('')
            } catch (err) {
                console.log(err, 'connect error')
                if ((err as Error).message !== 'MetaMask not installed') {
                    setConnectError((err as Error).message)
                }
            }

            return
        }

        setConnectError('failed connect wallet: unknown provider')

        return
    }, [setWalletType, setConnectError])

    const disconnect = useCallback(() => {
        console.log('disconnect wallet')
        if (metaMask?.deactivate) {
            void metaMask.deactivate()
        }

        if (walletConnect?.deactivate) {
            void walletConnect.deactivate()
        }

        setWalletType(WALLET_TYPE.UNSET)
    }, [setWalletType])

    return { connect, disconnect, isShown, openModal, closeModal }
}

export type WalletHook = {
    wallet: string
    shortWallet: string
    walletType: WALLET_TYPE
    walletError: string
    connection: ConnectWalletHandlers
}

export const useWallet = (): WalletHook  => {
    const [connectError, setConnectError] = useState('')
    const [currentWallet, setCurrentWallet] = useState('')
    const walletConnectAccount = walletConnectHooks.useAccount()
    const metamaskAccount = metamaskHooks.useAccount()
    const [walletType, setWalletType] = useLocalStorage<WALLET_TYPE>(LOCAL_STORAGE_ENUM.WALLET_TYPE, WALLET_TYPE.UNSET)
    const walletHandlers = useConnectWallet(setWalletType, setConnectError)

    useEffect(() => {
        if (walletType === WALLET_TYPE.WALLET_CONNECT && !walletConnectAccount) {
            walletConnect
                .connectEagerly()
                .then(() => setWalletType(WALLET_TYPE.WALLET_CONNECT))
                .catch(err => {
                    console.log(err, 'Could not connect eagerly walletconnect')
                    setWalletType(WALLET_TYPE.UNSET)
                    setCurrentWallet('')
                })
        }

        if (walletType === WALLET_TYPE.METAMASK && !metamaskAccount) {
            metaMask
                .connectEagerly()
                .then(() => setWalletType(WALLET_TYPE.METAMASK))
                .catch(err => {
                    console.log(err, 'Could not connect eagerly metamask')
                    setWalletType(WALLET_TYPE.UNSET)
                    setCurrentWallet('')
                })
        }
    }, [walletType, walletConnectAccount, metamaskAccount, setWalletType])

    useEffect(() => {
        if (walletType === WALLET_TYPE.WALLET_CONNECT && walletConnectAccount) {
            setCurrentWallet(walletConnectAccount)
        }

        if (walletType === WALLET_TYPE.METAMASK && metamaskAccount) {
            setCurrentWallet(metamaskAccount)
        }

        if (walletType === WALLET_TYPE.UNSET) {
            setCurrentWallet('')
        }
    }, [walletType, walletConnectAccount, metamaskAccount, setCurrentWallet])

    return {
        wallet: currentWallet,
        shortWallet: currentWallet && `${currentWallet.substring(0, 6)}...${currentWallet.slice(-4)}`,
        walletType,
        walletError: connectError,
        connection: walletHandlers,
    }
}
import React from 'react';

import './style.scss';

import PhonesImage from 'assets/img/android-ui.png';
import PhoneImage from 'assets/img/video-phone.png';
import videoUI from 'assets/video/iMeAndroid.mp4';
import { Button } from '../../form/Button';
import { LINK_ANDROID, LINK_GOOGLE_PLAY, PROJECT_SHORT_NAME } from 'config';
import { openNewTab } from 'utils';

const VideoPreview = () => (
    <div className="download-android-video">
        <img className="download-android-video-img" src={PhoneImage} alt="phones" />
        <video autoPlay loop muted playsInline>
            <source src={videoUI} type="video/mp4" />
        </video>
    </div>
)

export const DownloadAndroid = () => {
    return (
        <div className={`download-android-wrapper`}>
            <div className={`download-android-section`}>
                <div>
                    <img className="download-android-img" src={PhonesImage} alt="phones" />
                </div>
                <div className="download-android-block">
                    <h3>{PROJECT_SHORT_NAME} for Android</h3>
                    <p>
                        You can download {PROJECT_SHORT_NAME} for{' '}
                        <span>Android</span> device here.
                    </p>
                    <Button
                        isBlueFill
                        className={`download-button`}
                        onClick={() => {
                            openNewTab(LINK_ANDROID);
                        }}
                    >
                        Download {PROJECT_SHORT_NAME} App
                    </Button>
                    <p className='download-android-note'>
                        How to install {PROJECT_SHORT_NAME} App on
                        Android device? <span>Watch the video.</span>
                    </p>
                </div>
                <div>
                    <VideoPreview />
                </div>
            </div>
            <div className={`download-android-prefer`}>
                <p>Do you prefer to install apps from the Google Play Store?</p>
                <a
                    href={LINK_GOOGLE_PLAY}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Download {PROJECT_SHORT_NAME} from Google Play
                </a>
            </div>
        </div>
    );
};

import { styled } from '@stitches/react';

export const SwapRoutes = styled('div', {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& > img': {
        margin: '13px 17px 0',
    },
});
export const SwapRoute = styled('div', {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: 'white',
    padding: '4px 5px',
    paddingRight: 6,
    color: '#3a4f68', // will be added to theme
    fontSize: '.875rem',
    marginTop: 13,

    '& > img': {
        '&:first-child': {
            marginLeft: 1,
            marginRight: 3,
            maxWidth: 20,
            maxHeight: 20,
        },
        '&:not(:first-child)': {
            marginLeft: 4,
            marginRight: 2,
        },
    },
});

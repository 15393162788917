import EthIcon from 'assets/img/eth-icon.svg';
import BnbIcon from 'assets/img/bnb-icon.svg';
import MaticIcon from 'assets/img/matic-icon.svg';
import { COIN_BNB, COIN_ETH, COIN_MATIC } from './coins';
import { Contract, providers } from 'ethers';
import type { AddEthereumChainParameter } from '@web3-react/types';

import BnbTokenAbi from 'abis/BnbToken.json';
import PolygonTokenAbi from 'abis/PolygonToken.json';

export enum ChainNames {
    Rinkeby = 'RINKEBY',
    Ethereum = 'ETHEREUM',
    Ropsten = 'ROPSTEN',
    BscTestnet = 'BSC_TESTNET',
    Bsc = 'BSC',
    Polygon = 'POLYGON',
    PolygonTestnet = 'POLYGON_TESTNET',
}
export interface ChainAddresses {
    staking?: string;
    lime: string;
    claim?: string;
    bab?: string;
    bae?: string;
    tknB?: string;
    tknE?: string;
    airdrop?: string;
    usdc?: string;
    busd?: string;
}
export interface Chain {
    name: string;
    label: string;
    chainId: number;
    url: string;
    explorer: string;
    address: ChainAddresses;
    icon: string;
    color: string;
    coin: string;
    airdrop?: string;
}

export const chains: Record<ChainNames, Chain> = {
    RINKEBY: {
        name: 'Rinkeby Testnet',
        label: 'Rinkeby Testnet',
        chainId: 4,
        url: 'https://rinkeby.infura.io/v3/e8031527a4504619b8581b444ccca4e3',
        explorer: 'https://rinkeby.etherscan.io/tx/',
        address: {
            staking: '0xF8a23247cCA30e717Ef159D0B8e5dfA76236A05C',
            lime: '0x10ad0a8a482bd9027ed0ce119d8ff6199dd14039',
        },
        icon: EthIcon,
        color: '#B5B5B5',
        coin: COIN_ETH,
    },
    ETHEREUM: {
        name: 'Ethereum Mainnet',
        label: 'Ethereum',
        chainId: 1,
        url: 'https://rpc.ankr.com/eth',
        explorer: 'https://etherscan.io/tx/',
        address: {
            staking: '0x2De3B4B4487fcb1C4499640545fF8f2C72888275',
            lime: '0x9D0B65a76274645B29e4cc41B8f23081fA09f4A3',
            airdrop: '0xd508933559d014988ecc3ce8437ed965dd0235a6',
            bab: '0xECab05bb8215023796BDA1a33514C44a6b80777d',
            bae: '0xECab05bb8215023796BDA1a33514C44a6b80777d',
            tknB: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
            tknE: '0x9d0b65a76274645b29e4cc41b8f23081fa09f4a3',
            usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        },
        icon: EthIcon,
        color: '#B5B5B5',
        coin: COIN_ETH,
    },
    ROPSTEN: {
        name: 'Ropsten Testnet',
        label: 'Ropsten Testnet',
        chainId: 3,
        url: 'https://rpc.ankr.com/eth_ropsten',
        explorer: 'https://ropsten.etherscan.io/tx/',
        address: {
            staking: '0xe0dec2f9e89126237bcfee0937a9082a133a88a9',
            lime: '0x98b3c321f619d8d87c54a46b7ce9495e43d6f7ef',
            bab: '0x54b69e497437cD6f8Fb35AE5Fb8699BAEE23b06F',
            bae: '0x4F1281fEf2Bbd433D8E12Fa6cfc9cF4BACb351cb',
            tknB: '0xbfAaF004504bcc139B354cf8F12f5b830CdD0DE6',
            tknE: '0x98B3C321F619d8D87c54a46b7ce9495E43d6F7Ef',
        },
        icon: EthIcon,
        color: '#B5B5B5',
        coin: COIN_ETH,
    },
    BSC_TESTNET: {
        name: 'BNB Testnet',
        label: 'BNB Chain Testnet',
        chainId: 97,
        url: 'https://testnet.bsc.imem.app/gateway/',
        explorer: 'https://testnet.bscscan.com/tx/',
        address: {
            lime: '0xbfAaF004504bcc139B354cf8F12f5b830CdD0DE6',
            claim: '0xb1d782DA8b2F8175B5E6aDA57eDa1854f6cD4F2f',
        },
        icon: BnbIcon,
        color: '#F0B90B',
        coin: COIN_BNB,
        airdrop: '0x9821Fb30a85975Bc59749d5639aB8EE58B405C51',
    },
    BSC: {
        name: 'BNB Mainnet',
        chainId: 56,
        label: 'BNB Chain',
        url: 'https://bsc-mainnet.core.chainstack.com/a5a47b68c4174a4680e3627dc84a14d0', 
        explorer: 'https://bscscan.com/tx/',
        address: {
            lime: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
            claim: '0x791f04ffb4bf4a31715dd25a292edc4ac9b58d5a',
            busd: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        },
        icon: BnbIcon,
        color: '#F0B90B',
        coin: COIN_BNB,
        airdrop: '0x3c33927B5c19B9B3AC0e670A49a217f95AC94f7F',
    },
    POLYGON: {
        name: 'Polygon Mainnet',
        chainId: 137,
        label: 'Polygon',
        url: 'https://polygon-mainnet.core.chainstack.com/496a3fe5374cd14b86fa713f57dce52a',
        explorer: 'https://polygonscan.com/tx/',
        address: {
            lime: '0x7f67639Ffc8C93dD558d452b8920b28815638c44',
            usdc: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        },
        icon: MaticIcon,
        color: '#8853DF',
        coin: COIN_MATIC,
        airdrop: '0x4b4042CdAaa38f89BFe7bfD2f5AfB037C684fB41',
    },
    POLYGON_TESTNET: {
        name: 'Mumbai',
        chainId: 80001,
        label: 'Polygon Mumbai',
        url: 'https://rpc-mumbai.maticvigil.com',
        explorer: 'https://mumbai.polygonscan.com/tx/',
        address: {
            lime: '0xf7909CBC21E95000E3d0e14004aADdea2bb1796d',
        },
        icon: MaticIcon,
        color: '#8853DF',
        coin: COIN_MATIC,
        airdrop: '0x59CF9E00e243E87D24c61B824Bda73B2255AAE7b',
    },
};

export const CHAINS_URLS = Object.fromEntries(Object.values(chains).map((chain) => [chain.chainId, chain.url]))

export const CHAINS_ABI = {
    [chains.BSC.chainId]: BnbTokenAbi,
    [chains.BSC_TESTNET.chainId]: BnbTokenAbi,
    [chains.POLYGON.chainId]: PolygonTokenAbi,
    [chains.POLYGON_TESTNET.chainId]: PolygonTokenAbi,
}

export const CHAINS_BY_ID = Object.fromEntries(Object.values(chains).map((chain: Chain) => [chain.chainId, chain]))

export const CHAINS_CONTRACTS = Object.fromEntries(Object.keys(CHAINS_ABI).map((id) => [
    id,
    new Contract(
        CHAINS_BY_ID[id].address.lime,
        CHAINS_ABI[id as unknown as number],
        new providers.JsonRpcProvider(CHAINS_BY_ID[id].url)
    ),
]))

export const CHAINS_PARAMS_BY_ID = Object.fromEntries(Object.values(chains).map((chain: Chain): [number, AddEthereumChainParameter] => [
    chain.chainId,
    {
        chainId: chain.chainId,
        chainName: chain.name,
        nativeCurrency: {
            name: chain.coin,
            symbol: chain.coin,
            decimals: 18
        },
        rpcUrls: [chain.url],
        blockExplorerUrls: [chain.explorer],
    }
]))

import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import Select from 'react-select';
import { selectStyles } from 'styles';
import { ReactComponent as Cancel } from 'assets/img/cancel.svg';
import { CatalogTag } from 'types';

interface ITagsSelector {
    allTags: CatalogTag[];
    setSelectedTags: any;
    selectedTags: CatalogTag[];
    title: string;
    isOnlyOne?: boolean;
}

const TagsSelector: FC<ITagsSelector> = ({
    allTags,
    setSelectedTags,
    selectedTags,
    title,
    isOnlyOne,
}) => {
    const options = allTags
        .filter((i) => !selectedTags.find((j) => j.id === i.id))
        .map((i) => {
            return { value: i.id, label: i.title };
        });

    const addTag = (value: any) => {
        const t = allTags.find((i) => i.id === value.value);
        let tmp;
        if (isOnlyOne) {
            tmp = [t];
        } else {
            tmp = [...selectedTags];
            tmp.push(t!);
        }
        setSelectedTags(tmp);
    };
    const delTag = (value: any) => {
        const tmp = selectedTags;
        selectedTags.splice(value, 1);
        setSelectedTags(tmp);
    };

    return (
        <div className={'channel-create-tags'}>
            <div className={'title'}>{title}</div>
            <div className={'select-tags'}>
                <div className={'select'}>
                    <Select
                        options={options}
                        onChange={(e) => {
                            addTag(e);
                        }}
                        styles={selectStyles}
                        placeholder={'Start typing'}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                        }}
                        maxMenuHeight={150}
                        noOptionsMessage={() =>
                            'Connection error, please refresh page'
                        }
                    />
                </div>
                <div className={'tags'}>
                    {selectedTags.map((i, index) => (
                        <div key={index} className={'tag'}>
                            {i.title} <Cancel onClick={() => delTag(index)} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TagsSelector;

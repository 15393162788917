import { FC, Fragment, useState } from 'react';
import './style.scss';
import 'components/Service/style.scss';
import person from 'assets/img/catalog/person.svg';
import { getTelegramChannelDeepLink, getTelegramChannelLink, isMobile, openNewTab} from 'utils';
import { Button } from 'components/form/Button';
import { useDispatch, useSelector } from 'react-redux';
import { cancelCampaign } from 'modules/Catalog';
import { CARD_STATUS } from '../CatalogCard/cardStatus';
import { AppStateType } from 'store';
import verified from 'assets/img/catalog/verified.svg';
import { ChannelCompProps } from './types';

const Channel: FC<ChannelCompProps> = ({ channel, previewSend }) => {
    const copy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
        navigator.clipboard.writeText(
            getTelegramChannelLink(channel?.channel.shortname)
        );
    };

    console.log(channel?.tags.length);

    const dispatch = useDispatch();

    const [isCopied, setIsCopied] = useState<boolean>(false);

    const openLink = () => {
        if (channel?.channel.shortname)
            window.open(getTelegramChannelDeepLink(channel?.channel.shortname), '_self');
    };

    const createLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoadingCreate
    );
    const error = useSelector(
        (state: AppStateType) => state.catalog.error[state.catalog.currentMode]
    );

    const linkButtons = (
        <div className={'buttons'}>
            <Button isBlueFill onClick={() => openLink()}>
                Open in Telegram
            </Button>
            <Button isBlue onClick={() => copy()}>
                {isCopied ? (
                    <span className={'copied'}>Copied</span>
                ) : (
                    'Copy link'
                )}
            </Button>
        </div>
    );

    return (
        <div className={'channel'}>
            <div className={'title'}>Channel page</div>

            <div className={'content'}>
                <div className={'block'}>
                    <div className={'image-holder'}>
                        <img className={'image'} src={channel?.channel.photo} />
                    </div>
                    {isMobile() ? null : linkButtons}
                </div>

                <div className={'block'}>
                    <div className={'name'}>
                        {channel?.channel.title}{' '}
                        {channel?.channel.verified && <img src={verified} />}
                    </div>
                    <div className={'rating'}>
                        <div>
                            {channel?.channel.membersCount}
                            <img src={person} alt={'persons'} />
                        </div>
                    </div>
                    {channel?.category ? (
                        <div className={'type-wrapper'}>
                            <div className={'type'}>
                                {channel?.category.title}
                            </div>
                            {channel?.channel.shortname && (
                                <div className={'shortname'}>
                                    @{channel?.channel.shortname}
                                </div>
                            )}
                        </div>
                    ) : null}
                    {channel?.channel.description && (
                        <div className={'description'}>
                            {channel?.channel.description}
                        </div>
                    )}
                    <div className={'language'}>
                        <span>Language:</span> {channel?.language?.title}
                    </div>
                    {channel?.tags.length > 0 && (
                        <div className={'tags'}>
                            <span>Tags:</span>{' '}
                            {channel?.tags.map((i, index) => (
                                <div className={'tag'} key={index}>
                                    {i.title}
                                </div>
                            ))}
                        </div>
                    )}

                    <div className={'publish'}>
                        {channel?.status === CARD_STATUS.PUBLISHED ? (
                            <Button
                                onClick={() => {
                                    dispatch(cancelCampaign(channel!.id));
                                }}
                                isRedFill
                            >
                                Unpublish
                            </Button>
                        ) : null}
                        {previewSend !== undefined ? (
                            <Fragment>
                                <Button
                                    onClick={() => {
                                        previewSend();
                                    }}
                                    isGreenFill
                                >
                                    {createLoading ? (
                                        <div className={'loading-circle'} />
                                    ) : (
                                        'Set on review'
                                    )}
                                </Button>
                                {error ? (
                                    <div className={'error'}>
                                        {error!.message}
                                    </div>
                                ) : null}
                            </Fragment>
                        ) : null}
                    </div>

                    {isMobile() ? linkButtons : null}
                </div>
            </div>
        </div>
    );
};

export default Channel;

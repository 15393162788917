import { FC } from 'react'
import classNames from 'classnames'

import { ReactComponent as SuccessCircle } from 'assets/img/success-circle.svg'
import { ReactComponent as DeclinedCircle } from 'assets/img/declined-circle.svg'
import { ReactComponent as LoaderCircle } from 'assets/img/loader-circle.svg'

export const Status: FC<any> = ({ loading, error, success }) => {
    if (loading) {
        return <div className={classNames('loader')}><LoaderCircle /></div>
    }

    if (success) {
        return <SuccessCircle />
    }

    if (error) {
        return <DeclinedCircle />
    }

    return null
}
import { ReactNode } from 'react';
import { BigNumber } from 'ethers';

declare global {
    interface Window {
        AdobeAn: any;
        createjs: any;
        ethereum: any;
        web3: any;
        logEvent: (eventName: string, params?: Record<string, unknown>) => void;
        setUserId: (userId: string) => void;
    }
}

export interface StakeType {
    start: BigNumber;
    period: BigNumber;
    LIMEAmount: BigNumber;
    USDTAmount: BigNumber;
}

export interface SupplyDetails {
    value: string;
    percent: number;
}

export interface ChainMetricsDetails {
    totalSupply: SupplyDetails;
    holdAndStock: SupplyDetails;
    circulatingSupply: SupplyDetails;
    DEXLiquidity: SupplyDetails;
}

export interface TokenMetrics {
    distribution: {
        eth: ChainMetricsDetails;
        bsc: ChainMetricsDetails;
        polygon: ChainMetricsDetails;
        combined: ChainMetricsDetails;
    };
    market: {
        price: number;
        capitalization: number;
        fullyDilutedCapitalization: number;
        tradingVolume24h: number;
    };
}

export interface Channel {
    title: string;
    photo: string;
    description: string;
    chatType: string;
    rating: number;
    membersCount: number;
    createdAt: string;
    shortname: string;
    id: number;
    verified?: boolean;
}

export interface Campaign {
    id: number;
    channel: Channel;
    tags: CatalogTag[];
    status?: string;
    language?: { id: number; nativeTitle: string; title: string };
    countries?: CatalogCategory;
    category: CatalogCampaignCategory;
    invalidReason: string;
    createdAt: string;
}

export interface TelegramUser {
    id: number;
    auth_date: number;
    hash: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    photo_url?: string;
}

export interface CatalogCategory {
    category: {
        id: number;
        title: string;
    };
    total?: number;
}

export interface CatalogTag {
    id: number;
    title: string;
}

export interface CatalogLanguage {
    id: number;
    nativeTitle: string;
    title: string;
}

export type CatalogCountry = {
    id: number;
    title: string;
};

export interface CatalogCampaignCategory {
    id: number;
    title: string;
}

export interface TelegramButtonState {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    position: string;
    hover: boolean;
}

export interface Error {
    code: string;
    status: string;
    message?: string;
}

export interface CatalogAllChannelsItem {
    category: {
        id: number;
        title: string;
    };
    total: number;
    items: Array<Campaign>;
}

export enum PLATFORMS_ENUM {
    UNSET = 0,
    MAC,
    WINDOWS_32,
    WINDOWS_64,
    LINUX_64,
}

export type PlatformLinks = {
    label: string
    link: string
    arch?: string
    additionalLinks?: Array<{ label: string, link: string }>
}

/**
 * # Possible blockchain network purpose
 *
 * **Testnet** stands for toy networks, like Ropsten, Mumbai, etc.
 *
 * **Mainnet** stands for real networks, like Ethereum, BSC, etc.
 */
export enum NetworkPurpose {
    Testnet = 'TESTNET',
    Mainnet = 'MAINNET',
}

import { ChainNames } from 'config'
import { FC } from 'react'
import { CHAINS_DATA } from '../../constants'

import { SwapStep } from '../hooks'

type SubmitButtonProps = {
    swap: () => void
    approve: () => void
    wallet: string
    swapStep: SwapStep
    error?: string
    loading: boolean
    shouldSwitchNetwork: boolean
    switchNetwork: () => void
    fromChain: ChainNames.Bsc | ChainNames.Polygon
    connectWallet: () => void
}

export const SubmitButton: FC<SubmitButtonProps> = ({
    swap,
    approve,
    wallet,
    swapStep,
    error,
    loading,
    shouldSwitchNetwork,
    switchNetwork,
    fromChain,
    connectWallet,
}) => {
    if (!wallet) {
        return <button onClick={connectWallet}>Connect Wallet</button>
    }

    if (swapStep === SwapStep.NeedAprove) {
        if (loading) {
            return (
                <button disabled>
                    Approving...
                </button>
            )
        }

        return (
            <button onClick={approve}>
                Approve
            </button>
        )
    }

    if (loading) {
        return <button disabled>Loading...</button>
    }

    if (error) {
        return <button disabled>{error}</button>
    }

    if (shouldSwitchNetwork) {
        return (
            <button onClick={switchNetwork}>
                Switch network to {CHAINS_DATA[fromChain].name}
            </button>
        )
    }

    if (swapStep === SwapStep.ReadyToSwap || swapStep === SwapStep.Declined) {
        return (
            <button onClick={swap}>
                Swap
            </button>
        )
    }

    return (
        <button disabled>
            Swap
        </button>
    )
}
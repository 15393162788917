import { FC } from 'react';
import './style.scss';
import notFoundPic from 'assets/img/404.svg';

const NotFound: FC = () => {
    return (
        <div className={'not-found page-bg'}>
            <img className={'pic'} src={notFoundPic} alt={'404'} />
            <div className={'text'}>
                Page you were looking for may have been removed or never
                existed. Sorry about it.
            </div>
        </div>
    );
};

export default NotFound;

import { userAxios } from './instance';

export const getCatalogCategories = (
    chatType: string = 'CHANNEL',
    languageId: number | null
) => {
    return userAxios
        .get(`/promotion/catalog`, {
            params: {
                chatType: chatType.toUpperCase(),
                languageId: languageId,
            },
        })
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getCategories = (chatType: string = 'CHANNEL') => {
    return userAxios
        .get(`/categories`, {
            params: {
                chatType: chatType,
            },
        })
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getAllCatalog = (
    cursor: string,
    size: number,
    chatType: string = 'CHANNEL',
    languageId: number | null
) => {
    return userAxios
        .get(`/promotion/catalog/preview`, {
            params: {
                categorySize: size,
                chatType: chatType.toUpperCase(),
                cursor: cursor,
                languageId: languageId,
            },
        })
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getCatalogByCategory = (
    id: number,
    size: number,
    chatType: string = 'CHANNEL',
    languageId: number | null
) => {
    return userAxios
        .get(`/promotion/catalog/${id}`, {
            params: {
                size: size,
                chatType: chatType.toUpperCase(),
                languageId: languageId,
            },
        })
        .then((res) => res.data.payload.items)
        .catch((e) => {
            console.log(e);
        });
};
export const getUserCampaigns = (chatType: string = 'CHANNEL') => {
    return userAxios
        .get(`/promotion/campaigns`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const createCampaign = (
    name: string,
    language: number,
    category: number,
    tagsIds: Array<number>,
    countryIds: Array<number>
) => {
    return userAxios
        .post(`/promotion/campaigns`, {
            shortnameOrId: name,
            languageId: language,
            categoryId: category,
            tagIds: tagsIds,
            countryIds: countryIds,
        })
        .then((res) => res.data)
        .catch((e) => {
            console.log(e);
        });
};
export const cancelCampaign = (id: number) => {
    return userAxios
        .post(`/promotion/campaigns/${id}/cancel`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const restoreCampaign = (id: number) => {
    return userAxios
        .post(`/promotion/campaigns/${id}/restore`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getTags = () => {
    return userAxios
        .get(`/tags`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getLanguages = () => {
    return userAxios
        .get(`/languages`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const getCountries = () => {
    return userAxios
        .get(`/countries`)
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};
export const checkChannel = (name: string) => {
    return userAxios
        .get(`/telegram?username=${name}`, {})
        .then((res) => res.data)
        .catch((e) => {
            console.log(e);
            return e;
        });
};
export const getPopular = (
    cursor: string,
    chatType: string = 'CHANNEL',
    languageId: number | null
) => {
    return userAxios
        .get(`/promotion/catalog/all`, {
            params: {
                cursor: cursor,
                chatType: chatType.toUpperCase(),
                languageId: languageId,
            },
        })
        .then((res) => res.data.payload)
        .catch((e) => {
            console.log(e);
        });
};

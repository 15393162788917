import { FC } from 'react'
import { Tooltip } from 'components/Tooltip'

export const Footer: FC<any> = ({ needToApproveTokens, error }) => {
    if (error) {
        return (
            <div className="airdrop-footer airdrop-footer-error">
                {error}
            </div>
        )
    }

    if (needToApproveTokens) {
        return (
            <Tooltip
                side="right"
                sideOffset={10}
                text={
                    <div className="airdrop-approve-notion">
                        <strong>Approval</strong> is a one-time transaction for tokens,
                        required before appealing to a smart contract.
                        With this operation, you give permission to make
                        transactions with your token via the DEX protocol
                        using smart contracts.
                        <br />
                        As this operation takes place in the blockchain, the Gas fee will be withheld.
                        <br />
                        <br />
                        <strong>
                            You will not need to re-approve
                            the current token.
                        </strong>
                    </div>
                }
            >
                <div className="airdrop-footer">
                    What does approval mean?
                </div>
            </Tooltip>
        )
    }

    return null
}
import { useState, useCallback } from 'react';
import { utils, BigNumber } from 'ethers'

import { AirdropFormEntry } from '../../types';

export const INITIAL_ENTRY = {
    address: '',
    amount: '',
    invalid: false,
}

const validateAddress = (address: string) => {
    if (address === '') {
        return 'empty'
    }

    return utils.isAddress(address)
}

const validateAmount = (amount: string) => {
    if (!amount || amount === '') {
        return 'empty'
    }

    const [,decimal] = amount.split('.')

    if (decimal && decimal.length > 8) {
        return false
    }

    try {
        const parsedAmount = utils.parseEther(amount)
        utils.formatEther(parsedAmount)

        return true
    } catch (err) {
        return false
    }
}

const getZeroAmount = () => utils.parseEther('0')

export const useAirdrop = () => {
    const [entries, setEntries] = useState<Record<string, AirdropFormEntry>>({ '0': INITIAL_ENTRY });
    const [totalTokenAmount, setTotalTokenAmount] = useState<BigNumber>(getZeroAmount());
    const [commonAmount, setCommonAmount] = useState(false)
    const [totalAddresses, setTotalAddresses] = useState(0)

    const reset = useCallback(() => {
        setTotalAddresses(0)
        setCommonAmount(false)
        setTotalTokenAmount(getZeroAmount())
        setEntries({ '0': INITIAL_ENTRY })
    }, [])

    const validateAndUpdateEntries = useCallback((collection: AirdropFormEntry[], forceCommon?: boolean) => {
        const commonFlag = forceCommon !== undefined ? forceCommon : commonAmount

        const newEntries: [number, AirdropFormEntry][] = []
        let newTotalAmount = getZeroAmount()
        let newTotalAddresses = 0
        let firstAmount = getZeroAmount()
        let firstAmountStr = ''

        for (const [i, { address, amount }] of collection.entries()) {
            const isAddressValid = validateAddress(address)
            const isAmountValid = validateAmount(amount)
            let bigNumber
            if (isAddressValid === true && isAmountValid === true) {
                bigNumber = utils.parseEther(amount)
            }

            if (commonFlag && i === 0 && bigNumber) {
                firstAmount = bigNumber
                firstAmountStr = amount
            }

            if (firstAmountStr) {
                newTotalAmount = newTotalAmount.add(firstAmount)
                newTotalAddresses += 1

                newEntries.push([i, {
                    address,
                    amount: firstAmountStr,
                    bigNumber: firstAmount,
                    isAddressValid,
                    isAmountValid: true,
                }])
            } else {
                if (isAddressValid === true && isAmountValid === true) {
                    bigNumber = utils.parseEther(amount)
                    newTotalAmount = newTotalAmount.add(bigNumber)
                    newTotalAddresses += 1
                }

                newEntries.push([i, {
                    address,
                    amount,
                    bigNumber,
                    isAddressValid,
                    isAmountValid,
                }])
            }
        }

        if (!newTotalAmount.eq(totalTokenAmount)) {
            setTotalTokenAmount(newTotalAmount)
        }

        setTotalAddresses(newTotalAddresses)
        setEntries(Object.fromEntries(newEntries))
    }, [setEntries, totalTokenAmount, setTotalTokenAmount, commonAmount])

    const updateEntry = useCallback((entry: AirdropFormEntry, index: number) => {
        if (index !== 0) {
            setCommonAmount(false)
            validateAndUpdateEntries(Object.values({ ...entries, [index]: entry }), false)
        } else {
            validateAndUpdateEntries(Object.values({ ...entries, [index]: entry }))
        }
    }, [entries, validateAndUpdateEntries])

    const addEntry = useCallback(() => {
        validateAndUpdateEntries([...Object.values(entries), INITIAL_ENTRY])
    }, [entries, validateAndUpdateEntries])

    const removeEntry = useCallback((index: number) => {
        const { ...prevEntries } = entries
        delete prevEntries[index]

        validateAndUpdateEntries(Object.values(prevEntries))
    }, [entries, validateAndUpdateEntries])

    const setCommonAmountHandle = useCallback((checked: boolean) => {
        setCommonAmount(checked)

        if (checked) {
            validateAndUpdateEntries(Object.values(entries), true)
        }
    }, [entries, validateAndUpdateEntries, setCommonAmount])

    return {
        entries,
        totalTokenAmount,
        totalAddresses,
        commonAmount,
        updateEntry,
        addEntry,
        validateAndUpdateEntries,
        removeEntry,
        setCommonAmountHandle,
        reset,
    }
}

const formatTextToEntries = (rows: string[]) => {
    const entries = []

    for (const text of rows) {
        if (!text) {
            continue
        }

        const [address, amount] = text.split(/[;,]/);

        entries.push({ address, amount })
    }

    return entries
};

export const useCSVForm = (validateAndUpdateEntries: any, maxRecipients: number) => {
    const [csvText, setCsvText] = useState<string>('');
    const [csvError, setCsvError] = useState<string[] | null>();
    const [csvRecipientsError, setCsvRecipientsError] = useState<string | null>();
    const [csvFileName, setCsvFileName] = useState<string>('');

    const resetFile = useCallback(() => {
        setCsvText('')
        setCsvError(null)
        setCsvFileName('')
    }, [])

    const processCsvFile = useCallback(async (file: File) => {
        resetFile()

        setCsvFileName(file.name)
        const rawtext = await file.text()
        const [header, ...rows] = rawtext.split(/\r?\n/);

        if (header !== 'Address;Amount (Tokens)' && header !== 'Address,Amount (Tokens)') {
            setCsvError([])

            return
        }

        const validRows = []
        const invalidRows = []

        for (const row of rows) {
            if (row === '') {
                continue
            }

            if (!/^0x[a-fA-F0-9]{40}[;,][0-9]*([.][0-9]*)?$/g.test(row)) {
                invalidRows.push(row)
            } else {
                validRows.push(row)
            }
        }

        // if no valid rows, show common error, do not fill text area
        if (validRows.length === 0) {
            setCsvError([])

            return
        }

        if (rows.filter(row => row !== '').length > maxRecipients) {
            setCsvRecipientsError(`Maximum number of recipients ${maxRecipients}`)
        } else {
            setCsvRecipientsError(undefined)
        }

        if (invalidRows.length) {   // show in error invalid rows and fill text area with valid items
            setCsvError(invalidRows)
        }

        setCsvText(validRows.join('\n'));
        validateAndUpdateEntries(formatTextToEntries(rows));
    }, [setCsvFileName, setCsvText, validateAndUpdateEntries, resetFile, maxRecipients]);

    const updateEntries = useCallback((e: any) => {
        const text = e.target.value

        setCsvText(text);
        const rows = text.split(/\r?\n/) as string[]

        if (rows.filter(row => row !== '').length > maxRecipients) {
            setCsvRecipientsError(`Maximum number of recipients ${maxRecipients}`)
        } else {
            setCsvRecipientsError(undefined)
        }

        validateAndUpdateEntries(formatTextToEntries(rows));
    }, [setCsvText, validateAndUpdateEntries, maxRecipients])

    return {
        csvText,
        csvError,
        csvFileName,
        csvRecipientsError,
        resetFile,
        updateEntries,
        processCsvFile,
    }
}
import { styled } from '@stitches/react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

export const CheckboxRoot = styled(CheckboxPrimitive.Root, {
    all: 'unset',
    width: 20,
    height: 20,
    borderRadius: 3,
    border: '2px solid #006AED',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',

    '&[data-state=checked]': {
        background: '#006AED',
    }
})

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {})

import { FC, Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CollaborationsList from '../components/Landing/Collaborations/CollaborationsList';

const CollaborationsPage: FC = () => {
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <CollaborationsList />
                <div className={'footer-wrapper'}>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
};

export default CollaborationsPage;

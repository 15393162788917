import React from 'react'
import { styled, keyframes } from '@stitches/react'
import { violet, blackA, mauve, green } from '@radix-ui/colors'
import { Cross2Icon } from '@radix-ui/react-icons'
import * as DialogPrimitive from '@radix-ui/react-dialog'

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
})

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const StyledOverlay = styled(DialogPrimitive.Overlay, {
    background: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    zIndex: 1001,
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
})
export const StyledContent = styled(DialogPrimitive.Content, {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '700px',
    maxHeight: '85vh',
    padding: 40,
    zIndex: 1002,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&:focus': { outline: 'none' },
})

export const StyledTitle = styled(DialogPrimitive.Title, {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '2.5rem',
})
export const StyledClose = styled('div', {
    position: 'absolute',
    top: '28px',
    right: '28px',
    cursor: 'pointer',
})

export const StyledDescription = styled(DialogPrimitive.Description, {
    margin: '10px 0 20px',
    fontSize: 15,
    lineHeight: 1.5,
})

// Exports
export const Dialog = DialogPrimitive.Root
export const DialogTrigger = DialogPrimitive.Trigger
export const DialogDescription = StyledDescription

import React, { FC } from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import {
    StyledContent,
    StyledIcon,
    StyledItem,
    StyledTrigger,
    StyledViewport,
} from './style'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { SelectProps, SelectItem } from './types'

const SelectElement: React.FC<SelectItem> = ({ value, render }) => (
    <StyledItem
        key={`select-value-${value}`}
        value={value}
    >
        <SelectPrimitive.ItemText>
            {render}
        </SelectPrimitive.ItemText>
    </StyledItem>
)

export const Select: FC<SelectProps> = ({ value, onChange, items, renderValue }) => (
    <SelectPrimitive.Root value={value} onValueChange={onChange}>
        <StyledTrigger aria-label="Blockchain">
            <SelectPrimitive.Value placeholder="Select">
                {renderValue ? renderValue(value) : null}
            </SelectPrimitive.Value>
            <StyledIcon>
                <ChevronDownIcon />
            </StyledIcon>
        </StyledTrigger>
        <SelectPrimitive.Portal>
            <StyledContent>
                <StyledViewport>
                    <SelectPrimitive.Group>
                        {items?.map(SelectElement)}
                    </SelectPrimitive.Group>
                </StyledViewport>
            </StyledContent>
        </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
)

export default Select

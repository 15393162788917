import { styled } from 'styles';

export const Wrapper = styled('div', {
    height: '100%',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.confirm': {
        maxWidth: '90%',
        background: 'white',
        border: '1px solid rgba(147, 170, 200, 0.2)',
        boxSizing: 'border-box',
        boxShadow: '12px 12px 70px rgba(17, 51, 102, 0.1)',
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 24,
        position: 'relative',

        '.close': {
            position: 'absolute',
            right: 20,
            top: 20,
            cursor: 'pointer',
        },

        '.title': {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 30,
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '-0.015em',
            marginBottom: 40,
        },
        '.text': {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 26,
            marginBottom: 24,
            textAlign: 'center',
        },
        '.button': {
            width: 140,
        },
    },
});

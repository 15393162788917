import { Link } from 'components/form/Link'

type DownloadLinkProps = {
    href: string
    additional?: boolean
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({ href, additional, children }) =>
    <Link
        className={additional ? 'additional-button download-button' : 'download-button'}
        isBlueFill={!additional}
        href={href}
        target="_blank"
        rel="noreferrer"
    >
        {children}
    </Link>
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import './style.scss';
import { useCanvas, useOnScreen } from 'hooks';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { InfoBlockProps } from './types';

const InfoBlock: FC<InfoBlockProps> = ({ isRightPic, item }) => {
    const history = useHistory();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const blockRef = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(blockRef, 0.3);
    const [animationStarted, setAnimationStarted] = useState<boolean>(false);
    useEffect(() => {
        if (isVisible) setAnimationStarted(true);
    }, [isVisible]);

    useCanvas(
        item.anim_name!,
        item.anim_id!,
        canvasRef,
        animationRef,
        overlayRef,
        isVisible
    );
    return (
        <div ref={blockRef} className={'info-block__container'}>
            {isRightPic ? (
                <Fragment>
                    <div
                        className={classNames({
                            'info-block__text-block': true,
                            right: true,
                            animate: animationStarted,
                        })}
                    >
                        <div className={'info-block__title'}>{item.title}</div>
                        <div className={'info-block__text'}>{item.text}</div>
                        <div className={'info-block__buttons'}>
                            {item?.buttons(history)}
                        </div>
                    </div>

                    <div className={'info-block__image-block'}>
                        <img
                            className={'image-left back-image'}
                            src={item.bg}
                            alt={'bg'}
                        />
                        <div className={'info-block__pic-holder'}>
                            <div ref={animationRef}>
                                <canvas ref={canvasRef} />
                                <div ref={overlayRef}></div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className={'info-block__image-block'}>
                        <img
                            className={'image-right back-image'}
                            src={item.bg}
                            alt={'bg'}
                        />
                        <div className={'info-block__pic-holder'}>
                            <div ref={animationRef}>
                                <canvas ref={canvasRef} />
                                <div ref={overlayRef}></div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames({
                            'info-block__text-block': true,
                            left: true,
                            animate: animationStarted,
                        })}
                    >
                        <div className={'info-block__title'}>{item.title}</div>
                        <div className={'info-block__text'}>{item.text}</div>
                        <div className={'info-block__buttons'}>
                            {item?.buttons(history)}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default InfoBlock;

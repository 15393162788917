import { providers, utils } from 'ethers';
import { Dispatch } from 'react';
import { resetWallet } from './Staking';
import { AppStateType } from 'store';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { stakeConfig } from 'stakeConfig';
import { bridgeConfig } from 'bridgeConfig';
import { chains, CHAINS_BY_ID } from 'config/chains';

const SET_WALLET = 'Services/SET_WALLET';
const SET_PROVIDER = 'Services/SET_PROVIDER';
const SET_WC_PROVIDER = 'Services/SET_WC_PROVIDER';
const SET_SIGNER = 'Services/SET_SIGNER';
const SET_CONNECT_TYPE = 'Services/SET_CONNECT_TYPE';
const SET_CONNECT_URI = 'Services/SET_CONNECT_URI';
const RESET = 'Services/RESET';
const SET_ERROR = 'Services/SET_ERROR';
const CHAIN_ID_UPDATE = 'Services/CHAIN_ID_UPDATE';
const TARGET_CHAIN_ID_UPDATE = 'Services/TARGET_CHAIN_ID_UPDATE';

export enum WALLET_TYPE {
    METAMASK = 'metamask',
    WALLET_CONNECT = 'walletConnect',
}

interface IServices {
    providerE: providers.Web3Provider | any | null;
    providerWc: WalletConnectProvider | any | null;
    signer: providers.JsonRpcSigner | null;
    wallet: '';
    connectType: WALLET_TYPE | null;
    walletConnectURI: string | null;
    error: string;
    targetChainId: number | null;
    currentChainId: number | null;
}

interface ISystemAction {
    type: string;
    payload: any;
}

const initialState: IServices = {
    providerE: null,
    providerWc: null,
    signer: null,
    wallet: '',
    connectType: null,
    walletConnectURI: null,
    error: '',
    targetChainId: null,
    currentChainId: null,
};

export const accountChange = (isWalletConnect: boolean) => {
    return async (dispatch: Dispatch<any>) => {
        if (isWalletConnect) {
            await dispatch(connectWalletconnect());
        } else {
            await dispatch(connectMetamask());
        }
    };
};

export const clickConnectMetamask = () => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        if (getState().services.connectType === WALLET_TYPE.WALLET_CONNECT) {
            dispatch({
                type: RESET,
            });
        }
        await dispatch(connectMetamask());
        if (window.ethereum) {
            (window.ethereum as any).on('chainChanged', async () => {
                await dispatch(connectMetamask());
            });
            (window.ethereum as any).on('accountsChanged', async () => {
                await dispatch(connectMetamask());
            });
        }

        // if (getState().services.wallet) await dispatch(loadContractInfo())
    };
};

const connectMetamask = () => {
    return async (dispatch: Dispatch<any>) => {
        let provider, signer, wallet;
        try {
            if (!window.ethereum) {
                throw new Error(
                    'Could not connect MetaMask. Error: Please set up MetaMask properly'
                );
            }

            await window.ethereum.request({
                method: 'eth_requestAccounts',
            });

            provider = new providers.Web3Provider(window.ethereum);

            signer = provider.getSigner();
            wallet = await signer.getAddress();
            await dispatch(updateChainId(provider));

            dispatch(setProvider(provider));
            dispatch(setWallet(wallet));
            dispatch(setSigner(signer));
            dispatch(setConnectType(WALLET_TYPE.METAMASK));
            dispatch({
                type: SET_ERROR,
                payload: undefined,
            });
        } catch (error) {
            if (error.code === 4001) {
                // User rejected the request.

                return
            }

            if (error.code === -32002) {
                // Request of type 'wallet_requestPermissions' already pending.
                dispatch({
                    type: SET_ERROR,
                    payload: error.message,
                });

                return
            }
            console.log('connectMetamask', error)
            dispatch({
                type: SET_ERROR,
                payload: error.message,
            });
            console.error(error);
        }
    };
};

export const clickConnectWalletconnect = (allowedChains?: number[]) => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        if (getState().services.connectType === WALLET_TYPE.METAMASK) {
            dispatch({
                type: RESET,
            });
        }
        await dispatch(connectWalletconnect(allowedChains));
        //if (getState().services.wallet) await dispatch(loadContractInfo())
    };
};

const DEFAULT_RPC = {
    4: stakeConfig.TESTNET.provider.url,
    1: stakeConfig.MAINNET.provider.url,
    3: bridgeConfig.TESTNET.providerE.url,
    56: bridgeConfig.MAINNET.providerB.url,
    97: bridgeConfig.TESTNET.providerB.url,
    [chains.POLYGON.chainId]: chains.POLYGON.url,
    [chains.POLYGON_TESTNET.chainId]: chains.POLYGON_TESTNET.url,
    [chains.BSC.chainId]: chains.BSC.url,
    [chains.BSC_TESTNET.chainId]: chains.BSC_TESTNET.url,
}

const generateRpc = (acc: any, chainId: number) => {
    return ({
        ...acc,
        [chainId]: CHAINS_BY_ID[chainId].url
    })
}

const connectWalletconnect = (allowedChains?: number[]) => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        let provider, signer, wallet;
        try {
            const targetChainId = getState().services.targetChainId

            const chainId = (allowedChains && allowedChains[0]) || getState().services.targetChainId! | 1
            const rpc = allowedChains
                ?  allowedChains.reduce(generateRpc, {})
                : DEFAULT_RPC

            console.log('WalletConnectProvider', {
                rpc,
                chainId,
                qrcode: true,
            })
            const wc = new WalletConnectProvider({
                rpc,
                chainId,
                qrcode: true,
            });

            wc.connector.on('display_uri', (err: any, payload: any) => {
                console.log('display_uri payload', payload)
                const uri = payload.params[0];
                dispatch(setWalletConnectURI(uri));
            });

            wc.on("disconnect", (code: number, reason: string) => {
                console.log('disconnect', code, reason);
            });

            await wc.enable();

            if (wc.chainId !== targetChainId) {
                wc.disconnect()

                return dispatch({
                    type: SET_ERROR,
                    payload: `Could not connect Walletconnect. Error: Wrong chain ${wc.chainId} ${targetChainId}`,
                });
            }

            wc.on('modal_closed', () => {
                console.log('QR Code Modal closed')
            })

            wc.on('close', (code: number, reason: string) => {
                console.log('walletconnect closed', code);
                dispatch({
                    type: RESET,
                });
            });

            provider = new providers.Web3Provider(wc);

            signer = provider.getSigner();
            wallet = await signer.getAddress();

            dispatch(setWcProvider(wc));
            dispatch(updateChainId(provider));
            dispatch({
                type: SET_ERROR,
                payload: undefined,
            });
        } catch (error) {
            if (error.message !== 'User closed modal') {
                dispatch({
                    type: SET_ERROR,
                    payload: 'Could not connect Walletconnect. Error: ' + error.message,
                });
            }

            dispatch(setWalletConnectURI());
            console.error('walletconnect', error);
        }
        dispatch(setProvider(provider));
        dispatch(setWallet(wallet));
        dispatch(setSigner(signer));
        dispatch(setConnectType(WALLET_TYPE.WALLET_CONNECT));
    };
};

const updateChainId = (provider: any) => {
    return async (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        const id = (await provider?.getNetwork())?.chainId || null;
        if (id !== getState().services.targetChainId && provider?.connection?.url !== 'metamask') {
            //ConfigData[currentNetwork].provider.chainId
            dispatch({
                type: SET_ERROR,
                payload: `You selected wrong network for this direction. Select in your provider and refresh the page`,
            });
        }

        dispatch({
            type: CHAIN_ID_UPDATE,
            payload: id,
        });
    };
};

export const setTargetChainId = (chainId: number | undefined) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: TARGET_CHAIN_ID_UPDATE,
            payload: chainId,
        });
    };
};

export const setWallet = (wallet: string | undefined) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_WALLET,
            payload: wallet,
        });
    };
};

export const setWcProvider = (provider: WalletConnectProvider | undefined) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_WC_PROVIDER,
            payload: provider,
        });
    };
};

export const setProvider = (provider: providers.Web3Provider | undefined) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_PROVIDER,
            payload: provider,
        });
    };
};

export const setSigner = (signer: providers.JsonRpcSigner | undefined) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_SIGNER,
            payload: signer,
        });
    };
};

export const reset = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: RESET,
        });
    };
}

export const setWalletConnectURI = (uri?: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CONNECT_URI,
            payload: uri,
        });
    };
};

export const setConnectType = (type: WALLET_TYPE) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_CONNECT_TYPE,
            payload: type,
        });
    };
};

export const resetWallets = () => {
    return (dispatch: Dispatch<any>, getState: () => AppStateType) => {
        if (getState().services?.connectType === WALLET_TYPE.WALLET_CONNECT)
            getState().services?.providerE?.provider?.disconnect();
        dispatch(resetWallet());
        dispatch({
            type: RESET,
        });
    };
};

const ServicesReducer = (
    state = initialState,
    action: ISystemAction
): IServices => {
    switch (action.type) {
        case SET_WALLET:
            return {
                ...state,
                wallet: action.payload,
            };

        case SET_PROVIDER:
            return {
                ...state,
                providerE: action.payload,
            };
        case SET_WC_PROVIDER:
            return {
                ...state,
                providerWc: action.payload,
            };
        case SET_SIGNER:
            return {
                ...state,
                signer: action.payload,
            };

        case SET_CONNECT_TYPE:
            return {
                ...state,
                connectType: action.payload,
            };

        case SET_CONNECT_URI:
            return {
                ...state,
                walletConnectURI: action.payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case CHAIN_ID_UPDATE:
            return {
                ...state,
                currentChainId: action.payload,
            };
        case TARGET_CHAIN_ID_UPDATE:
            return {
                ...state,
                targetChainId: action.payload,
            };

        case RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default ServicesReducer;

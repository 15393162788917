import { PLATFORMS_ENUM } from 'types';

export const getDevicePlatform = (): PLATFORMS_ENUM => {
    const device = navigator?.userAgentData?.platform || navigator?.platform;

    if (/mac/gi.test(device)) {
        return PLATFORMS_ENUM.MAC;
    }

    if (/win32/gi.test(device)) {
        return PLATFORMS_ENUM.WINDOWS_32;
    }

    if (/win64/gi.test(device)) {
        return PLATFORMS_ENUM.WINDOWS_64;
    }

    if (/linux/gi.test(device)) {
        return PLATFORMS_ENUM.LINUX_64;
    }

    return PLATFORMS_ENUM.UNSET
};

import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import { COIN_LIME } from 'config';
import classNames from 'classnames';
import tick from 'assets/img/tick-white.svg';
import { CheckoutPeriods } from './types';

interface ICheckoutButtons {
    buttons: CheckoutPeriods[];
    selectedIndex: number;
    setSelectedIndex: (index: number) => any;
}

const CheckoutButtons: FC<ICheckoutButtons> = ({
    buttons,
    selectedIndex,
    setSelectedIndex,
}) => {
    return (
        <div className={'checkout-buttons'}>
            {buttons.map((i, index) => (
                <div
                    onClick={() => setSelectedIndex(index)}
                    className={classNames({
                        period: true,
                        active: index === selectedIndex,
                    })}
                    key={index}
                >
                    <div className={'time'}>{i.time}</div>
                    <div className={'price'}> {i.price + ' ' + COIN_LIME}</div>
                    <div className={'selected'}>
                        <img src={tick} alt={'active'} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CheckoutButtons;

export const LINK_ANDROID = 'https://imem.app/download/android/apk';
export const LINK_APP_STORE =
    'https://apps.apple.com/us/app/ime-messenger/id1450480822';
export const LINK_GOOGLE_PLAY =
    'https://play.google.com/store/apps/details?id=com.iMe.android&hl';

export const LINK_TELEGRAM = 'https://t.me/ime_en';
export const LINK_PREMIUM_DEEPLINK = 'ime://premium/purchase';
export const LINK_REFERRAL_DEEPLINK = 'ime://referral/referral_complete';
export const LINK_TELEGRAM_CHANNEL = 'https://t.me/s/';
export const DEEPLINK_TELEGRAM_CHANNEL = 'tg://resolve?domain=';
export const LINK_IME_FAQ = 'https://imem.gitbook.io/ime-faq-eng/';
export const LINK_TWITTER = 'https://twitter.com/ImePlatform';
export const LINK_MEDIUM = 'https://imesmartplatform.medium.com';
export const LINK_YOUTUBE = 'https://www.youtube.com/c/iMeMessenger';
export const LINK_REDDIT = 'https://www.reddit.com/r/iMeSmartPlatform';
export const LINK_GITHUB = 'https://github.com/imemessenger/';

export const LINK_TELEGRAM_NEWS = 'https://t.me/ime_en';
export const LINK_TELEGRAM_RU_NEWS = 'https://t.me/ime_ru';
export const LINK_TELEGRAM_WALLET_BOT = 'https://t.me/iMeWalletBot';
export const LINK_TELEGRAM_DISCUSS_IME = 'https://t.me/ime_ai';
export const LINK_TELEGRAM_DISCUSS_LIME = 'https://t.me/iMeLime';
export const LINK_TELEGRAM_SUPPORT = 'https://t.me/imemessenger';
export const LINK_LIME_AUDIT =
    'https://imem.gitbook.io/ime-faq-eng/docs-and-terms/lime-audit';

export const LINK_LIME_ETHERSCAN =
    'https://etherscan.io/token/0x9d0b65a76274645b29e4cc41b8f23081fa09f4a3';
export const LINK_LIME_BSCSCAN =
    'https://bscscan.com/token/0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd';
export const LINK_LIME_COINMARKET =
    'https://coinmarketcap.com/currencies/ime-lab';
export const LINK_LIME_GECKO = 'https://www.coingecko.com/en/coins/ime-lab';
export const LINK_LIME_UNISWAP =
    'https://app.uniswap.org/#/swap?use=V2&outputCurrency=0x9D0B65a76274645B29e4cc41B8f23081fA09f4A3';
export const LINK_LIME_PANCAKE =
    'https://pancakeswap.finance/info/token/0x7bc75e291e656e8658d66be1cc8154a3769a35dd';
export const LINK_LIME_ASCENDEX =
    'https://ascendex.com/en/register?inviteCode=U4AP5CPWD';

export const LINK_CEX_GATE =
    'https://www.gate.io/signup/VgARAwgO?ref_type=103';
export const LINK_CEX_ASCENDEX =
    'https://ascendex.com/ru/register?inviteCode=WTDY9CB5';
export const LINK_CEX_BINGX =
    'https://bingx.com/invite/MUKOQQ';
export const LINK_CEX_BITGET =
    'https://www.bitget.com/';
export const LINK_CEX_COINSTORE =
    'https://www.coinstore.com/';
export const LINK_CEX_COINW =
    'https://www.coinw.com/frontweb/invitePublicity?r=2731320&language=en_US';
export const LINK_CEX_POLONIEX =
    'https://poloniex.com/signup?c=DM56MKXD&r=%2Freferral%2Ftasks';
export const LINK_CEX_XT =
    'https://www.xt.com/en';
export const LINK_CEX_MEXC =
    'https://www.mexc.com/register?inviteCode=12AwcJ';
export const LINK_CEX_TAPBIT =
    'https://www.tapbit.com/auth/VDDGHSK';



export const LINK_LIME_GATE_ETH = 'https://www.gate.io/trade/LIME_ETH';
export const LINK_LIME_GATE_USDT = 'https://www.gate.io/en/trade/LIME_USDT';
export const LINK_LIME_GATE_BTC = 'https://www.gate.io/en/trade/LIME_BTC';
export const LINK_LIME_CERTIK = 'https://www.certik.org/projects/imelab';
export const LINK_LIME_APESWAP =
    'https://app.apeswap.finance/swap?inputCurrency=0x7bc75e291e656e8658d66be1cc8154a3769a35dd&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
export const LINK_LEGAL_OPINION =
    'https://imem.gitbook.io/ime-faq-eng/docs-and-terms/legal-opinion-lime';
export const LINK_APK = 'https://imem.gitbook.io/ime-faq-eng/apps/android/apk';
export const LINK_POLICY =
    'https://imem.app/privacy';
export const LINK_USER_AGREEMENT =
    'https://imem.app/tos';
export const LINK_BRAND_ASSETS =
    'https://www.figma.com/design/iQxkatXu6Isse6xWRAOWaY/iMe-Media-Kit?node-id=6-2&t=cV7LmC9HHs4ZsgVY-0';
export const LINK_KUCOIN = 'https://kucoin.com';
export const LINK_POLYGONSCAN =
    'https://polygonscan.com/token/0x7f67639ffc8c93dd558d452b8920b28815638c44';
export const LINK_SUSHISWAP =
    'https://analytics-polygon.sushi.com/pairs/0xdcb0c125df19598501427c28e7991564504c947b';
export const LINK_UNISWAPV3 =
    'https://info.uniswap.org/#/polygon/tokens/0x7f67639ffc8c93dd558d452b8920b28815638c44';
export const LINK_DEXTOOLS =
    'https://www.dextools.io/app/bsc/pair-explorer/0x7fcf9b7f973fac79fb20aaa11095314f1dd9d3b8';
export const LINK_TRADING_VIEW =
    'https://www.tradingview.com/chart/?symbol=GATEIO%3ALIMEUSDT';

export const LINK_LIME_YOUTUBE = 'https://www.youtube.com/watch?v=CgUyTqnMAP4';

export const LINK_DESKTOP_APP_WINDOWS_64 = 'https://imem.app/download/desktop/win64'
export const LINK_DESKTOP_APP_WINDOWS_64_PORTABLE = 'https://imem.app/download/desktop/win64_portable'
export const LINK_DESKTOP_APP_WINDOWS_32 = 'https://imem.app/download/desktop/win'
export const LINK_DESKTOP_APP_WINDOWS_32_PORTABLE = 'https://imem.app/download/desktop/win_portable'
export const LINK_DESKTOP_APP_MAC = 'https://imem.app/download/desktop/mac'
export const LINK_DESKTOP_APP_MAC_APP_STORE = LINK_APP_STORE
export const LINK_DESKTOP_APP_LINUX_64 = 'https://imem.app/download/desktop/linux'

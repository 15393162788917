export const firebaseConfig = {
    TESTNET: {
        apiKey: "AIzaSyDh9qB8_OkpG6vi84Jt3IwrGTqgUKNYgC0",
        authDomain: "ime-messenger-stage.firebaseapp.com",
        databaseURL: "https://ime-messenger-stage.firebaseio.com",
        projectId: "ime-messenger-stage",
        storageBucket: "ime-messenger-stage.appspot.com",
        messagingSenderId: "272440335749",
        appId: "1:272440335749:web:ca564873af7ac921d8b8ec",
        measurementId: "G-ZX5TRFKLK0"
    },
    MAINNET: {
        apiKey: "AIzaSyBYQTewIIZXaE9Ef9_Lg5-TbEoWwLIlXcY",
        authDomain: "ime-messenger.firebaseapp.com",
        databaseURL: "https://ime-messenger.firebaseio.com",
        projectId: "ime-messenger",
        storageBucket: "ime-messenger.appspot.com",
        messagingSenderId: "444157464191",
        appId: "1:444157464191:web:c7b254590a31768520b94a",
        measurementId: "G-RN3NTPFBWK"
    }
};
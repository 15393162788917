import { FC, useEffect, useRef, useState } from 'react';
import './style.scss';
import AppStore from 'assets/img/buttons/apple-head.svg';
import GooglePlay from 'assets/img/buttons/google-head.svg';
import logo from 'assets/img/logo-full-white.svg';
import { useOnScreen } from 'hooks';
import { openNewTab } from 'utils';
import { LINK_APP_STORE, LINK_GOOGLE_PLAY } from 'config';
import videoIme from 'assets/video/imeMain.mp4';
import { LandingHeadProps } from './types';

const LandingHead: FC<LandingHeadProps> = ({ setIsLogoVisible }) => {
    const logoRef = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(logoRef);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        setIsLogoVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        if (document.readyState === 'complete') setIsLoaded(true);
        window.onload = function () {
            setIsLoaded(true);
        };
    }, []);

    return (
        <div className={'landing-head'}>
            {isLoaded ? (
                <div className={'lime-video'}>
                    <div className={'white-block'} />
                    <video autoPlay loop muted>
                        <source src={videoIme} type="video/mp4" />
                    </video>
                </div>
            ) : null}

            <div className={'container'}>
                <div ref={logoRef}>
                    <img src={logo} alt={'logo'} className={'logo'} />
                </div>

                <div className={'title'}>{'Messenger\n & Crypto Wallet'}</div>

                <div className={'text'}>
                    {'Intelligent DeFi & AI Platform Powered by Telegram API\n' +
                        'with non-custodial Crypto Wallet'}
                </div>

                <div className={'buttons'}>
                    <img
                        src={AppStore}
                        alt={'apple'}
                        onClick={() => {
                            openNewTab(LINK_APP_STORE);
                        }}
                    />

                    <img
                        src={GooglePlay}
                        alt={'google'}
                        onClick={() => {
                            openNewTab(LINK_GOOGLE_PLAY);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default LandingHead;

import { useEffect } from 'react'
import './App.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import { routesArray } from './routes'
import { Route, Switch } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import CookiesWindow from './components/Modal/CookiesWindow'
import { useDispatch } from 'react-redux'
import { init } from './modules/System'

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(init());
    }, []);
    return (
        <div className="App">
            <ScrollToTop />
            <Switch>
                {routesArray.map((item: any, index: number) => (
                    <Route exact={item.exact} path={item.path} key={index}>
                        <item.component />
                    </Route>
                ))}
            </Switch>
            <CookiesWindow />
        </div>
    );
}

export default App;

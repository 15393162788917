import { FC } from 'react';
import './style.scss';

import { collaborations } from 'config';
import { openNewTab } from 'utils';
import { ROUTE_COLLABORATIONS } from 'routes/names';
import { Button } from '../../form/Button';

const Collaborations: FC = () => {
    const IMAGE_WIDTH_WITH_MARGIN = 187;

    const firstRow = collaborations
        .filter((i, index) => index < collaborations.length / 3)
        .map((i, index) => {
            return (
                <div className={'item-wrapper'} key={index}>
                    <img
                        className={'monochrome'}
                        alt={i.name}
                        src={i.pictureMonochrome}
                        onClick={() => openNewTab(i.link)}
                    />
                    <img className={'colored'} src={i.picture} alt={i.name} />
                </div>
            );
        });

    const secondRow = collaborations
        .filter(
            (i, index) =>
                index >= collaborations.length / 3 &&
                index < (2 * collaborations.length) / 3
        )
        .map((i, index) => {
            return (
                <div className={'item-wrapper'} key={index}>
                    <img
                        className={'monochrome'}
                        alt={i.name}
                        src={i.pictureMonochrome}
                        onClick={() => openNewTab(i.link)}
                    />
                    <img className={'colored'} src={i.picture} alt={i.name} />
                </div>
            );
        });

    const thirdRow = collaborations
        .filter((i, index) => index >= (2 * collaborations.length) / 3)
        .map((i, index) => {
            return (
                <div className={'item-wrapper'} key={index}>
                    <img
                        className={'monochrome'}
                        alt={i.name}
                        src={i.pictureMonochrome}
                        onClick={() => openNewTab(i.link)}
                    />
                    <img className={'colored'} src={i.picture} alt={i.name} />
                </div>
            );
        });

    return (
        <div className={'collaborations'}>
            <div className={'title'}>Collaborations</div>

            <div className={'collaborations__list'}>
                <div id="container">
                    <div
                        className="wrapper-top wrapper"
                        style={{
                            width:
                                firstRow.length * IMAGE_WIDTH_WITH_MARGIN * 2 +
                                'px',
                        }}
                    >
                        <div className="photobanner">
                            {firstRow.map((i) => i)}
                            {firstRow.map((i) => i)}
                            {firstRow.map((i) => i)}
                            {firstRow.map((i) => i)}
                        </div>
                    </div>
                    <div
                        className="wrapper-bottom wrapper"
                        style={{
                            width:
                                secondRow.length * IMAGE_WIDTH_WITH_MARGIN * 2 +
                                'px',
                        }}
                    >
                        <div className="photobanner">
                            {secondRow.map((i) => i)}
                            {secondRow.map((i) => i)}
                            {secondRow.map((i) => i)}
                            {secondRow.map((i) => i)}
                        </div>
                    </div>

                    <div
                        className="wrapper-top wrapper-third wrapper"
                        style={{
                            width:
                                thirdRow.length * IMAGE_WIDTH_WITH_MARGIN * 2 +
                                'px',
                        }}
                    >
                        <div className="photobanner">
                            {thirdRow.map((i) => i)}
                            {thirdRow.map((i) => i)}
                            {thirdRow.map((i) => i)}
                            {thirdRow.map((i) => i)}
                        </div>
                    </div>
                </div>

                <Button
                    isBlue
                    onClick={() => {
                        openNewTab(ROUTE_COLLABORATIONS);
                    }}
                >
                    VIEW ALL COLLABORATIONS
                </Button>
            </div>
        </div>
    );
};

export default Collaborations;

import { FC, useCallback, useState } from 'react'
import { utils } from 'ethers'
import { TextAreaWithNumbers } from 'components/TextAreaWithNumbers'
import CsvExample from 'assets/img/csv-example.png'
import { ReactComponent as RemoveIcon } from 'assets/img/close.svg';
import { ReactComponent as CardStackIcon } from 'assets/img/card-stack.svg';
import { Dialog } from 'components/Dialog'
import { isArray } from 'lodash'


export const CSVForm: FC<any> = ({
    totalTokenAmount,
    totalAddresses,
    reset,
    errors,
    disabled,
    entriesErrorsIdx,
    csvText,
    csvError,
    csvFileName,
    processCsvFile,
    resetFile,
    updateEntries,
    csvRecipientsError,
}) => {
    const [exampleDialogOpened, toggleExampleDialog] = useState(false)

    const onFile = useCallback((e) => {
        const [file] = e.target?.files || [];

        if (file) {
            processCsvFile(file)
        }
    }, [])

    const cancelFile = useCallback(() => {
        if (!disabled) {
            reset()
            resetFile()
        }
    }, [reset, resetFile, disabled])

    return (
        <>
            <header className='airdrop-form-header'>
                <div>
                    {totalAddresses} addresses,{' '}
                    {utils.formatEther(totalTokenAmount)} tokens
                </div>
                <div>Format: address;token_amount</div>
            </header>
            <section>
                <TextAreaWithNumbers
                    value={csvText}
                    onChange={updateEntries}
                    disabled={disabled}
                    entriesErrorsIdx={entriesErrorsIdx}
                />
            </section>
            <footer>
                <div className="airdrop-csv-buttons">
                    <div className="airdrop-csv-upload">
                        <input
                            type="file"
                            id="csvFile"
                            accept=".csv"
                            value=""
                            onChange={onFile}
                            disabled={disabled}
                        />
                        {csvFileName
                            ? (
                                <div onClick={cancelFile} className="airdrop-file-btn">
                                    <CardStackIcon className="airdrop-file-btn-icon" />
                                    <span>{csvFileName}</span>
                                    <RemoveIcon height={18} width={18} className="airdrop-file-btn-close" />
                                </div>
                            )
                            : (
                                <label className="airdrop-file-btn" htmlFor="csvFile">
                                    <CardStackIcon className="airdrop-file-btn-icon" />
                                    Upload CSV file
                                </label>
                            )
                        }
                    </div>
                    <Dialog
                        title="CSV Example"
                        open={exampleDialogOpened}
                        close={() => toggleExampleDialog(false)}
                        content={
                            <div className="airdrop-csv-example">
                                <p>
                                    Make 2 separate
                                    columns: “address”
                                    and “token_amount”
                                </p>
                                <p>
                                    You can upload files
                                    in formats:{' '}
                                    <strong>
                                        CSV
                                    </strong>
                                </p>
                                <a href="airdrop-template.csv" download="airdrop-template.csv">
                                    Download CSV example
                                </a>
                                <img
                                    className="csv-example-image"
                                    src={CsvExample}
                                    alt="CSV example"
                                />
                            </div>
                        }
                    >
                        <div onClick={() => toggleExampleDialog(true)} className="airdrop-csv-example">
                            Show CSV example
                        </div>
                    </Dialog>
                </div>
                <div className="airdrop-errors">
                    {csvRecipientsError && <div>{csvRecipientsError}</div>}
                    {isArray(csvError)
                        ? csvError.length === 0 ? 'The data in file is incorrect. Please, use CSV example' : (
                            <>
                                <div>These addresses are not valid:</div>
                                {csvError.map((error, i) => (
                                    <div key={`${i}-${error}`}>
                                        {error}
                                    </div>
                                ))}
                            </>
                        )
                        : errors.length && errors[0] ? (
                            <>
                                <div>These addresses are not valid:</div>
                                {errors.map((error, i) => (
                                    <div key={`${i}-${error}`}>
                                        {error}
                                    </div>
                                ))}
                            </>
                        ) : null
                    }
                </div>
            </footer>
        </>
    )
}
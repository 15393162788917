import { ChainNames, chains } from 'config';
import { Contract, providers } from 'ethers';
import { limeBsc, limePolygon, limeBscTestnet, limePolygonTestnet } from 'api';
import { currentNetwork } from 'utils'
import { NetworkPurpose } from 'types'

import BnbTokenAbi from 'abis/BnbToken.json';
import PolygonTokenAbi from 'abis/PolygonToken.json';

import { ChainPositionType, ChainsPositions } from './types'
import { ContractInterface } from 'ethers'

export const CHAINS: [ChainNames.Bsc, ChainNames.Polygon] = [ChainNames.Bsc, ChainNames.Polygon]

export const CHAINS_ABI: Record<ChainNames.Bsc | ChainNames.Polygon, ContractInterface> = {
    [ChainNames.Bsc]: BnbTokenAbi,
    [ChainNames.Polygon]: PolygonTokenAbi,
}

export const CHAINS_ABI_BY_ID = {
    [chains.BSC.chainId]: BnbTokenAbi,
    [chains.BSC_TESTNET.chainId]: BnbTokenAbi,
    [chains.POLYGON.chainId]: PolygonTokenAbi,
    [chains.POLYGON_TESTNET.chainId]: PolygonTokenAbi,
}

export const INITIAL_CHAINS_POSITIONS: ChainsPositions = {
    [ChainPositionType.From]: ChainNames.Bsc,
    [ChainPositionType.To]: ChainNames.Polygon,
}

export const INITIAL_AMOUNT = {
    [ChainPositionType.From]: '',
    [ChainPositionType.To]: '',
}

export const INITIAL_BALANCE = {
    [ChainNames.Bsc]: '0',
    [ChainNames.Polygon]: '0',
}

const isMainnet = currentNetwork === NetworkPurpose.Mainnet

export const CHAINS_DATA = {
    [ChainNames.Bsc]: isMainnet ? chains[ChainNames.Bsc] : chains[ChainNames.BscTestnet],
    [ChainNames.Polygon]: isMainnet ? chains[ChainNames.Polygon] : chains[ChainNames.PolygonTestnet],
}

export const CHAINS_CONTRACTS = {
    [ChainNames.Bsc]: new Contract(
        CHAINS_DATA[ChainNames.Bsc].address.lime,
        CHAINS_ABI[ChainNames.Bsc],
        new providers.JsonRpcProvider(CHAINS_DATA[ChainNames.Bsc].url)
    ),
    [ChainNames.Polygon]: new Contract(
        CHAINS_DATA[ChainNames.Polygon].address.lime,
        CHAINS_ABI[ChainNames.Polygon],
        new providers.JsonRpcProvider(CHAINS_DATA[ChainNames.Polygon].url)
    )
}

export const CHAINS_LIME = {
    [ChainNames.Bsc]: isMainnet ? limeBsc : limeBscTestnet,
    [ChainNames.Polygon]: isMainnet ? limePolygon : limePolygonTestnet,
}

import {
    Portal,
    Provider,
    Root,
    TooltipContentProps,
    TooltipTrigger,
} from '@radix-ui/react-tooltip'
import { FC, ReactNode } from 'react'
import { StyledArrow, StyledContent } from './style'

export const Tooltip: FC<
    TooltipContentProps & { text: string | ReactNode; children: ReactNode }
> = (props) => {
    const { children, text } = props
    return (
        <Provider delayDuration={100}>
            <Root>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <Portal>
                    <StyledContent {...props}>
                        {text}
                        <StyledArrow width={24} height={16} />
                    </StyledContent>
                </Portal>
            </Root>
        </Provider>
    )
}

import React, { FC, Fragment, useEffect } from 'react';
import Channel from 'components/Service/Catalog/Channel';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { useHistory } from 'react-router-dom';
import { ROUTE_CATALOG } from 'routes/names';
import HeaderWithNavigation from 'components/Header/HeaderWithNavigation';
import { setScrollRestore } from 'modules/System';

const ChannelPage: FC = () => {
    const channel = useSelector(
        (state: AppStateType) => state.catalog.currentChannel
    );
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!channel) history.push(ROUTE_CATALOG);
    }, []);

    useEffect(() => {
        dispatch(setScrollRestore(true));

        return () => {
            dispatch(setScrollRestore(false));
        };
    }, []);
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <HeaderWithNavigation
                    backName={'Catalog'}
                    backAction={() => history.goBack()}
                />
                {channel && <Channel channel={channel} />}
            </div>
        </Fragment>
    );
};

export default ChannelPage;

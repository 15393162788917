import { FC } from 'react';
import './style.scss';

import { collaborations } from 'config';
import { ReactComponent as Telegram } from 'assets/img/socials/Telegram.svg';
import { ReactComponent as Web } from 'assets/img/socials/Web.svg';
import { ReactComponent as Medium } from 'assets/img/socials/Medium.svg';
import { ReactComponent as Reddit } from 'assets/img/socials/Reddit.svg';
import { ReactComponent as Twitter } from 'assets/img/socials/Twitter.svg';
import { ReactComponent as Youtube } from 'assets/img/socials/Youtube.svg';
import { openNewTab } from 'utils';

const CollaborationsList: FC = () => {
    return (
        <div className={'collaborations-list'}>
            <div className={'title'}>Collaborations</div>

            <div className={'list'}>
                {collaborations.map((i, index) =>
                    i.link ? (
                        <div className={'collab'} key={index}>
                            {
                                <img
                                    className={'logo'}
                                    onClick={() => openNewTab(i.link)}
                                    src={i.picture}
                                    alt={i.name}
                                />
                            }
                            <div className={'links'}>
                                {i.medium ? (
                                    <Medium
                                        onClick={() => openNewTab(i.medium)}
                                    />
                                ) : null}
                                {i.twitter ? (
                                    <Twitter
                                        onClick={() => openNewTab(i.twitter)}
                                    />
                                ) : null}
                                {i.reddit ? (
                                    <Reddit
                                        onClick={() => openNewTab(i.reddit)}
                                    />
                                ) : null}
                                {i.youtube ? (
                                    <Youtube
                                        onClick={() => openNewTab(i.youtube)}
                                    />
                                ) : null}
                                {i.telegram ? (
                                    <Telegram
                                        onClick={() => openNewTab(i.telegram)}
                                    />
                                ) : null}
                                {i.link ? (
                                    <Web onClick={() => openNewTab(i.link)} />
                                ) : null}
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default CollaborationsList;

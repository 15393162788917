import {useHistory} from 'react-router-dom';
import {LINK_APP_STORE, LINK_GOOGLE_PLAY} from 'config';
import {ROUTE_DOWNLOAD_DESKTOP, ROUTE_MAIN} from 'routes/names';

export default function DeepLink() {
    const history = useHistory();
    const navigator: any = window.navigator.userAgent;

    if (/android/i.test(navigator)) {
        window.location.href = LINK_GOOGLE_PLAY;
    } else if (/iphone|ipad/i.test(navigator)) {
        window.location.href = LINK_APP_STORE;
    } else if (/Win|Mac|Linux/i.test(navigator)) {
        history.push(ROUTE_DOWNLOAD_DESKTOP);
    } else {
        history.push(ROUTE_MAIN);
    }

    return null;
}

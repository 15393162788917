import React, { FC } from 'react'
import './style.scss'
import classNames from 'classnames'

interface IInput {
    type: string
    isError?: boolean
    badge?: string
    onChange: (e: any) => void
    value?: string
    defaultValue?: string
}

const Input: FC<IInput> = ({
    type,
    isError,
    badge,
    onChange,
    value,
    defaultValue,
}) => {
    return (
        <div
            className={classNames({
                input: true,
                number: type === 'number',
                invalid: isError,
            })}
        >
            <input
                type={type}
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
            />
            <div className={'badge'}>{badge} </div>
        </div>
    )
}

export default Input

import { FC, Fragment } from 'react';
import { RouteProps } from '../types';
import { SwapRoute, SwapRoutes } from './style';

import OneInchIcon from 'assets/img/1inch.svg';
import SymbiosisIcon from 'assets/img/symbiosis.svg';
import ArrowRightSmallIcon from 'assets/img/arrow-right-small.svg';
import ArrowRightIcon from 'assets/img/arrow-right.svg';
import { Token } from 'symbiosis-js-sdk';

import { ChainNames } from 'config'

const predefinedPairs = {
    [ChainNames.Bsc]: [
        [{ symbol: "LIME" }, { symbol: "BUSD" }],
        [{ symbol: "sBUSD" }, { symbol: "USDT" }],
        [{ symbol: "USDT" }, { symbol: "LIME" }],
    ],
    [ChainNames.Polygon]: [
        [{ symbol: "LIME" }, { symbol: "USDT" }],
        [{ symbol: "sBUSD" }, { symbol: "BUSD" }],
        [{ symbol: "WBNB" }, { symbol: "LIME" }],
    ],
}

export const Route: FC<RouteProps> = ({ tokens, token }) => {
    const tokensPairs = tokens?.reduce<Token[][]>(
        (pairs, token, index, tokens) => {
            if (index < pairs.length * 2) {
                return pairs;
            }
            if (index === tokens.length - 1) {
                return [...pairs, [tokens[index - 1], token]];
            }

            return [...pairs, [token, tokens[index + 1]]];
        },
        []
    ) || predefinedPairs[token];

    return (
        <SwapRoutes>
            {tokensPairs.map((pair, index) => {
                const [{ symbol: firstSymbol }, { symbol: secondSymbol }] = pair;

                return (
                    <Fragment key={`${firstSymbol}-${secondSymbol}`}>
                        <SwapRoute>
                            <img
                                src={index === 1 ? SymbiosisIcon : OneInchIcon}
                                alt={index === 1 ? 'Symbiosis' : '1inch'}
                            />
                            {firstSymbol}
                            <img src={ArrowRightSmallIcon} alt="to" />
                            {secondSymbol}
                        </SwapRoute>
                        {index + 1 !== tokensPairs.length && (
                            <img src={ArrowRightIcon} alt="then" />
                        )}
                    </Fragment>
                );
            })}
        </SwapRoutes>
    );
};

import { createContext, FC, useContext, useCallback, useState, useRef, useMemo } from 'react'
import { Web3ReactProvider, useWeb3React, Web3ContextType } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect'
import { utils } from 'ethers'
import { CHAINS_PARAMS_BY_ID } from 'config/chains'

import { useWallet, connectors, WalletHook } from 'modules/web3/wallet'
import { walletConnect } from 'modules/web3/walletConnect'
import { metaMask } from 'modules/web3/metamask'

import { useEffect } from 'react'
import { WALLET_TYPE } from '.'

interface IWeb3WalletContext extends WalletHook {
    currentChainId?: number
    switchChain: (chainId: number) => Promise<boolean>
    connector?: Web3ContextType['connector']
}

const Web3WalletContext = createContext<IWeb3WalletContext | undefined>(undefined)

const Web3WalletProviderEnhancer: FC<{}> = ({ children }) => {
    const [error, setError] = useState('')
    const { chainId, isActive, isActivating } = useWeb3React()
    const {
        wallet,
        shortWallet,
        walletType,
        walletError: walletConnectError,
        connection,
    } = useWallet()

    const connector = useMemo(() => {
        if (walletType === WALLET_TYPE.METAMASK) {
            return metaMask
        }

        if (walletType === WALLET_TYPE.WALLET_CONNECT) {
            return walletConnect
        }
    }, [walletType])

    const prevChainId = useRef<number | undefined>()
    useEffect(() => {
        if (prevChainId.current === undefined) {
            prevChainId.current = chainId
        } else if (prevChainId.current !== chainId && error !== '') {
            setError('')
        }
    }, [error, setError, chainId, prevChainId])

    const switchChain = useCallback(async (desiredChainId: number) => {
        const chainId = utils.hexValue(desiredChainId)

        try {
            if (connector instanceof WalletConnect) {
                try {
                    await connector.activate(desiredChainId)
                } catch (err) {
                    console.log(err)
                    if (err.code === -32000) {
                        await connector.provider?.request({
                            method: "wallet_addEthereumChain",
                            params: [{
                                ...CHAINS_PARAMS_BY_ID[desiredChainId],
                                chainId,
                            }],
                        });
                    } else {
                        throw err
                    }
                }
            } else if (connector instanceof MetaMask) {
                await connector.activate(CHAINS_PARAMS_BY_ID[desiredChainId])
            } else {
                setError('')

                return false
            }

            setError('')

            return true
        } catch (err) {
            console.error(err, 'switch chain err')

            if (err.code === -32000) {
                setError('Unable to change network. Open the connected extension and change the network.')
            } else if (err.code === 4001) {
                console.log(err.message, 'error skipped')
                setError('')
            } else if (err.code === -32002) {
                setError('This request has already been sent. Open the connected extension and accept the request.')
            } else {
                setError((err as Error).message)
            }

            return false
        }
    }, [connector, setError])

    // auto disconnect
    const prevActive = useRef(false)
    useEffect(() => {
        if (!isActivating) {
            if (!prevActive.current && isActive) {
                prevActive.current = true
            } else if (prevActive.current && !isActive) {
                prevActive.current = false
                connection.disconnect()
            }
        }
    }, [isActive, connection, isActivating])

    return (
        <Web3WalletContext.Provider value={{
            wallet,
            shortWallet,
            walletType,
            walletError: walletConnectError || error,
            connection,
            currentChainId: chainId,
            switchChain,
            connector,
        }}>
            {children}
        </Web3WalletContext.Provider>
    )
}

export const Web3WalletProvider: FC<{}> = ({ children }) => {
    return (
        <Web3ReactProvider connectors={connectors} lookupENS={false} >
            <Web3WalletProviderEnhancer>
                {children}
            </Web3WalletProviderEnhancer>
        </Web3ReactProvider>
    )
}

export function useWeb3Wallet() {
    const context = useContext(Web3WalletContext)

    if (!context) {
        throw Error('useWeb3Wallet can only be used within the Web3WalletProvider component')
    }

    return context
}

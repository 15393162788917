import { FC, Fragment, useEffect, useRef } from 'react';
import InfoBlock from './InfoBlock';
import { infoBlocks } from './infoBlocks';
import LandingHead from './LandingHead';
import Footer from '../Footer';
import Collaborations from './Collaborations';
import Socials from './Socials';
// import RoadMap from './RoadMap';
import Lime from './Lime';
import About from './About';
import {
    SCROLL_TO_ABOUT,
    SCROLL_TO_COLLAB,
    SCROLL_TO_CONTACTS,
    SCROLL_TO_FEATURES,
    SCROLL_TO_LIME,
    // SCROLL_TO_ROADMAP,
} from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { getLimeMetrics, setScrollTo } from '../../modules/Landing';
import LimeMetrics from './Lime/LimeMetrics';
import { isTablet } from 'utils';

interface ILanding {
    setIsLogoVisible: (isVisible: boolean) => any;
}

const Landing: FC<ILanding> = ({ setIsLogoVisible }) => {
    const refAbout = useRef<HTMLDivElement>(null);
    const refLime = useRef<HTMLDivElement>(null);
    const refFeatures = useRef<HTMLDivElement>(null);
    // const refRoadmap = useRef<HTMLDivElement>(null);
    const refCollaborations = useRef<HTMLDivElement>(null);
    const refSocials = useRef<HTMLDivElement>(null);

    const scrollTo = useSelector(
        (state: AppStateType) => state.landing.scrollTo
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (scrollTo) {
            const target = blocks.find((i) => i.name === scrollTo);
            window.scrollTo({
                top: target!.ref.current!.offsetTop - target!.offset,
                behavior: 'smooth',
            });
            dispatch(setScrollTo(null));
        }
    }, [scrollTo]);

    const blocks = [
        {
            name: SCROLL_TO_ABOUT,
            ref: refAbout,
            offset: 0,
        },
        {
            name: SCROLL_TO_FEATURES,
            ref: refFeatures,
            offset: isTablet() ? 50 : -70,
        },
        // {
        //     name: SCROLL_TO_ROADMAP,
        //     ref: refRoadmap,
        //     offset: 50,
        // },
        {
            name: SCROLL_TO_COLLAB,
            ref: refCollaborations,
            offset: 0,
        },
        {
            name: SCROLL_TO_CONTACTS,
            ref: refSocials,
            offset: isTablet() ? 30 : -50,
        },
        {
            name: SCROLL_TO_LIME,
            ref: refLime,
            offset: 0,
        },
    ];

    const metrics = useSelector(
        (state: AppStateType) => state.landing.limeMetrics
    );

    useEffect(() => {
        if (!metrics) dispatch(getLimeMetrics());
    }, []);

    return (
        <Fragment>
            <LandingHead setIsLogoVisible={setIsLogoVisible} />
            <div ref={refAbout} />
            <About />
            <div ref={refLime} />
            <Lime />
            {metrics && <LimeMetrics metrics={metrics} />}
            <div ref={refFeatures} />
            {infoBlocks.map((block, i) => {
                return (
                    <InfoBlock item={block} isRightPic={(i + 1) % 2 === 0} />
                );
            })}
            {/*<div ref={refRoadmap} />*/}
            {/*<RoadMap />*/}
            <div ref={refCollaborations} />
            <Collaborations />
            <div ref={refSocials} />
            <Socials />
            <Footer />
        </Fragment>
    );
};

export default Landing;

import { FC } from 'react';
import ReactDOM from 'react-dom';

import * as S from './style';

interface IModal {
    isShown: boolean;
    hide?: () => void;
}

const Modal: FC<IModal> = ({ isShown, hide, children }) => {
    let page = (
        <S.Wrapper
            onClick={() => {
                if (hide) hide();
            }}
        >
            {children}
        </S.Wrapper>
    );

    return isShown ? ReactDOM.createPortal(page, document.body) : null;
};

export default Modal;

import { FC, Fragment } from 'react';
import './style.scss';
import metamask from 'assets/img/metamask.svg';
import walletConnectIcon from 'assets/img/wallet-connect.svg';
import disconnectPic from 'assets/img/x-circle-red.svg';
import cancel from 'assets/img/cancel.svg';
import tickPic from 'assets/img/tick-circle.svg';
import { WalletConnectProps } from './types';
import classNames from 'classnames'

import { WALLET_TYPE } from 'modules/web3'

const installMetaMask = () => window.open('https://metamask.io')

const WalletConnect: FC<WalletConnectProps> = ({
    hide,
    wallet,
    walletType,
    connectMetamask,
    connectWalletConnect,
    disconnectAndHide,
}) => {
    const metamaskInstalled = window.ethereum?.isMetaMask

    return (
        <Fragment>
            <div className="wallet-connect">
                <div className={'title'}>
                    <div>Connect using:</div>
                    <img src={cancel} alt="cancel" onClick={hide} />
                </div>

                <div
                    className={'item-wrapper'}
                    onClick={metamaskInstalled ? connectMetamask : installMetaMask}
                >
                    <div className={classNames('item', wallet && walletType === WALLET_TYPE.METAMASK && 'selected')}>
                        <div>
                            <img src={metamask} alt={'metamask'} />
                            {metamaskInstalled ? 'MetaMask' : 'Install MetaMask'}
                        </div>
                        {wallet && walletType === WALLET_TYPE.METAMASK ? (
                            <img src={tickPic} alt={'selected'} />
                        ) : null}
                    </div>
                </div>

                <div
                    className={'item-wrapper'}
                    onClick={connectWalletConnect}
                >
                    <div className={classNames('item', wallet && walletType === WALLET_TYPE.WALLET_CONNECT && 'selected')}>
                        <div>
                            <img src={walletConnectIcon} alt={'wallet-connect'} />
                            WalletConnect
                        </div>
                        {wallet && walletType === WALLET_TYPE.WALLET_CONNECT ? (
                            <img src={tickPic} alt={'selected'} />
                        ) : null}
                    </div>
                </div>

                {wallet && (
                    <div
                        className={
                            'item disconnect' + (!wallet ? ' inactive' : '')
                        }
                        onClick={disconnectAndHide}
                    >
                        <img src={disconnectPic} alt={'disconnect'} />
                        Disconnect Wallet
                    </div>
                )}

            </div>
        </Fragment>
    );
};

export default WalletConnect;

import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import person from 'assets/img/catalog/person.svg';
import { ChannelInfoProps } from './types';

const ChannelInfo: FC<ChannelInfoProps> = ({ channel }) => {
    return (
        <div className={'channel-info'}>
            <div>
                <div className={'image-wrapper'}>
                    <div className={'image-holder'}>
                        <img
                            className={'image'}
                            src={channel?.channel.photo}
                            alt={'pic'}
                        />
                    </div>
                </div>
                <div className={'info'}>
                    <div>
                        <div className={'card-title'}>
                            {channel?.channel.title}
                        </div>
                        <div className={'link'}>{channel?.channel.title}</div>
                    </div>
                </div>
            </div>
            <div className={'rating'}>
                <div>
                    {channel?.channel.membersCount}
                    <img src={person} alt={'persons'} />
                </div>
            </div>
        </div>
    );
};

export default ChannelInfo;

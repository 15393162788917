import { FC, useCallback } from 'react';
import { utils } from 'ethers'
import { ChainNamesType, ChainPositionType } from '../../types';
import { CHAINS_DATA } from '../../constants'

import { COIN_LIME } from 'config';
import Select from 'components/Select';
import { LimeIcon } from '../LimeIcon'

import './style.scss';
import { sliceAddress } from 'utils';

type TransferInputProps = {
    blockchain: ChainNamesType;
    chainPositionType: ChainPositionType;
    wallet: string;
    amount: string;
    onAmountChange?: (amount: string) => void;
    onChainChange: (blockchain: string) => void;
    disabled?: boolean;
    walletLoading: boolean;
    chainName: string;
    chainIcon: string;
    chainBalance: string;
    selectItems: Array<{ value: string, render: JSX.Element }>
}

const SELECT_VALUES_BY_CHAIN = Object.fromEntries(Object.entries(CHAINS_DATA).map(([chain, data]) => {
    return [chain, () => (
        <div className="transfer-input-select-value">
            <div className="transfer-input-lime-icons">
                <LimeIcon chain={chain} />
            </div>
        </div>
    )]
}))

export const TransferInput: FC<TransferInputProps> = ({
    chainPositionType,
    wallet,
    amount,
    blockchain,
    onAmountChange,
    onChainChange,
    chainName,
    chainIcon,
    chainBalance,
    selectItems,
    walletLoading,
}) => {
    const disabled = chainPositionType === ChainPositionType.To

    const parseAndChangeAmount = useCallback((e) => {
        if (onAmountChange) {
            const amount = e.target.value;

            if (amount === '00') {
                return
            }

            try {
                if (amount === '') {
                    onAmountChange(amount)
                } else {
                    utils.formatEther(utils.parseEther(amount))
                    onAmountChange(amount)
                }
            } catch (e) {

            }
        }
    }, [onAmountChange])

    const setMaxAmount = useCallback(() => {
        if (onAmountChange) {
            onAmountChange(chainBalance)
        }
    }, [chainBalance, onAmountChange])

    let [left, right] = chainBalance.split('.')
    left = left.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const balanceStr = right ? `${left}.${right}` : left

    return (
        <div className={`transfer-input transfer-input-${chainPositionType === ChainPositionType.To ? 'to' : 'from'}`}>
            <header>
                <h3>
                    Transfer {chainPositionType === ChainPositionType.To ? 'To' : 'From'} {chainName}:
                </h3>
                {wallet && (
                    <div>
                        <img src={chainIcon} alt="Chain" />
                        {sliceAddress(wallet)}
                    </div>
                )}
            </header>
            <main>
                <div>
                    <input
                        type="text"
                        placeholder="0.0"
                        value={amount}
                        onChange={parseAndChangeAmount}
                        disabled={!wallet || disabled}
                    />
                    {wallet && !disabled && (
                        <span onClick={setMaxAmount}>
                            Max
                        </span>
                    )}
                </div>
                <div>
                    <Select
                        items={selectItems}
                        value={blockchain}
                        onChange={onChainChange}
                        renderValue={SELECT_VALUES_BY_CHAIN[blockchain]}
                    />
                </div>
            </main>
            {walletLoading
                ? (
                    <footer>
                        Your balance: Loading...
                    </footer>
                )
                : (
                    <footer>
                        Your balance: {balanceStr} {COIN_LIME}
                    </footer>
                )
            }
        </div>
    );
};

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { logEvent as firebaseLogEvent, setUserId as firebaseSetUserId } from "firebase/analytics";
import { currentNetwork } from "../utils";
import { analyticsInstance } from '../modules/firebase';
import { LogConsole } from '../components/LogConsole';
import { NetworkPurpose } from "../types";

const DesktopAnalytics = () => {
    const [logs, setLogs] = useState<string[]>([]);

    const logEvent = useCallback((eventName: string, params?: Record<string, unknown>) => {
        if (params) {
            firebaseLogEvent(analyticsInstance, eventName, params);
        } else {
            firebaseLogEvent(analyticsInstance, eventName);
        }
        if (currentNetwork === NetworkPurpose.Testnet) {
            const log = `Event sent to Firebase: ${eventName}, parameters: ${JSON.stringify(params)}`;
            setLogs(prevLogs => [...prevLogs, log]);
        }
    }, []);

    const setUserId = useCallback((userId: string) => {
        firebaseSetUserId(analyticsInstance, userId);
        if (currentNetwork === NetworkPurpose.Testnet) {
            const log = `User ID set in Firebase: ${userId}`;
            setLogs(prevLogs => [...prevLogs, log]);
        }
    }, []);

    useEffect(() => {
        window.logEvent = logEvent;
        window.setUserId = setUserId;
    }, [logEvent, setUserId]);

    const memoizedLogs = useMemo(() => logs, [logs]);

    return (
        <div>
            <h1>Desktop Analytics Page</h1>
            <LogConsole logs={memoizedLogs} />
        </div>
    );
};

export default DesktopAnalytics;
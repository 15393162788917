import { FC } from 'react';
import './style.scss';
import classNames from 'classnames';
import { LinkProps } from './types';

export const Link: FC<LinkProps> = ({
    isBlueFill,
    className,
    href,
    children,
    ...props
}) => {
    return (
        <a
            href={href}
            className={classNames(className, {
                link: true,
                'link--blue-fill': isBlueFill,
            })}
            {...props}
        >
            {children}
        </a>
    );
};

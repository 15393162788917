import { FC } from 'react'
import classNames from 'classnames'

import './style.scss'

export const TextAreaWithNumbers: FC<any> = ({ value, onChange, entriesErrorsIdx }) => {
    const valuesLength = value.split('\n').length
    const lines = valuesLength > 5 ? valuesLength : 5

    return (
        <div className="num-textarea">
            <div className="num-textarea-container">
                <div className="num-textarea-numbers">
                    {[...Array(lines)].map((_, i) =>
                        <span
                            className={classNames(entriesErrorsIdx.has(i) && 'num-textarea-numbers--error')}
                            key={i}
                        >
                            {i+1}
                        </span>
                    )}
                </div>
                <textarea className="num-textarea-content" value={value} onChange={onChange} />
            </div>
        </div>
    )
}
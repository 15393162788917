import { FC } from 'react'
import { SwapForm } from './SwapForm'

import './style.scss';

const Swap: FC<{}> = () => {
    return (
        <div className={`service-wrapper`}>
            <div className="swap">
                <div className={`swap-main`}>
                    <header>
                        <h2>Cross-Chain Bridge</h2>
                    </header>
                    <SwapForm />
                </div>
            </div>
        </div>
    );
};

export default Swap;

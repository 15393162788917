import { FC } from 'react';
import './style.scss';
import { ReactComponent as Arrow } from 'assets/img/arrow.svg';
import { openNewTab } from 'utils';
import { ExchangeChainTag } from '../types';

interface IExchangeCard {
    title: string;
    pic: string;
    picColor: string;
    tags: ExchangeChainTag[];
    link: string | undefined;
}

const ExchangeCard: FC<IExchangeCard> = ({
    title,
    pic,
    picColor,
    tags,
    link,
}) => {
    return (
        <div className={'exchange-card'} onClick={() => openNewTab(link)}>
            <div className={'image-holder'} style={{ background: picColor }}>
                <img src={pic} alt={'pic'} />
            </div>
            <div className={'info-holder'}>
                <div>
                    <div className={'title'}>{title}</div>
                    <div className={'tags'}>
                        {tags.map((i) => (
                            <div
                                className={'tag'}
                                style={{ background: i.color }}
                            >
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <Arrow />
            </div>
        </div>
    );
};
export default ExchangeCard;

import { FC, Fragment } from 'react';
import Header from '../components/Header';
import { DownloadDesktop } from '../components/Download';

const DownloadDesktopPage: FC = () => {
    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <DownloadDesktop />
            </div>
        </Fragment>
    );
};

export default DownloadDesktopPage;

import { FC, useState } from 'react';
import './style.scss';
import { Button } from '../../form/Button';

const CookiesWindow: FC = () => {
    const clickHandler = () => {
        document.cookie = 'cookieBanner=confirmed;max-age=61536000';
        setIsShown(false);
    };

    const [isShown, setIsShown] = useState<boolean>(
        document.cookie
            .split(';')
            .filter((item) => item.includes('cookieBanner=confirmed')).length <=
            0
    );

    return isShown ? (
        <div className={'cookies-window'}>
            <div>
                <div className={'title'}>Cookies in this site</div>

                <div className={'text'}>
                    Our site uses cookies to collect and analyse on site
                    perfomance of usage. By clicking, you agree to our Privacy
                    Policy.
                </div>
            </div>
            <div className={'buttons'}>
                <Button isBlueFill onClick={() => clickHandler()}>
                    Allow&nbsp;cookies
                </Button>
                <Button isBlue onClick={() => clickHandler()}>
                    No, thank you
                </Button>
            </div>
        </div>
    ) : null;
};

export default CookiesWindow;

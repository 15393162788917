import { useCallback, useMemo, useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { BigNumber, Contract, providers, utils } from 'ethers';
import { SwapExactIn } from 'symbiosis-js-sdk/dist/crosschain/baseSwapping';
import { debounce } from 'lodash'

import { COIN_LIME, CHAINS_BY_ID, CHAINS_CONTRACTS, CHAINS_ABI } from 'config'
import { LimeIcon } from './LimeIcon'
import { ChainPositionType, ChainNamesType } from '../types';
import { calculateSwap } from 'api/symbiosis';

import {
    CHAINS,
    CHAINS_DATA,
    CHAINS_ABI as CHAINS_ABI_BY_NAME,
    INITIAL_BALANCE,
    INITIAL_CHAINS_POSITIONS,
    INITIAL_AMOUNT,
} from '../constants'

export const useWallet = (wallet?: string, currentChainId?: number, targetChainId?: number): [typeof INITIAL_BALANCE, boolean, (w: string) => void, boolean] => {
    const [loading, setLoading] = useState(false)
    const [shouldSwitchNetwork, setShouldSwitchNetwork] = useState(false)

    useEffect(() => {
        if (currentChainId && targetChainId) {
            setShouldSwitchNetwork(currentChainId !== targetChainId)
        }
    }, [currentChainId, targetChainId])

    // Balances of each chain
    const [chainsBalance, setChainsBalance] = useState(INITIAL_BALANCE);

    const fetchBalances = useCallback(async (currentWallet: string) => {
        setLoading(true)

        try {
            const balances = await Promise.all(
                CHAINS.map(async (chain) => {
                    const { address, url } = CHAINS_DATA[chain];

                    const provider = new providers.JsonRpcProvider(url);
                    const contract = new Contract(address.lime, CHAINS_ABI_BY_NAME[chain], provider);

                    const balance = await contract.balanceOf(currentWallet)

                    return [chain, utils.formatEther(balance.toString())];
                })
            );

            setChainsBalance(Object.fromEntries(balances));
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, []);

    // fetch wallet balances
    useEffect(() => {
        if (wallet) {
            fetchBalances(wallet);
        } else {
            setChainsBalance(INITIAL_BALANCE)
        }
    }, [wallet, fetchBalances]);

    return [chainsBalance, shouldSwitchNetwork, fetchBalances, loading]
}

const loadAllowance = async (wallet: string, address: string, chainId: number): Promise<BigNumber> => {
    const contract = CHAINS_CONTRACTS[chainId];

    try {
        const res = await contract.allowance(wallet, address);

        return res
    } catch (e) {
        console.log('loadAllowance', e)
        throw e
    }
}

export enum SwapStep {
    Unset = 'unset',
    Transaction = 'transaction',
    Mining = 'mining',
    SwapSuccess = 'SwapSuccess',
    Allowance = 'calc-allowance',
    Approve = 'approve',
    ReadyToSwap = 'ReadyToSwap',
    NeedAprove = 'NeedAprove',
    CalculateSwap = 'CalculateSwap',
    Declined = 'Declined',
    SwitchChain = 'SwitchChain',
    Error = 'Error',
}

type SymbiosisSwapHookState = {
    swapStep: SwapStep
    swapMeta?: Awaited<SwapExactIn>
    swapError?: string
    expectedAmount: string
    transactionHash: string
}

type SymbiosisSwapHookHandlers = {
    approve: any
    calculate: any
    swap: any
    reset: any
    setSwapStep: any
}

type SymbiosisSwapHook = [
    SymbiosisSwapHookState,
    SymbiosisSwapHookHandlers,
    boolean,
]

export const useSymbiosisSwap = (toggleShowInprogressDialog: (close: boolean) => void): SymbiosisSwapHook => {
    const [loading, setLoading] = useState(false)
    const [swapError, setSwapError] = useState<string>()
    const [swapStep, setSwapStep] = useState<SwapStep>(SwapStep.Unset)
    const [expectedAmount, setExpectedAmount] = useState('')
    const [transactionHash, setTransactionHash] = useState('')

    const [allowances, setAllowance] = useState<Record<string, BigNumber>>({})
    const [swapMeta, setSwapMeta] = useState<Awaited<SwapExactIn>>()

    const approve = useCallback(async (fromChainId: number, amount: string, connector: any) => {
        setLoading(true)

        if (swapMeta?.approveTo) {
            setSwapStep(SwapStep.Approve);
            const provider = new providers.Web3Provider(connector.provider);
            const signer = provider.getSigner();

            const contract = new Contract(
                CHAINS_BY_ID[fromChainId].address.lime,
                CHAINS_ABI[fromChainId],
                signer,
            );

            try {
                const tx = await contract.approve(swapMeta.approveTo, utils.parseUnits(amount, 18).toString());
                await tx.wait();

                setAllowance((prev) => ({ ...prev, [fromChainId]: undefined }))
                setSwapStep(SwapStep.ReadyToSwap);
            } catch (e) {
                console.log(e)
            }
        }

        setLoading(false)
    }, [swapMeta, setAllowance])

    const lastAmount = useRef('')

    const reset = useCallback(() => {
        setSwapError(undefined)
        setSwapMeta(undefined)
        setExpectedAmount('')
        setSwapStep(SwapStep.Unset);
        setLoading(false)
        setTransactionHash('')
        setAllowance({})
        lastAmount.current = ''
    }, [])

    const getAllowance = useCallback(async (wallet: string, chainId: number, address: string) => {
        let allowance = allowances[chainId]

        if (!allowance) {
            setSwapStep(SwapStep.Allowance)
            setLoading(true)
            allowance = await loadAllowance(wallet, address, chainId)
            setAllowance((prev) => ({ ...prev, [chainId]: allowance }))
        }

        return allowance
    }, [setAllowance, allowances])

    const calculate = useCallback(async (wallet: string, fromChainId: number, toChainId: number, amount: string, balance: string) => {
        reset()

        if (amount === '' || amount === '0' || amount === '0.0') {
            return
        }

        lastAmount.current = amount
        setLoading(true)
        setSwapStep(SwapStep.CalculateSwap);

        if (utils.parseEther(balance).lt(utils.parseEther(amount))) {
            setSwapError('Insufficient balance')
            setLoading(false)
            setSwapStep(SwapStep.Error)

            return
        }

        debounce(async (amount, wallet, toChainId, fromChainId) => {
            if (amount !== lastAmount.current) {
                return
            }

            try {
                const swapMeta = await calculateSwap(wallet, fromChainId, toChainId, amount)

                if (amount !== lastAmount.current) {
                    return
                }

                const tokenAmountOut = utils.formatEther(swapMeta.tokenAmountOut.raw.toString())
                setExpectedAmount(tokenAmountOut)

                setSwapMeta(swapMeta)
                setSwapStep(SwapStep.Allowance)
                const allowance = await getAllowance(wallet, fromChainId, swapMeta.approveTo)
                if (amount !== lastAmount.current) {
                    return
                }

                const needToApproveTokens = allowance.eq(0) || allowance.lt(utils.parseEther(amount));

                if (needToApproveTokens) {
                    setSwapStep(SwapStep.NeedAprove)
                } else {
                    setSwapStep(SwapStep.ReadyToSwap)
                }
            } catch (err) {
                console.error(err, 'swapcalcerror')

                if (amount !== lastAmount.current) {
                    return
                }

                if (err.code === 2) {
                    setSwapError((err as Error).message)
                    setSwapStep(SwapStep.Error)
                } else {
                    setSwapError('Unknown error')
                    setSwapStep(SwapStep.Error)
                }
            } finally {
                if (amount !== lastAmount.current) {
                    return
                }

                setLoading(false)
            }
        }, 1000)(amount, wallet, toChainId, fromChainId);
    }, [setSwapMeta, reset, getAllowance])

    const swap = useCallback(async (connector) => {
        const provider = new providers.Web3Provider(connector.provider);
        const signer = provider.getSigner();

        if (swapMeta) {
            try {
                setSwapStep(SwapStep.Transaction)
                const { response } = await swapMeta.execute(signer)
                const transactionHash = response.hash
                setSwapStep(SwapStep.SwapSuccess)
                setTransactionHash(transactionHash)

                return true
            } catch (err: any) {
                console.log({ code: err.code, message: err.message })
                if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
                    return false
                } else if (err.code === 'ACTION_REJECTED' || err.message === 'User rejected the transaction' || err.code === -32000) {
                    setSwapStep(SwapStep.Declined)
                } else {
                    toggleShowInprogressDialog(false)
                    setSwapStep(SwapStep.Error)
                    setSwapError('Unknown error')
                }
            }

            return null
        }
    }, [swapMeta, toggleShowInprogressDialog])

    return [
        {
            swapStep,
            swapMeta,
            swapError,
            expectedAmount,
            transactionHash,
        },
        {
            approve,
            calculate,
            swap,
            reset,
            setSwapStep,
        },
        loading,
    ]
}

export const useSwapForm = (): [string, Dispatch<SetStateAction<string>>, ChainNamesType, ChainNamesType, () => void] => {
    const [chainsPositions, changeChainsPositions] = useState(INITIAL_CHAINS_POSITIONS);
    const fromChain = chainsPositions[ChainPositionType.From]
    const toChain = chainsPositions[ChainPositionType.To]

    const [amount, setAmount] = useState<string>(INITIAL_AMOUNT[ChainPositionType.From]);

    const swapChainPositions = useCallback(() => {
        changeChainsPositions((prevPostions) => ({
            [ChainPositionType.From]: prevPostions[ChainPositionType.To],
            [ChainPositionType.To]: prevPostions[ChainPositionType.From],
        }));
    }, [changeChainsPositions]);

    return [amount, setAmount, fromChain, toChain, swapChainPositions]
}

export const useSelectItems = (chainsBalance: any) => {
    return useMemo(() => CHAINS.map((chain) => {
        const balance = chainsBalance[chain]
        let [left] = balance.split('.')
        const balanceStr = left.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        return {
            value: chain,
            render: (
                <div
                    key={`select-${chain}`}
                    className="transfer-input-select-item"
                >
                    <div className="transfer-input-lime-icons">
                        <LimeIcon chain={chain} />
                    </div>
                    <div>
                        <h5>{COIN_LIME}</h5>
                        <p>
                            {balanceStr} {COIN_LIME}
                        </p>
                    </div>
                </div>
            ),
        };
    }), [chainsBalance])
}

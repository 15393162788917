import { FC, Fragment, useEffect, useState } from 'react';
import './style.scss';
import '../../style.scss';
import { CatalogTag } from 'types';
import { Button } from '../../../form/Button';
import question from 'assets/img/question.svg';
import TagsSelector from './TagsSelector';
import lime from 'assets/img/landing/Lime/lime.svg';
import ChannelInfo from '../Channel/ChannelInfo';
import { COIN_LIME, PROJECT_SHORT_NAME } from 'config';
import { ERROR_CHANNEL_CHECK } from 'api';
import { ROUTE_CATALOG } from 'routes/names';
import Input from '../../../form/Input';
import { checkChannel, createCampaign, getCreateStaff } from 'modules/Catalog';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import HeaderWithNavigation from '../../../Header/HeaderWithNavigation';
import { useHistory } from 'react-router-dom';
import Channel from '../Channel';
import classNames from 'classnames';
import { CreateChannelProps } from './types';

const CreateChannel: FC<CreateChannelProps> = ({ channel }) => {
    const VIEW = {
        CHECK: 'check',
        INFO: 'info',
        TAGS: 'tags',
        PREVIEW: 'preview',
    };
    const [viewState, setViewState] = useState<string>(VIEW.CHECK);

    const allFieldsCheck = () => {
        return !(
            selectedTags.tags.length === 0 ||
            selectedTags.countries.length === 0 ||
            selectedTags.languages.length === 0 ||
            selectedTags.categories.length === 0
        );
    };

    const [selectedTags, setSelectedTags] = useState<{
        tags: CatalogTag[];
        languages: CatalogTag[];
        countries: CatalogTag[];
        categories: CatalogTag[];
    }>({ tags: [], languages: [], countries: [], categories: [] });

    const setTags = (key: string, value: CatalogTag[]) => {
        setSelectedTags({ ...selectedTags, [key]: value });
    };

    const dispatch = useDispatch();
    const [channelName, setChannelName] = useState<string>('@');

    const checkHandler = () => {
        setErrorText('');
        if (/^@[a-zA-Z0-9_]+$/.test(channelName)) {
            dispatch(checkChannel(channelName));
        } else {
            setErrorText('Wrong chat name');
        }
    };

    const [isSend, setIsSend] = useState<boolean>(false);
    const confirmHandler = () => {
        dispatch(
            createCampaign(
                channel!.channel.id.toString(),
                selectedTags.languages[0].id,
                selectedTags.categories[0].id,
                selectedTags.tags.map((i) => i.id),
                selectedTags.countries.map((i) => i.id)
            )
        );
        setIsSend(true);
    };

    const createLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoadingCreate
    );

    useEffect(() => {
        if (!createLoading && isSend && !error) history.push(ROUTE_CATALOG);
    }, [createLoading]);

    const tags = useSelector((state: AppStateType) => state.catalog.tags);
    const languages = useSelector(
        (state: AppStateType) => state.catalog.languages
    );
    const countries = useSelector(
        (state: AppStateType) => state.catalog.countries
    );
    const categories = useSelector(
        (state: AppStateType) => state.catalog.createCategories
    );
    const isLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoading
    );

    useEffect(() => {
        if (tags === null) {
            dispatch(getCreateStaff());
        }
    }, []);

    const ERRORS = {
        INVALID_BOT: `Please, add ${PROJECT_SHORT_NAME} Bot @${process.env.REACT_APP_TELEGRAM_BOT_NAME} to your channel’s administrator list, then try again.`,
        UNEXPECTED: 'Unexpected error, try again later',
    };
    const error = useSelector(
        (state: AppStateType) => state.catalog.error[state.catalog.currentMode]
    );
    const [errorText, setErrorText] = useState<string>('');

    useEffect(() => {
        if (channel?.id && !isSend) {
            setViewState(VIEW.TAGS);
        }
        if (error) {
            if (
                error.code === ERROR_CHANNEL_CHECK.BOT_IS_NOT_ADMIN ||
                error.code === ERROR_CHANNEL_CHECK.NO_ACCESS
            ) {
                setErrorText(ERRORS.INVALID_BOT);
            } else {
                setErrorText(error.message!);
            }
        }
    }, [channel, error]);

    const history = useHistory();
    const [backName, setBackName] = useState<string>('Catalog');
    const [forwardName, setForwardName] = useState<string>('Preview');

    const backAction = () => {
        if (viewState === VIEW.CHECK) {
            history.push(ROUTE_CATALOG);
            setBackName('Catalog');
        }
        if (viewState === VIEW.INFO) {
            setViewState(VIEW.CHECK);
            setBackName('Catalog');
        }
        if (viewState === VIEW.TAGS) {
            setViewState(VIEW.INFO);
            setBackName('Search');
        }
        if (viewState === VIEW.PREVIEW) {
            setViewState(VIEW.TAGS);
            setBackName('Search');
        }
    };

    const forwardAction = () => {
        setViewState(VIEW.PREVIEW);
    };

    const tagsView = (
        <Fragment>
            <ChannelInfo channel={channel} />
            {channel?.channel.description ? (
                <div className={'description'}>
                    {channel?.channel.description}
                </div>
            ) : null}
            <div>
                <TagsSelector
                    title={'Channel Tags'}
                    allTags={tags || []}
                    setSelectedTags={(value: CatalogTag[]) =>
                        setTags('tags', value)
                    }
                    selectedTags={selectedTags.tags}
                />
                <TagsSelector
                    title={'Channel Categories'}
                    allTags={categories || []}
                    setSelectedTags={(value: CatalogTag[]) =>
                        setTags('categories', value)
                    }
                    selectedTags={selectedTags.categories}
                    isOnlyOne
                />
                <TagsSelector
                    title={'Channel Countries'}
                    allTags={countries || []}
                    setSelectedTags={(value: CatalogTag[]) =>
                        setTags('countries', value)
                    }
                    selectedTags={selectedTags.countries}
                />
                <TagsSelector
                    title={'Channel Language'}
                    allTags={languages || []}
                    setSelectedTags={(value: CatalogTag[]) =>
                        setTags('languages', value)
                    }
                    selectedTags={selectedTags.languages}
                    isOnlyOne
                />
            </div>

            <div className={'buttons continue'}>
                <Button
                    isDisabled={!allFieldsCheck()}
                    isBlueFill
                    onClick={() => setViewState(VIEW.INFO)}
                >
                    Preview
                </Button>
            </div>
        </Fragment>
    );

    const checkView = (
        <Fragment>
            <div className={'check-channel'}>
                <div className={'title'}>Add channel</div>
                <Input
                    type={'text'}
                    defaultValue={'@'}
                    value={channelName}
                    onChange={(e) => {
                        let tmp = e.target.value.replace('@', '');
                        tmp = '@'.concat(tmp);
                        if (tmp.length > 0) setChannelName(tmp);
                    }}
                    isError={errorText.length > 0}
                />
                {errorText.length > 0 ? (
                    <div className={'error'}>{errorText}</div>
                ) : null}
                <Button
                    isDisabled={channelName.length <= 1}
                    isBlueFill
                    isFullSize
                    onClick={() => checkHandler()}
                >
                    {isLoading ? (
                        <div className={'loading-circle'} />
                    ) : (
                        'Continue'
                    )}
                </Button>
            </div>
        </Fragment>
    );

    const infoView = (
        <Fragment>
            <ChannelInfo channel={channel} />
            {channel?.channel.description ? (
                <div className={'description'}>
                    {channel?.channel.description}
                </div>
            ) : null}
            <div className={'text'}>
                {
                    'The channel can be published in our catalog.\nTo place your channel, you will need to:'
                }
            </div>
            <div className={'point'}>
                <span>1</span> verify your channel
            </div>
            <div className={'point'}>
                <span>2</span> pay for the hosting service
                <div className={'tip'}>
                    <img src={question} alt={'info'} />
                    <div className={'tip-text'}>
                        Channel publication costs <span>12.5 {COIN_LIME}</span>{' '}
                        <img src={lime} alt={'lime'} />
                    </div>
                </div>
            </div>
            <div className={'buttons continue'}>
                <Button isBlueFill onClick={() => setViewState(VIEW.PREVIEW)}>
                    Continue
                </Button>
            </div>
        </Fragment>
    );

    const preview = (
        <Fragment>
            {channel && allFieldsCheck() ? (
                <Channel
                    channel={{
                        id: 0,
                        channel: channel!.channel,
                        tags: selectedTags.tags,
                        countries: selectedTags.countries,
                        category: selectedTags.categories[0],
                        language: {
                            id: selectedTags.languages[0].id,
                            title: selectedTags.languages[0].title,
                            nativeTitle: selectedTags.languages[0].title,
                        },
                        invalidReason: '',
                        createdAt: '',
                    }}
                    previewSend={() => confirmHandler()}
                />
            ) : null}
        </Fragment>
    );

    return (
        <div
            className={classNames({
                'create-channel': true,
                preview: VIEW.PREVIEW === viewState,
            })}
        >
            <HeaderWithNavigation
                backName={backName}
                backAction={backAction}
                isForwardVisible={viewState === VIEW.TAGS}
                isFrowardActive={allFieldsCheck()}
                forwardName={forwardName}
                forwardAction={forwardAction}
                isExit={viewState !== VIEW.CHECK}
            />

            {viewState === VIEW.CHECK ? checkView : null}
            {viewState === VIEW.INFO ? infoView : null}
            {viewState === VIEW.TAGS ? tagsView : null}
            {viewState === VIEW.PREVIEW ? preview : null}
        </div>
    );
};

export default CreateChannel;
